import { ApiResources } from "common/config";
import BaseService from "services/base-service";
import {
  AdjReceiptDetail,
  ImportAdjReceiptParamsRequest,
  ImportAdjReceiptResponse,
  ResponseUpdateEntity,
  SaveImportAdjReceiptRequest,
  SaveImportAdjReceiptResponse,
  UpdateAdjReceiptDetail,
  UpdateAdjReceiptStatusRequest,
  UpdateAdjReceiptStatusResponse,
} from "common/entities";

export class AdjReceiptDetailService extends BaseService {
  constructor() {
    super();
  }

  async detailAdjReceipt(adjReceiptId: string) {
    const apiURL = ApiResources.adjReceipt.detailAdjReceipt;
    const { data, success, errors, statusCode } = await this.get<AdjReceiptDetail>(apiURL, {
      adjReceiptId: adjReceiptId,
    });
    return { data, success, errors, statusCode };
  }

  async updateAdjReceipt(params: UpdateAdjReceiptDetail) {
    const apiURL = ApiResources.adjReceipt.updateAdjReceipt;
    const { data, statusCode, errors, success, fullResponse } = await this.put<
      UpdateAdjReceiptDetail,
      string
    >(apiURL, params, { adjReceiptId: params?.id });
    return { data, statusCode, errors, success, fullResponse };
  }

  async exportAdjReceiptDetail(adjReceiptId: string) {
    const apiURL = ApiResources.adjReceipt.exportAdjReceiptDetail;
    const { fullResponse } = await this.getBlob<string, Blob>(apiURL, null, { adjReceiptId: adjReceiptId });
    return { fullResponse };
  }

  async importAdjReceipt(params: ImportAdjReceiptParamsRequest, file: File) {
    const formData = new FormData();
    formData.append("importFile", file);
    const apiURL = ApiResources.adjReceipt.importAdjReceipt;
    const { data, success, fullResponse, errors, statusCode } = await this.postFormData<ImportAdjReceiptResponse>(
      apiURL,
      formData,
      params,
      { "content-type": "multipart/form-data" },
      null
    );
    return {
      fullResponse,
      data,
      success,
      errors,
      statusCode,
    };
  }

  async saveImportAdjReceiptDetail(body: SaveImportAdjReceiptRequest) {
    const apiURL = ApiResources.adjReceipt.saveImportAdjReceiptDetail;
    const { data, errors, success, statusCode } = await this.post<
      SaveImportAdjReceiptRequest,
      SaveImportAdjReceiptResponse
    >(apiURL, body);
    return { data, success, errors, statusCode };
  }

  async updateAdjReceiptStatus(params: UpdateAdjReceiptStatusRequest) {
    const apiURL = ApiResources.adjReceipt.updateAdjReceiptStatus;
    const { data, errors, success, statusCode } = await this.get<UpdateAdjReceiptStatusResponse>(apiURL, params);
    return {
      data,
      errors,
      success,
      statusCode,
    };
  }
}

const adjReceiptDetailService = new AdjReceiptDetailService();

export default adjReceiptDetailService;
