import { ImportAdjReceiptResponse } from "common/entities";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { adjReceiptImportAtom } from "recoil/atoms/adjReceipt/AdjReceiptImport";

export function useGetAdjReceiptImport() {
  return useRecoilValue(adjReceiptImportAtom);
}

export function useSetAdjReceiptImport() {
  const reset = useResetRecoilState(adjReceiptImportAtom);
  const set = useSetRecoilState(adjReceiptImportAtom);
  return (value: ImportAdjReceiptResponse | ((pre: ImportAdjReceiptResponse) => ImportAdjReceiptResponse)) => {
    reset();
    set(value);
  };
}

export function useResetAdjReceiptImport() {
    return useResetRecoilState(adjReceiptImportAtom);
}
