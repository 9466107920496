import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { unitConversionImportAtom } from "recoil/atoms/unit/UnitConversionImport";
import { ImportUnitConversionResponse } from  'common/entities/unit';

export function useGetUnitConversionImport() {
    return useRecoilValue(unitConversionImportAtom)
}

export function getSetUnitConversionImport() {
    const reset = useResetRecoilState(unitConversionImportAtom);
    const set = useSetRecoilState(unitConversionImportAtom);
    return (value: ImportUnitConversionResponse | ((prev: ImportUnitConversionResponse) => ImportUnitConversionResponse)) => {
        reset();
        set(value);
    }
}

export function useResetUnitConversionImport() {
    return useResetRecoilState(unitConversionImportAtom);
}
