import { Input, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { POEntity, ProductInfoEntity } from "common/entities";
import dateTimeUtils from "common/utils/date";
import { TFunction } from "react-i18next";
import { Link } from 'react-router-dom';
import { formatNumber } from "../strings";
import { tagStatus } from "common/dataGlobal/defaultTag";
import { PoTypeOption, SEARCH_DATE_FORMAT } from "common/config";

/**
 * Get all columns of user table
 * @param t Translation function
 * @returns
 */
export function getTablePOColumns(
    t: TFunction,
    pageNumber: number,
    pageSize: number,
): ColumnsType<POEntity> {
    return [
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.STT"),
            align: "center",
            render: (_, record, index) => formatNumber(index + 1 + pageSize * (pageNumber - 1)),
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.NUMBER_PO"),
            dataIndex: "code",
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <Link to={'/po/detail/' + record.id} ><span style={{ color: "blue" }}>{record?.code}</span></Link>
                    </Space>
                )
            },
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.CREATED_DATE"),
            dataIndex: "createdDate",
            align: "center",
            render: (_, record) => dateTimeUtils.formatDate(record?.createdDate),
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.NUMBER_PRODUCT"),
            render: (_, record) => record?.order?.refCode,
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.PO_DATE"),
            dataIndex: "date",
            align: "center",
            render: (_, record) => dateTimeUtils.formatDate(record?.endDate),
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.PLACE_DELIVERY"),
            render: (_, record) => record?.storeDeliver?.name,
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.PLACE_RECEIVER"),
            render: (_, record) => record?.storeReceiver?.name,
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.TYPE"),
            dataIndex: "poType",
            align: "center",
            render: (text) => {
                if (text === PoTypeOption.Deliver) {
                    return t("RESOURCES.INVENTORY.PO.TYPE_PO_OPTION.DELIVER")
                }
                if (text === PoTypeOption.Transfer) {
                    return t("RESOURCES.INVENTORY.PO.TYPE_PO_OPTION.TRANSFER")
                }
                if (text === PoTypeOption.Refund) {
                  return t("RESOURCES.INVENTORY.PO.TYPE_PO_OPTION.REFUND");
                }
            }
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.BRAVO_CODE"),
            dataIndex: "bravoCode"
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.STATUS"),
            dataIndex: "status",
            align: "center",
            render: (text) => tagStatus(text),
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.NOTE"),
            dataIndex: "note",
            width : "20%",
        },


    ];
}

export function getTablePODetailColumns(t, isEditing, onInputChange):ColumnsType < ProductInfoEntity > {
    return [
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.STT"),
            render: (_, record, index) => index + 1,
            align:"center"
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.SKU"),
            dataIndex: "sku",
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <span style={{ color: "blue" }}>{record?.sku}</span>
                    </Space>
                )
            },
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.PRODUCT"),
            dataIndex: "productName",
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <span style={{ color: "blue" }}>{record?.productName}</span>
                    </Space>
                )
            },
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.NUMBER_ORDER"),
            dataIndex: "orderNumber",
            render: (_, record) => {
                return (
                    <span>{record?.qty}</span>
                )
            },
            align:"center"
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.UNIT"),
            dataIndex: "unitName",
            align:"center",
        },
        {
            title: t("RESOURCES.INVENTORY.PO.COLUMNS.NOTE"),
            dataIndex: "note",
            align:"center",
            render: (_, record, index) => {
                return (
                    isEditing ?
                        <Input
                            name="note"
                            defaultValue={record?.note}
                            onBlur={onInputChange('note', index)}
                        />
                        :
                        <span style={{ alignContent: "left" }}>{record?.note}</span>
                )
            },
        },


    ];
}
