import { Input, InputNumber, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { PIEntity, ProductInfoEntity } from "common/entities";
import dateTimeUtils from "common/utils/date";
import { Link } from 'react-router-dom';
import { Form } from "formik-antd";
import { TFunction } from "react-i18next";
import { formatNumber } from "common/utils/strings";
import { tagStatus } from "common/dataGlobal/defaultTag";
import { SEARCH_DATE_FORMAT } from "common/config";

/**
 * Get all columns of user table
 * @param t Translation function
 * @returns
 */
export function getTablePIColumns(
    t: TFunction,
    currentPage: number,
    pageSize: number
): ColumnsType<PIEntity> {
    return [
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.STT"),
            align: "center",
            render: (_, record, index) => formatNumber(index + 1 + pageSize * (currentPage - 1)),
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.NUMBER_PI"),
            dataIndex: "code",
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <Link to={'/pi/detail/' + record?.id}><span style={{ color: "blue" }}>{record?.code}</span></Link>
                    </Space>
                )
            },
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.CREATED_DATE"),
            align: "center",
            render: (_, record) => dateTimeUtils.formatDate(record?.createdDate),
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.NUMBER_PRODUCT"),
            render: (_, record) => record?.order?.refCode,
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.PI_DATE"),
            dataIndex: "date",
            align: "center",
            render: (_, record) => dateTimeUtils.formatDate(record?.endDate),
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.PLACE_DELIVERY"),
            render: (_, record) => record?.storeDeliver?.name,
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.INVENTORY_NAME"),
            render: (_, record) => record?.storeReceiver?.name,
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.BRAVO_CODE"),
            dataIndex: "bravoCode",
            align: "center",
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.STATUS"),
            dataIndex: "status",
            align: "center",
            render: (text) => tagStatus(text),
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.NOTE"),
            dataIndex: "note",
            width: "20%"
        },
    ];
}

export function getTablePIDetailColumns(t, isEditing): ColumnsType<ProductInfoEntity> {

    return [
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.STT"),
            render: (_, record, index) => index + 1,
            align: "center"
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.SKU"),
            dataIndex: "sku",
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <span style={{ color: "blue" }}>{record?.sku}</span>
                    </Space>
                )
            },
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.PRODUCT"),
            dataIndex: "productName",
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <span style={{ color: "blue" }}>{record?.productName}</span>
                    </Space>
                )
            },
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.NUMBER_ORDER"),
            dataIndex: "orderNumber",
            render: (_, record) => {
                return (
                    <span>{record?.qty}</span>
                )
            },
            align: "center"
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.UNIT"),
            render: (_, record) => {
                return (
                    <span>{record?.unitName}</span>
                )
            },
            align: "center"
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.NUMBER_ORDER_INVEN"),
            dataIndex: "qtyActual",
            render: (_, record) => {
                return (
                    isEditing ?
                        <InputNumber
                            type="number"
                            name="qtyActual"
                            defaultValue={record?.qtyActual}
                            onChange={(value) => { }}

                        />
                        :
                        <span>{record?.qtyActual}</span>
                )
            },
            align: "center"
        },
        {
            title: t("RESOURCES.INVENTORY.PI.COLUMNS.NOTE"),
            dataIndex: "note",
            render: (_, record) => {
                return (
                    isEditing ?
                        <Form.Item
                            name="note">
                            <Input name="note" />
                        </Form.Item>
                        :
                        <span style={{ alignContent: "left" }}>{record?.note}</span>
                )
            },
        },


    ];
}


