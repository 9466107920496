import { ColumnsType } from "antd/lib/table";
import { AdjReceiptDto } from "common/entities/adjReceipt";
import { ManipulationButton } from "components/buttons";
import { TFunction } from "react-i18next";
import { formatNumber } from "common/utils/strings";
import formatDate from "common/utils/date";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Text from "antd/lib/typography/Text";
import { tagStatus } from "common/dataGlobal/defaultTag";
import { useAdjReceiptDetailAction } from "recoil/actions/adjReceiptDetail";
import { useCallback } from "react";
import { AdjReceiptStatus } from "common/config/action-status";
import { DEFAULT_DATE_FORMAT } from "common/config";

const TextLink = styled(Text)`
  color: #1677ff;
`;

export function getTableAdjReceiptColumns(
  t: TFunction,
  currentPage: number,
  pageSize: number
): ColumnsType<AdjReceiptDto> {
  const router = useHistory();
  const { updateStatusAdjReceipt } = useAdjReceiptDetailAction();

  const fetchConfirmData = useCallback(async (id: string, status: number) => {
    await updateStatusAdjReceipt(id, status);
}, [])

  const handleDeleteReceipt = (receipt) => {
    if (receipt?.id) {
      fetchConfirmData(receipt?.id, AdjReceiptStatus.Cancel);
    }
  };
  const handleEditReceipt = (receipt) => {
    const receiptId = receipt?.id;
    router.push(`/adj-receipt-list/detail-adj-receipt/${receiptId}`);
  };
  return [
    {
      key: "1",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.STT"),
      align: "center",
      width: "5%",
      render: (text, record, index) => formatNumber(index + 1 + pageSize * (currentPage - 1)),
    },
    {
      key: "2",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.NUMBER_RECEIPT"),
      dataIndex: "code",
      render: (text, record) => (
        <Link to={`/adj-receipt-list/detail-adj-receipt/${record?.id}`}>
          <TextLink>{text}</TextLink>
        </Link>
      ),
    },
    {
      key: "3",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.OWNER"),
      dataIndex: "invStore",
      render: (text) => <span>{text?.refValue}</span>,
    },
    {
      key: "4",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.INVENTORY"),
      dataIndex: "invStore",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      key: "5",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.CREATE_DATE"),
      align: "center",
      dataIndex: "startDate",
      render: (text) => formatDate.formatDate(text, DEFAULT_DATE_FORMAT),
    },
    {
      key: "6",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.INSPECTION_DATE"),
      align: "center",
      dataIndex: "endDate",
      render: (text) => formatDate.formatDate(text, DEFAULT_DATE_FORMAT),
    },
    {
      key: "7",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.STATUS"),
      align: "center",
      dataIndex: "status",
      render: (text) => tagStatus(text)
    },
    {
      key: "8",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.MANIPULATION"),
      align: "center",
      render: (text, record) => (
        <ManipulationButton
          textCancel={t("RESOURCES.COMMON.ACTIONS.CANCEL")}
          showIcon
          handleDelete={() => handleDeleteReceipt(record)}
          handleEdit={() => handleEditReceipt(record)}
          hiddenDelete={record?.status !== AdjReceiptStatus.New}
        />
      ),
    },
  ];
}
