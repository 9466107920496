import {
    DashboardOutlined,
    DatabaseOutlined,
    ProfileOutlined,
    SettingOutlined,
    ShopOutlined,
    SnippetsOutlined,
} from '@ant-design/icons';
import { NavSideItem } from 'common/types';
import { AUTHORIZE_ROLES } from './common/config/roles';

const NavSideItems: NavSideItem[] = [
    {
        rolesName: [
            AUTHORIZE_ROLES.InventoryMenu,
            AUTHORIZE_ROLES.BomMenu,
            AUTHORIZE_ROLES.BasicMenu,
        ],
        key: 'auth-login-section',
        showOnMenu: false,
        link: true,
        url: '/login',
        lazyElement: () => import('./pages/auth/login'),
        private: false,
        workingEnvironments: ['development', 'staging', 'test', 'production'],
    },
    {
        rolesName: [
            AUTHORIZE_ROLES.InventoryMenu,
            AUTHORIZE_ROLES.BomMenu,
            AUTHORIZE_ROLES.BasicMenu,
        ],
        key: 'auth-forgot-section',
        showOnMenu: false,
        link: true,
        url: '/forgot-password',
        lazyElement: () => import('./pages/auth/forgot-password'),
        private: false,
        workingEnvironments: ['development', 'staging', 'test', 'production'],
    },
    {
        rolesName: [
            AUTHORIZE_ROLES.InventoryMenu,
            AUTHORIZE_ROLES.BomMenu,
            AUTHORIZE_ROLES.BasicMenu,
        ],
        key: 'index-redirect-section',
        group: false,
        link: true,
        url: '/',
        showOnMenu: false,
        private: true,
        lazyElement: () => import('./pages'),
        workingEnvironments: ['development', 'staging', 'test', 'production'],
    },
    {
        rolesName: [
            AUTHORIZE_ROLES.InventoryMenu,
            AUTHORIZE_ROLES.BomMenu,
            AUTHORIZE_ROLES.BasicMenu,
        ],
        key: 'profile-info-section',
        group: false,
        link: true,
        url: '/profile-information',
        showOnMenu: false,
        private: true,
        showOnDashboard: true,
        title: 'RESOURCES.PROFILE.UPDATE_PROFILE',
        lazyElement: () => import('./pages/profile/update'),
        workingEnvironments: ['development', 'staging', 'test', 'production'],
    },
    {
        rolesName: [
            AUTHORIZE_ROLES.InventoryMenu,
            AUTHORIZE_ROLES.BomMenu,
            AUTHORIZE_ROLES.BasicMenu,
        ],
        key: 'change-password-section',
        group: false,
        link: true,
        url: '/change-password',
        showOnMenu: false,
        private: true,
        showOnDashboard: true,
        title: 'RESOURCES.CHANGE_PASSWORD.PAGE_TITLE',
        lazyElement: () => import('./pages/profile/change-password'),
        workingEnvironments: ['development', 'staging', 'test', 'production'],
    },
    {
        rolesName: [
            AUTHORIZE_ROLES.InventoryMenu,
            AUTHORIZE_ROLES.BomMenu,
            AUTHORIZE_ROLES.BasicMenu,
            AUTHORIZE_ROLES.ASM,
            AUTHORIZE_ROLES.RSM,
            AUTHORIZE_ROLES.ExpectedInventory,
            AUTHORIZE_ROLES.MinimumInventory,
            AUTHORIZE_ROLES.ExportReport,
            AUTHORIZE_ROLES.ImportReport,
            AUTHORIZE_ROLES.ExpectedInventory,
        ],
        key: 'dashboard-section',
        group: false,
        link: true,
        title: 'RESOURCES.DASHBOARD.NAME',
        autoTranslate: true,
        url: '/dashboard',
        icon: <DashboardOutlined />,
        showOnMenu: true,
        private: true,
        lazyElement: () => import('./pages/dashboard'),
        showOnDashboard: true,
        workingEnvironments: ['development', 'staging', 'test', 'production'],
    },
    {
        rolesName: [AUTHORIZE_ROLES.InventoryMenu],
        key: 'products-section',
        group: false,
        link: false,
        title: 'RESOURCES.PRODUCTS.NAME',
        autoTranslate: true,
        icon: <DatabaseOutlined />,
        showOnMenu: true,
        private: true,
        workingEnvironments: ['development', 'staging', 'test', 'production'],
        items: [
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'products-list-section',
                openKey: 'products-section',
                title: 'RESOURCES.PRODUCTS.LIST',
                link: true,
                url: '/products-list',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () => import('./pages/products/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
        ],
    },
    {
        rolesName: [AUTHORIZE_ROLES.BomMenu],
        key: 'product-fnb-section',
        group: false,
        link: false,
        title: 'RESOURCES.BOM.PRODUCT_FnB',
        autoTranslate: true,
        icon: <DatabaseOutlined />,
        showOnMenu: true,
        private: true,
        workingEnvironments: ['development', 'staging', 'test', 'production'],
        items: [
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'category-product-section',
                openKey: 'product-fnb-section',
                title: 'RESOURCES.BOM.CATEGORY_PRODUCT.TITLE',
                link: true,
                url: '/category-products',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () => import('./pages/category/CategoryProduct'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'list-product-fnb-section',
                openKey: 'product-fnb-section',
                title: 'RESOURCES.BOM.PRODUCT_FNB_LIST.TITLE',
                link: true,
                url: '/list-products-fnb',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () => import('./pages/productFnB'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
        ],
    },
    {
        rolesName: [AUTHORIZE_ROLES.BomMenu],
        key: 'material-section',
        group: false,
        link: false,
        title: 'RESOURCES.BOM.MATERIAL_MANAGEMENT',
        autoTranslate: true,
        icon: <DatabaseOutlined />,
        showOnMenu: true,
        private: true,
        workingEnvironments: ['development', 'staging', 'test', 'production'],
        items: [
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'category-material-section',
                openKey: 'material-section',
                title: 'RESOURCES.BOM.CATEGORY_MATERIAL.TITLE',
                link: true,
                url: '/category-material',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () => import('./pages/category/CategoryMaterial'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'material-list-section',
                openKey: 'material-section',
                title: 'RESOURCES.BOM.MATERIAL_LIST.TITLE',
                link: true,
                url: '/material-list',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () => import('./pages/material/MaterialList'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
        ],
    },
    {
        rolesName: [AUTHORIZE_ROLES.BomMenu],
        key: 'material-quantification-section',
        group: false,
        link: false,
        title: 'RESOURCES.BOM.MATERIAL_QUANTIFICATION.TITLE',
        autoTranslate: true,
        icon: <DatabaseOutlined />,
        showOnMenu: true,
        private: true,
        workingEnvironments: ['development', 'staging', 'test', 'production'],
        items: [
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'recipe-section',
                openKey: 'material-quantification-section',
                title: 'RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.TITLE',
                link: true,
                url: '/recipe',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () =>
                    import('./pages/materialQuantification/Recipe'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'menu-dish-section',
                openKey: 'material-quantification-section',
                title: 'RESOURCES.BOM.MATERIAL_QUANTIFICATION.MENU_DISH.TITLE',
                link: true,
                url: '/menu-dish',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () =>
                    import('./pages/materialQuantification/MenuDish'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'material-order-section',
                openKey: 'material-quantification-section',
                title: 'RESOURCES.BOM.CATEGORY_ORDER.TITLE',
                link: true,
                url: '/material-order',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () => import('./pages/material/MaterialOrder'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
        ],
    },
    {
        rolesName: [AUTHORIZE_ROLES.BomMenu],
        key: 'purchase-section',
        group: false,
        link: false,
        title: 'RESOURCES.BOM.PURCHASEREQUEST.NAME',
        autoTranslate: true,
        icon: <DatabaseOutlined />,
        showOnMenu: true,
        private: true,
        workingEnvironments: ['development', 'staging', 'test', 'production'],
        items: [
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'purchase-manage-section',
                openKey: 'purchase-section',
                title: 'RESOURCES.BOM.PURCHASEREQUEST.TITLE',
                link: true,
                url: '/purchase',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () => import('./pages/purchaseRequest/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'purchase-detail-section',
                openKey: 'purchase-section',
                title: 'RESOURCES.BOM.PURCHASEREQUEST.DETAIL_NAME',
                link: true,
                url: '/purchase/detail/:id/:type',
                showOnMenu: false,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () =>
                    import('./pages/purchaseRequest/detail/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'purchase-create-section',
                openKey: 'purchase-section',
                title: 'RESOURCES.BOM.PURCHASEREQUEST.CREATE_NAME',
                link: true,
                url: '/purchase/create/:id/:type',
                showOnMenu: false,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () =>
                    import('./pages/purchaseRequest/create/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            // {
            //     rolesName: [AUTHORIZE_ROLES.BomMenu],
            //     key: 'purchase-order-manage-section',
            //     openKey: 'purchase-section',
            //     title: 'RESOURCES.BOM.PURCHASE_ORDER.TITLE',
            //     link: true,
            //     url: '/purchase-order',
            //     showOnMenu: true,
            //     private: true,
            //     showOnDashboard: true,
            //     autoTranslate: true,
            //     lazyElement: () => import('./pages/purchaseOrder/index'),
            //     workingEnvironments: [
            //         'development',
            //         'staging',
            //         'test',
            //         'production',
            //     ],
            // },
            // {
            //     rolesName: [AUTHORIZE_ROLES.BomMenu],
            //     key: 'purchase-order-detail-section',
            //     openKey: 'purchase-section',
            //     title: 'RESOURCES.BOM.PURCHASE_ORDER.DETAIL_NAME',
            //     link: true,
            //     url: '/purchase-order/detail/:id/:type',
            //     showOnMenu: false,
            //     private: true,
            //     showOnDashboard: true,
            //     autoTranslate: true,
            //     lazyElement: () => import('./pages/purchaseOrder/detail/index'),
            //     workingEnvironments: [
            //         'development',
            //         'staging',
            //         'test',
            //         'production',
            //     ],
            // },
            // {
            //     rolesName: [AUTHORIZE_ROLES.BomMenu],
            //     key: 'purchase-order-create-section',
            //     openKey: 'purchase-section',
            //     title: 'RESOURCES.BOM.PURCHASE_ORDER.CREATE_NAME',
            //     link: true,
            //     url: '/purchase-order/create/:id/:type',
            //     showOnMenu: false,
            //     private: true,
            //     showOnDashboard: true,
            //     autoTranslate: true,
            //     lazyElement: () => import('./pages/purchaseOrder/detail/index'),
            //     workingEnvironments: [
            //         'development',
            //         'staging',
            //         'test',
            //         'production',
            //     ],
            // },
        ],
    },
    {
        rolesName: [AUTHORIZE_ROLES.InventoryMenu],
        key: 'inventory-section',
        group: false,
        link: false,
        title: 'RESOURCES.INVENTORIES.NAME',
        showOnMenu: true,
        autoTranslate: true,
        private: true,
        icon: <ShopOutlined />,
        workingEnvironments: ['development', 'staging', 'test', 'production'],
        items: [
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'inventory-management-section',
                openKey: 'inventory-section',
                title: 'RESOURCES.INVENTORIES.MANAGE',
                link: true,
                url: '/inventory-management',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () => import('./pages/inventories/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'create-inventory-section',
                openKey: 'inventory-section',
                title: 'RESOURCES.INVENTORIES.CREATE',
                link: true,
                url: '/inventory-management/inventory-create',
                showOnMenu: false,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () =>
                    import('./pages/inventories/CreateInventory'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'edit-inventory-section',
                openKey: 'inventory-section',
                title: 'RESOURCES.INVENTORIES.EDIT',
                link: true,
                url: '/inventory-management/inventory-edit/:id',
                showOnMenu: false,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () => import('./pages/inventories/editInventory'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'inventory-product-section',
                openKey: 'inventory-section',
                title: 'RESOURCES.INVENTORIES.INVENTORY_PRODUCT',
                link: true,
                url: '/inventory-product',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () =>
                    import('./pages/inventories/InventoryProduct'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
        ],
    },
    {
        rolesName: [AUTHORIZE_ROLES.InventoryMenu],
        key: 'inven-section',
        group: false,
        link: false,
        title: 'RESOURCES.INVENTORY.NAME',
        showOnMenu: true,
        autoTranslate: true,
        private: true,
        icon: <ProfileOutlined />,
        workingEnvironments: ['development', 'staging', 'test', 'production'],
        items: [
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'adj-receipt-list',
                openKey: 'inven-section',
                title: 'RESOURCES.ADJ_RECEIPT.ADJ_RECEIPT_LIST',
                link: true,
                url: '/adj-receipt-list',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () => import('./pages/inventories/AdjReceiptList'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'detail-adj-receipt',
                openKey: 'inven-section',
                title: 'RESOURCES.ADJ_RECEIPT.DETAIL_ADJ_RECEIPT',
                link: true,
                url: '/adj-receipt-list/detail-adj-receipt/:id',
                showOnMenu: false,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () =>
                    import('./pages/inventories/DetailAdjReceipt'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'create-adj-receipt',
                openKey: 'inven-section',
                title: 'RESOURCES.ADJ_RECEIPT.CREATE_ADJ_RECEIPT',
                link: true,
                url: '/adj-receipt-list/create-adj-receipt/:type',
                showOnMenu: false,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () =>
                    import('./pages/inventories/CreateAdjReceipt'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'pi-list-section',
                openKey: 'inven-section',
                title: 'RESOURCES.INVENTORY.PI.NAME',
                link: true,
                url: '/pi',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () => import('./pages/pi/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'view-detail-pi',
                openKey: 'inven-section',
                autoTranslate: true,
                title: 'RESOURCES.INVENTORY.PI.DETAIL_NAME',
                link: true,
                url: '/pi/detail/:id',
                showOnDashboard: true,
                showOnMenu: false,
                private: true,
                lazyElement: () => import('./pages/pi/detail/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'view-create-pi',
                openKey: 'inven-section',
                autoTranslate: true,
                title: 'RESOURCES.INVENTORY.PI.CREATE',
                link: true,
                url: '/pi/create',
                showOnDashboard: true,
                showOnMenu: false,
                private: true,
                lazyElement: () => import('./pages/pi/create/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'po-list-section',
                openKey: 'inven-section',
                title: 'RESOURCES.INVENTORY.PO.NAME',
                link: true,
                url: '/po',
                showOnMenu: true,
                private: true,
                showOnDashboard: true,
                autoTranslate: true,
                lazyElement: () => import('./pages/po/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'view-detail-po',
                openKey: 'inven-section',
                autoTranslate: true,
                title: 'RESOURCES.INVENTORY.PO.DETAIL_NAME',
                link: true,
                url: '/po/detail/:id',
                showOnDashboard: true,
                showOnMenu: false,
                private: true,
                lazyElement: () => import('./pages/po/detail/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'view-create-po',
                openKey: 'inven-section',
                autoTranslate: true,
                title: 'RESOURCES.INVENTORY.PO.CREATE',
                link: true,
                url: '/po/create',
                showOnDashboard: true,
                showOnMenu: false,
                private: true,
                lazyElement: () => import('./pages/po/create/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
        ],
    },
    {
        rolesName: [
            AUTHORIZE_ROLES.InventoryMenu,
            AUTHORIZE_ROLES.BomMenu,
            AUTHORIZE_ROLES.ImportReport,
            AUTHORIZE_ROLES.ExportReport,
            AUTHORIZE_ROLES.InventoryReport,
        ],
        key: 'report-import-export-inventory',
        group: false,
        link: false,
        title: 'RESOURCES.REPORT_IMPORT_EXPORT.NAME',
        showOnMenu: true,
        autoTranslate: true,
        private: true,
        icon: <SnippetsOutlined />,
        workingEnvironments: ['development', 'staging', 'test', 'production'],
        items: [
            {
                rolesName: [
                    AUTHORIZE_ROLES.InventoryMenu,
                    AUTHORIZE_ROLES.BomMenu,
                    AUTHORIZE_ROLES.ImportReport,
                ],
                key: 'report-import-inventory',
                openKey: 'report-import-export-inventory',
                autoTranslate: true,
                title: 'RESOURCES.REPORT_IMPORT_EXPORT.REPORT_IMPORT',
                link: true,
                url: '/report-import-inventory',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () =>
                    import('./pages/reportImportExport/ReportImport'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [
                    AUTHORIZE_ROLES.InventoryMenu,
                    AUTHORIZE_ROLES.BomMenu,
                    AUTHORIZE_ROLES.ExportReport,
                ],
                key: 'report-export-inventory',
                openKey: 'report-import-export-inventory',
                autoTranslate: true,
                title: 'RESOURCES.REPORT_IMPORT_EXPORT.REPORT_EXPORT',
                link: true,
                url: '/report-export-inventory',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () =>
                    import('./pages/reportImportExport/ReportExport'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [
                    AUTHORIZE_ROLES.InventoryMenu,
                    AUTHORIZE_ROLES.BomMenu,
                    AUTHORIZE_ROLES.InventoryReport,
                ],
                key: 'report-inventory',
                openKey: 'report-import-export-inventory',
                autoTranslate: true,
                title: 'RESOURCES.REPORT_IMPORT_EXPORT.REPORT_INVENTORY',
                link: true,
                url: '/report-inventory',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () =>
                    import('./pages/reportImportExport/ReportInventory'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'report-purchase-order',
                openKey: 'report-import-export-inventory',
                autoTranslate: true,
                title: 'RESOURCES.BOM.REPORT.REPORT_PO_DETAIL.NAME',
                link: true,
                url: '/report-purchase-order',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () => import('./pages/purchaseOrder/report/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'report-material-consumption',
                openKey: 'report-import-export-inventory',
                autoTranslate: true,
                title: 'RESOURCES.BOM.REPORT_CONSUMPTION.TITLE',
                link: true,
                url: '/report-material-consumption',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () => import('./pages/reportMaterialConsumption'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
        ],
    },
    {
        rolesName: [
            AUTHORIZE_ROLES.InventoryMenu,
            AUTHORIZE_ROLES.BomMenu,
            AUTHORIZE_ROLES.MinimumInventory,
            AUTHORIZE_ROLES.ExpectedInventory,
        ],
        key: 'establish',
        group: false,
        link: false,
        title: 'RESOURCES.ESTABLISH.NAME',
        showOnMenu: true,
        autoTranslate: true,
        private: true,
        icon: <SettingOutlined />,
        workingEnvironments: ['development', 'staging', 'test', 'production'],
        items: [
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'establish-unit',
                openKey: 'establish',
                autoTranslate: true,
                title: 'RESOURCES.ESTABLISH.UNIT.NAME',
                link: true,
                url: '/establish-unit',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () => import('./pages/establish/EstablishUnit'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'unit-conversion',
                openKey: 'establish',
                autoTranslate: true,
                title: 'RESOURCES.ESTABLISH.UNIT_CONVERSION.NAME',
                link: true,
                url: '/unit-conversion',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () => import('./pages/establish/UnitConversion'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'create-unit-conversion',
                openKey: 'establish',
                autoTranslate: true,
                title: 'RESOURCES.ESTABLISH.UNIT_CONVERSION.CREATE',
                link: true,
                url: '/unit-conversion/create-unit-conversion',
                showOnDashboard: true,
                showOnMenu: false,
                private: true,
                lazyElement: () =>
                    import('./pages/establish/CreateUnitConversion'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'update-unit-conversion',
                openKey: 'establish',
                autoTranslate: true,
                title: 'RESOURCES.ESTABLISH.UNIT_CONVERSION.UPDATE',
                link: true,
                url: '/unit-conversion/update-unit-conversion/:id/:sku',
                showOnDashboard: true,
                showOnMenu: false,
                private: true,
                lazyElement: () =>
                    import('./pages/establish/UpdateUnitConversion'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [
                    AUTHORIZE_ROLES.InventoryMenu,
                    AUTHORIZE_ROLES.MinimumInventory,
                ],
                key: 'update-product-qty-min',
                openKey: 'establish',
                autoTranslate: true,
                title: 'RESOURCES.ESTABLISH.UPDATE_PRODUCT_QTY_MIN.NAME',
                link: true,
                url: '/update-product-qty-min',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () =>
                    import('./pages/establish/updateProductQtyMin'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [
                    AUTHORIZE_ROLES.InventoryMenu,
                    AUTHORIZE_ROLES.ExpectedInventory,
                ],
                key: 'update-product-qty-expect',
                openKey: 'establish',
                autoTranslate: true,
                title: 'RESOURCES.ESTABLISH.UPDATE_PRODUCT_QTY_EXPECT.NAME',
                link: true,
                url: '/update-product-qty-expect',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () =>
                    import('./pages/establish/updateProductQtyExpect'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.InventoryMenu],
                key: 'establish-store',
                openKey: 'establish',
                autoTranslate: true,
                title: 'RESOURCES.ESTABLISH.SETTING_STORE.NAME',
                link: true,
                url: '/setting-store',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () => import('./pages/establish/SettingStore'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'Unit-fnb-list',
                openKey: 'establish',
                autoTranslate: true,
                title: 'RESOURCES.ESTABLISH.UNIT_FNB_LIST.NAME',
                link: true,
                url: '/Unit-fnb-list',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () => import('./pages/establish/UnitFnBList'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'create-unit-fnb-list',
                openKey: 'establish',
                autoTranslate: true,
                title: 'RESOURCES.ESTABLISH.UNIT_FNB_LIST.CREATE',
                link: true,
                url: '/Unit-fnb-list/create-unit-fnb-list',
                showOnDashboard: true,
                showOnMenu: false,
                private: true,
                lazyElement: () =>
                    import('./pages/establish/CreateUnitFnBList'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'edit-unit-fnb-list',
                openKey: 'establish',
                autoTranslate: true,
                title: 'RESOURCES.ESTABLISH.UNIT_FNB_LIST.EDIT',
                link: true,
                url: '/Unit-fnb-list/edit-unit-fnb-list/:id',
                showOnDashboard: true,
                showOnMenu: false,
                private: true,
                lazyElement: () =>
                    import('./pages/establish/CreateUnitFnBList'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [AUTHORIZE_ROLES.BomMenu],
                key: 'convert-unit-fnb-list',
                openKey: 'establish',
                autoTranslate: true,
                title: 'RESOURCES.BOM.CONVERT_FNB_LIST.TITLE',
                link: true,
                url: '/convert-unit-fnb-list',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () =>
                    import('./pages/establish/ConvertUnitFnBList'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
            {
                rolesName: [
                    AUTHORIZE_ROLES.BomMenu,
                    AUTHORIZE_ROLES.InventoryMenu,
                ],
                key: 'log-system',
                openKey: 'establish',
                autoTranslate: true,
                title: 'Lịch sử thao tác',
                link: true,
                url: '/log',
                showOnDashboard: true,
                showOnMenu: true,
                private: true,
                lazyElement: () => import('./pages/log/index'),
                workingEnvironments: [
                    'development',
                    'staging',
                    'test',
                    'production',
                ],
            },
        ],
    },
];

export default NavSideItems;
