import AppRouter from "AppRouter";
import { StyledThemeProvider } from "definitions/styled-components";
import AntdConfigProvider from "AntdConfigProvider";
import i18next from "i18next";
import "moment/locale/vi";
import { I18nextProvider } from "react-i18next";
import { RecoilRoot } from "recoil";
import GlobalStyle from "styles/globalStyles";
import "./i18n";
import "validations/locales";
import TitleProvider from "common/providers/title/TitleProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";

function App(): JSX.Element {
  const [queryClient] = React.useState(() => new QueryClient());
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <I18nextProvider i18n={i18next}>
          <StyledThemeProvider>
            <AntdConfigProvider>
              <TitleProvider>
                <GlobalStyle />
                <AppRouter />
              </TitleProvider>
            </AntdConfigProvider>
          </StyledThemeProvider>
        </I18nextProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
