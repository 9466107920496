import { DownOutlined } from '@ant-design/icons';
import { Avatar, Space, Typography } from 'antd';
import { IMG_EMPTY } from 'common/config';
import { LayoutWidthType, ProfileMenuProps } from 'common/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuthAction } from 'recoil/actions/auth';
import { useGetLayoutWidth } from 'recoil/hooks';
import { useGetProfile } from 'recoil/hooks/useProfile';

const { Text } = Typography;

const ProfileMenu: React.FC<ProfileMenuProps> = () => {
  const [username, setUsername] = useState<string>("Administrator");
  const [imageURL, setImageURL] = useState<string>(IMG_EMPTY)
  const { getProfile } = useAuthAction();
  const profile = useGetProfile();
  const widthType = useGetLayoutWidth();

  const fetchProfile = useCallback(async () => {
    await getProfile();
  }, []);

  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    if (profile) {
        setUsername(profile.displayName || profile.emailAddress || profile.fullname);
        setImageURL(profile.photoURL ? profile.photoURL : IMG_EMPTY);
    }
  }, [profile]);

  return (
    <Space align='baseline' className='profile-header-menu'>
      <Avatar src={imageURL} className='avatar' />
      {
        widthType === LayoutWidthType.Desktop ? (
          <>
            <Text className='username'>{username}</Text>
            <DownOutlined className='dropdown-arrow' style={{ fontSize: '0.75em' }} />
          </>
        ) : (<></>)
      }

    </Space>
  );
};

export default ProfileMenu;