import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'
import { ButtonWithIconProps } from './ButtonWithIconProps'
import PropTypes from 'prop-types'

const EditButton: React.FC<ButtonWithIconProps> = ({
  showIcon,
  minimal,
  danger,
  content,
  ...rest
}) => {
  const { t } = useTranslation()
    return (
        <Button type="primary" {...rest} icon={showIcon ? <EditOutlined /> : null} className="btn-edit-custom">
      {content || t('RESOURCES.COMMON.ACTIONS.EDIT')}
    </Button>
  )
}

EditButton.propTypes = {
  showIcon: PropTypes.bool,
}

export default EditButton
