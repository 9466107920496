import React from 'react';
import TitleContext, { DEFAULT_TITLE_REDUCER_VALUE } from './TitleContext';

const TitleProvider: React.FC = ({ children }) => {

  return (
    <TitleContext.Provider value={DEFAULT_TITLE_REDUCER_VALUE}>
      {children}
    </TitleContext.Provider>
  )
};

export default TitleProvider;