import {
  UnitConversionListByProductRequest,
  UnitConversionListRequest,
  UpdateUnitConversionRequest,
  DeleteUnitRequest,
  DetailUnitRequest,
  UnitCreateRequest,
  UnitData,
  UnitListRequest,
  UpdateUnitRequest,
  DeleteUnitConversionRequest,
  CreateUnitConversionRequest,
  ExportUnitListRequest,
  ExportUnitConversionListRequest,
  ImportUnitConversionRequest,
  ExportUnitConversionTemplateRequest,
  SaveImportUnitConversionRequest,
} from "common/entities/unit";
import { CrudState } from "common/types";
import { useSetExportBlobData, useSetUnitDetail } from "recoil/hooks";
import { useSetCreateResult } from "recoil/hooks/useCreateResponseData";
import { useSetCrudActionState } from "recoil/hooks/useCrudActionState";
import { useSetUnitConversionListByProduct } from "recoil/hooks/useUnitConversionListByProductState";
import { useSetUnitConversionList } from "recoil/hooks/useUnitConversionListState";
import { useSetDeleteResult } from "recoil/hooks/useDeleteResponseData";
import { useSetUnitList } from "recoil/hooks/useUnitList";
import { useSetUpdateResult } from "recoil/hooks/useUpdateResponseData";
import unitService from "services/unit";
import { getSetUnitConversionImport } from "recoil/hooks/useUnitConversionImport";

export function useUnitMutations() {
  const setUnitConversionList = useSetUnitConversionList();
  const setUnitList = useSetUnitList();
  const setUnitDetail = useSetUnitDetail();
  const setCrudState = useSetCrudActionState();
  const setUnitConversionListByProduct = useSetUnitConversionListByProduct();
  const setUpdateUnitConversionResult = useSetUpdateResult();
  const setCreateUnitResult = useSetCreateResult();
  const setDeleteUnitResult = useSetDeleteResult();
  const setUpdateUnitResult = useSetUpdateResult();
  const setExportResult = useSetExportBlobData();
  const setExportUnitConversionList = useSetExportBlobData();
  const setUnitConversionImport = getSetUnitConversionImport();

  const getUnitConversionList = async (params: UnitConversionListRequest) => {
    const requestParams = {
      ...params,
    };
    const { data, statusCode } = await unitService.getUnitConversionList(requestParams);
    if (statusCode !== 200) {
      setUnitConversionList(null);
      setCrudState(CrudState.ServerError);
    } else if (data) {
      setUnitConversionList(data);
      setCrudState(CrudState.Succeed);
    } else {
      setUnitConversionList(null);
      setCrudState(CrudState.NotFound);
    }
  };

  const getUnitList = async (params: UnitListRequest) => {
    const requestParams = {
      ...params,
    };
    const { data, statusCode } = await unitService.getUnitList(requestParams);

    if (statusCode !== 200) {
      setUnitList(null);
      setCrudState(CrudState.ServerError);
    } else if (data) {
      setUnitList(data);
      setCrudState(CrudState.Succeed);
    } else {
      setUnitList(null);
      setCrudState(CrudState.NotFound);
    }
  };

  const getUnitConversionListByProduct = async (params: UnitConversionListByProductRequest) => {
    const requestParams = {
      ...params,
    };
    const { data, statusCode } = await unitService.getUnitConversionListByProduct(requestParams);
    if (statusCode !== 200) {
      setUnitConversionListByProduct(null);
      setCrudState(CrudState.ServerError);
    } else if (data) {
      setUnitConversionListByProduct(data);
      setCrudState(CrudState.Succeed);
    } else {
      setUnitConversionListByProduct(null);
      setCrudState(CrudState.NotFound);
    }
  };

  const addUnitConversion = async (params: CreateUnitConversionRequest) => {
    const { fullResponse, errors, statusCode } = await unitService.addUnitConversion(params);
    if (statusCode !== 200) {
      setCreateUnitResult(errors);
    } else if (fullResponse) {
      const dataNew = { ...fullResponse, statusCode: `${fullResponse?.statusCode}`, date: new Date() };
      setCreateUnitResult(dataNew);
    } else {
      setCrudState(CrudState.NotFound);
    }
  };

  const deleteUnitConversion = async (params: DeleteUnitConversionRequest) => {
    const requestParams = {
      ...params,
    };
    const { fullResponse, errors, statusCode } = await unitService.deleteUnitConversion(requestParams);
    if (statusCode !== 200) {
      setDeleteUnitResult(errors);
    } else if (fullResponse) {
      const dataNew = { ...fullResponse, statusCode: `${fullResponse?.statusCode}`, date: new Date() };
      setDeleteUnitResult(dataNew);
    } else {
      setCrudState(CrudState.NotFound);
    }
  };

  const exportUnitConversionList = async (params: ExportUnitConversionListRequest) => {
    const requestParams = {
      ...params,
    };
    const { fullResponse, statusCode } = await unitService.exportUnitConversionList(requestParams);
    if (statusCode !== 200) {
      setExportUnitConversionList(null);
      setCrudState(CrudState.ServerError);
    } else if (fullResponse) {
      setExportUnitConversionList(fullResponse);
    } else {
      setCrudState(CrudState.NotFound);
    }
  };

  const getUnitDetail = async (params: DetailUnitRequest) => {
    const requestParams = {
      ...params,
    };
    const { data, statusCode } = await unitService.getUnitDetail(requestParams);

    if (statusCode !== 200) {
      setUnitDetail(null);
      setCrudState(CrudState.ServerError);
    } else if (data) {
      setUnitDetail(data);
    } else {
      setUnitDetail(null);
      setCrudState(CrudState.NotFound);
    }
  };

  const updateUnitConversionListByProduct = async (params: UpdateUnitConversionRequest) => {
    const requestParams = { ...params };
    const { data, statusCode, errors, fullResponse } = await unitService.updateUnitConversion(requestParams);
    if (statusCode !== 200) {
      setUpdateUnitConversionResult(errors);
    } else if (fullResponse) {
      const newData = { ...data, statusCode: `${fullResponse?.statusCode}`, date: new Date() };
      setUpdateUnitConversionResult(newData);
      setCrudState(CrudState.Succeed);
    } else {
      setCrudState(CrudState.NotFound);
    }
  };

  const getExportUnitConversionTemplate = async (params: ExportUnitConversionTemplateRequest) => {
    const { fullResponse, statusCode } = await unitService.exportUnitConversionTemplate(params);
    if (statusCode !== 200) {
      setExportResult(null);
      setCrudState(CrudState.ServerError);
    } else if (fullResponse) {
      setExportResult(fullResponse);
    } else {
      setExportResult(null);
      setCrudState(CrudState.NotFound);
    }
  };

  const importUnitConversion = async (params: ImportUnitConversionRequest) => {
    const { data, statusCode } = await unitService.importUnitConversion(params);
    if (statusCode !== 200) {
      setUnitConversionImport(null);
      setCrudState(CrudState.ServerError);
    } else if (data) {
      setUnitConversionImport(data);
    } else {
      setUnitConversionImport(null);
      setCrudState(CrudState.NotFound);
    }
  };

  const saveImportUnitConversion = async (params: SaveImportUnitConversionRequest) => {
    const { statusCode } = await unitService.saveImportUnitConversion(params);
    if (statusCode === 200 ) { 
      setCrudState(CrudState.Updated);
    } else if (statusCode == 400) {
      setCrudState(CrudState.Failed);
    } else {
      setCrudState(CrudState.ServerError);
    }
  };

  const createUnit = async (params: UnitCreateRequest) => {
    const requestParams = {
      ...params,
    };
    const { fullResponse, errors, statusCode } = await unitService.createUnit(requestParams);
    if (statusCode !== 200) {
      setCreateUnitResult(errors);
    } else if (fullResponse) {
      const dataNew = { ...fullResponse, statusCode: `${fullResponse.statusCode}`, date: new Date() };
      setCreateUnitResult(dataNew);
      setCrudState(CrudState.Succeed);
    } else {
      setCrudState(CrudState.NotFound);
    }
  };

  const deleteUnit = async (params: DeleteUnitRequest) => {
    const paramsRequest = {
      ...params,
    };
    const { data, statusCode, errors, fullResponse } = await unitService.deleteUnit(paramsRequest);

    if (statusCode !== 200) {
      setDeleteUnitResult(errors);
    } else if (data) {
      const dataNew = { ...fullResponse, statusCode: `${fullResponse.statusCode}`, date: new Date() };
      setDeleteUnitResult(dataNew);
      setCrudState(CrudState.Succeed);
    } else {
      setCrudState(CrudState.NotFound);
    }
  };

  const updateUnit = async (body: UnitData, params: UpdateUnitRequest) => {
    const { data, statusCode, errors, fullResponse } = await unitService.updateUnit(body, params);
    if (statusCode !== 200) {
      setUpdateUnitResult(errors);
    } else if (data) {
      const dataNew = { ...fullResponse, statusCode: `${fullResponse.statusCode}`, date: new Date() };
      setUpdateUnitResult(dataNew);
      setCrudState(CrudState.Succeed);
    } else {
      setCrudState(CrudState.NotFound);
    }
  };

  const exportUnitList = async (params: ExportUnitListRequest) => {
    const { fullResponse, statusCode } = await unitService.exportUnitList(params);
    if (statusCode !== 200) {
      setExportResult(null);
      setCrudState(CrudState.ServerError);
    } else if (fullResponse) {
      setExportResult(fullResponse);
    } else {
      setCrudState(CrudState.NotFound);
    }
  };

  return {
    getUnitConversionList,
    getUnitList,
    createUnit,
    deleteUnit,
    updateUnit,
    getUnitDetail,
    deleteUnitConversion,
    getUnitConversionListByProduct,
    updateUnitConversionListByProduct,
    addUnitConversion,
    exportUnitList,
    exportUnitConversionList,
    getExportUnitConversionTemplate,
    importUnitConversion,
    saveImportUnitConversion
  };
}
