import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { BOMProductData } from "common/entities/BOMProductList";
import { TFunction } from "i18next";
import { formatNumber } from "common/utils/strings";

const { Text } = Typography;
export function getTableImportBOMSemiProduct(t: TFunction): ColumnsType<BOMProductData> {
  return [
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.STT"),
      width: "5%",
      align: "center",
      dataIndex: "stt",
      render: (text) => formatNumber(text),
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.BRAND"),
      dataIndex: "brandCode",
      align: "center",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.CODE_STORE"),
      dataIndex: "storeNo",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.CODE_RECIPE_SEMI"),
      dataIndex: "bomSemiProductsCode",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.NAME_RECIPE_SEMI"),
      dataIndex: "bomSemiProductsName",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.QUANTITATIVE_SEMI"),
      dataIndex: "bomSemiProductsQuantity",
      align: "center",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.CODE_UNIT_SEMI"),
      dataIndex: "bomSemiProductsUnitCode",
      align: "center",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.SKU"),
      dataIndex: "materialsSku",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.CODE_BOM"),
      dataIndex: "materialsCode",
      align: "center",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.NAME_MATERIAL"),
      dataIndex: "materialsName",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.QUANTITATIVE_MATERIAL"),
      dataIndex: "materialsQuantity",
      align: "center",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.CODE_UNIT_MATERIAL"),
      dataIndex: "materialsUnitCode",
      align: "center",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.ERROR"),
      dataIndex: "errorNote",
      render: (text) => <Text type="danger">{text}</Text>,
      width: "20%",
    },
  ];
}
