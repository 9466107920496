import { HttpClient, httpClient } from 'common/http';
import { AxiosRequestHeaders, CancelToken } from 'axios';
import { BaseInventoryApiResponse } from 'common/entities/baseRootResponse';
import { BodyData, HttpParameter } from 'common/types/http';

export default abstract class BaseService {
  private readonly httpService: HttpClient;

  protected constructor() {
    this.httpService = httpClient;
  }

  /**
   * Performs `GET` JSON request. This methods is shorthand for `axios.get(url, { headers: {'content-type': 'application/json', responseType: 'json' }})`
   * @param path is the server resource path that will be used for the request
   * @param queryParams are the URL parameters / query string to be sent with the request. Must be a `plain object` or an `URLSearchParams` object
   * @param cancelToken is Cancellation token to cancelling the request
   * @returns Axios response wrapped by Promise with response type force to `JSON`
   */
  protected getJSON<
    D extends BodyData,
    R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(path: string, queryParams?: HttpParameter, cancelToken?: CancelToken) {
    return this.httpService.getJSON<D, R>(path, queryParams, cancelToken);
  }

  /**
   * Download a file using GET method, response as a blob
   * @param path is the server resource path that will be used for the request
   * @param queryParams are the URL parameters / query string to be sent with the request. Must be a `plain object` or an `URLSearchParams` object
   * @param cancelToken is Cancellation token to cancelling the request
   * @returns
   */
  protected getDownload(
    path: string,
    queryParams?: HttpParameter,
    cancelToken?: CancelToken,
  ) {
    return this.httpService.getDownload(path, queryParams, cancelToken);
    }

  /**
   * Performs `GET` request
   * @param path is the server resource path that will be used for the request
   * @param queryParams are the URL parameters / query string to be sent with the request. Must be a plain object or an `URLSearchParams` object
   * @param headers are custom headers to be sent
   * @param cancelToken is Cancellation token to cancelling the request
   * @returns Axios response wrapped by Promise
   */
  protected get<
    D extends BodyData,
    R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(
    path: string,
    queryParams?: HttpParameter,
    headers?: AxiosRequestHeaders,
    cancelToken?: CancelToken,
  ) {
    return this.httpService.get<D, R>(path, queryParams, headers, cancelToken);
  }

  /**
   * Performs `POST` request
   * @param path is the server resource path that will be used for the request
   * @param body is the request body to be used when making the request
   * @param queryParams are the URL parameters / query string to be sent with the request. Must be a `plain object` or an `URLSearchParams` object
   * @param headers are custom headers to be sent
   * @param cancelToken is Cancellation token to cancelling the request
   * @returns Axios response wrapped by Promise
   */
  protected post<
    B extends BodyData,
    D extends BodyData,
    R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(
    path: string,
    body: B,
    queryParams?: HttpParameter,
    headers?: AxiosRequestHeaders,
    cancelToken?: CancelToken,
  ) {
    return this.httpService.post<B, D, R>(
      path,
      body,
      queryParams,
      headers,
      cancelToken,
    );
  }


    protected postBlob<
        B extends BodyData,
        D extends BodyData,
        R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(
        path: string,
        body: B,
        queryParams?: HttpParameter,
        headers?: AxiosRequestHeaders,
        cancelToken?: CancelToken,
    ) {
        return this.httpService.postBlob<B, D, R>(
            path,
            body,
            queryParams,
            headers,
            cancelToken,
        );
    }


    protected getBlob<
        B extends BodyData,
        D extends BodyData,
        R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(
        path: string,
        body: B,
        queryParams?: HttpParameter,
        headers?: AxiosRequestHeaders,
        cancelToken?: CancelToken,
    ) {
        return this.httpService.getBlob<B, D, R>(
            path,
            body,
            queryParams,
            headers,
            cancelToken,
        );
    }

  /**
   * Performs `POST` request with FormData as a body. Useful to upload file
   * @param path is the server resource path that will be used for the request
   * @param body is the request body with FormData type to be used when making the request
   * @param queryParams are the URL parameters / query string to be sent with the request. Must be a `plain object` or an `URLSearchParams` object
   * @param headers are custom headers to be sent
   * @param cancelToken is Cancellation token to cancelling the request
   * @returns Axios response wrapped by Promise
   */
  protected postFormData<
    D extends BodyData,
    R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(
    path: string,
    body: FormData,
    queryParams?: HttpParameter,
    headers?: AxiosRequestHeaders,
    cancelToken?: CancelToken,
  ) {
    headers = {
      ...headers,
      'Content-Type': 'multipart/form-data',
    };
    return this.httpService.postFormData<D, R>(
      path,
      body,
      queryParams,
      headers,
      cancelToken,
    );
  }

  /**
   * Performs `PUT` request
   * @param path is the server resource path that will be used for the request
   * @param body is the request method to be used when making the request
   * @param queryParams are the URL parameters / query string to be sent with the request. Must be a `plain object` or an `URLSearchParams` object
   * @param headers are custom headers to be sent
   * @param cancelToken is Cancellation token to cancelling the request
   * @returns Axios response wrapped by Promise
   */
  protected put<
    B extends BodyData,
    D extends BodyData,
    R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(
    path: string,
    body: B,
    queryParams?: HttpParameter,
    headers?: AxiosRequestHeaders,
    cancelToken?: CancelToken,
  ) {
    return this.httpService.put<B, D, R>(
      path,
      body,
      queryParams,
      headers,
      cancelToken,
    );
  }

  /**
   * Performs `DELETE` request
   * @param path is the server resource path that will be used for the request
   * @param queryParams are the URL parameters / query string to be sent with the request. Must be a `plain object` or an `URLSearchParams` object
   * @param headers are custom headers to be sent
   * @param cancelToken is cancellation token to cancelling the request
   * @returns Axios response wrapped by Promise
   */
  protected delete<
    D extends BodyData,
    R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(
    path: string,
    queryParams?: HttpParameter,
    headers?: AxiosRequestHeaders,
    cancelToken?: CancelToken,
  ) {
    return this.httpService.delete<D, R>(
      path,
      queryParams,
      headers,
      cancelToken,
    );
  }
}
