import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { authAtom } from 'recoil/atoms/auth/authState';

export const useAuthState: () => [boolean, (state: boolean) => void] = () => {
  const [currentAuthState, setCurrentAuthState] = useRecoilState(authAtom);

  const toggleAuthState = (state: boolean) => {
    setCurrentAuthState(state);
  };

  return [currentAuthState, toggleAuthState];
}

export const useGetAuthState: () => boolean = () => {
  return useRecoilValue(authAtom);
};

export const useSetAuthState = () => {
  const reset = useResetRecoilState(authAtom);
  const set = useSetRecoilState(authAtom);

  const setAuthState = (state: boolean) => {
    reset();
    set(state);
  };

  return setAuthState;
};