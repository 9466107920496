import loadable from '@loadable/component';
import { LayoutWidthType } from 'common/types';
import React, { CSSProperties } from 'react';
import { useGetLayoutWidth } from 'recoil/hooks';
import AppLogo from 'components/logo';
import NavSiderMenu from './NavSiderMenu';

const AppSider: React.FC = () => {
  const layoutWidth = useGetLayoutWidth();
  const SideBar = loadable(() => import('./SideBar'));
  const AppDrawer = loadable(() => import('./AppDrawer'));
  const ScrollableContainer = loadable(() => import('../scrollable-container'));

  const style: CSSProperties = {
    maxHeight: 'calc(100vh - 70px)',
    overflowX: 'hidden'
  };

  return (
    <>
      {
        layoutWidth === LayoutWidthType.Desktop ? (
          <SideBar>
            <AppLogo />
            <ScrollableContainer style={style}>
              <NavSiderMenu />
            </ScrollableContainer>
          </SideBar>) : (
          <AppDrawer>
            <AppLogo />
            <ScrollableContainer style={style}>
              <NavSiderMenu />
            </ScrollableContainer>
          </AppDrawer>
        )
      }
    </>
  )
};

export default AppSider;
