import { Checkbox } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SEARCH_DATE_FORMAT } from "common/config";
import { iconActive } from "common/dataGlobal/defaultTag";
import { BOMProductData } from "common/entities/BOMProductList";
import formatDate from "common/utils/date";
import { TFunction } from "i18next";

export function getTableRecipe(t: TFunction): ColumnsType<BOMProductData> {
  return [
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.STT"),
      width: "5%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.BRAND"),
      dataIndex: "brandCode",
      align: "center",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.CODE_STORE"),
      dataIndex: "storesInfo",
      render: (text) => <span>{text.storeNo}</span>,
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.NAME_STORE"),
      dataIndex: "storesInfo",
      render: (text) => <span>{text.name}</span>,
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.CODE_RECIPE"),
      dataIndex: "code",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.NAME_RECIPE"),
      dataIndex: "name",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.CREATE_DATE"),
      dataIndex: "creationTime",
      render: (text) => formatDate.formatDate(text),
      align: "center",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.UPDATE_DATE"),
      dataIndex: "lastModificationTime",
      render: (text) => formatDate.formatDate(text),
      align: "center",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.STATUS"),
      dataIndex: "isActive",
      align: "center",
      render: (text) => iconActive(text),
    },
  ];
}
