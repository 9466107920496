import React from 'react';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/vi_VN';
import { customizeRenderEmpty } from 'components/custom-empty';
import { useTranslation } from 'react-i18next';

const AntdConfigProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <ConfigProvider locale={locale} renderEmpty={customizeRenderEmpty(t)}>
      {children}
    </ConfigProvider>
  )
};

export default AntdConfigProvider;