import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { wardAtom } from "recoil/atoms/location/WardAtom";
import { WardDto } from "common/entities/location";

export function useGetWard() {
    return useRecoilValue(wardAtom);
}

export function useSetWard() {
    const reset = useResetRecoilState(wardAtom);
    const set = useSetRecoilState(wardAtom);
    return (value: WardDto[] | ((currVal: WardDto[]) => WardDto[])) => {
        reset();
        set(value);
    }
}

export function useResetWard() {
    return useResetRecoilState(wardAtom);
}
