import { useTranslation } from "react-i18next";
import { ButtonWithIconProps } from "./ButtonWithIconProps";
import PropTypes from "prop-types";
import { Button } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
const ImportButton: React.FC<ButtonWithIconProps> = ({ showIcon, content, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button
      type="primary"
      {...rest}
      icon={showIcon ? <VerticalAlignBottomOutlined /> : null}
      className="btn-exp-imp-custom"
    >
      {content ? content : t("RESOURCES.COMMON.ACTIONS.IMPORT")}
    </Button>
  );
};

ImportButton.propTypes = {
  showIcon: PropTypes.bool,
};

export default ImportButton;
