import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWithIconProps } from './ButtonWithIconProps';

const UpdateButton: React.FC<ButtonWithIconProps> = ({ showIcon, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button {...rest} type='primary' icon={ showIcon ? <CheckCircleOutlined /> : null}>
      {t('RESOURCES.COMMON.ACTIONS.UPDATE')}
    </Button>
  );
};

UpdateButton.propTypes = {
  showIcon: PropTypes.bool
};

export default UpdateButton;