import { Button } from 'antd';
import { ButtonWithIconProps } from './ButtonWithIconProps'
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined } from '@ant-design/icons'


const CancelButton: React.FC<ButtonWithIconProps> = ({ showIcon=true, children, ...rest }) => {
  const { t } = useTranslation();
    return (
        <Button type='primary' {...rest} icon={showIcon ? <CloseCircleOutlined /> : null} className="btn-cancel-custom">
      {t('RESOURCES.COMMON.ACTIONS.CANCEL')}
    </Button>
  );
};

export default CancelButton;