import { UnitConversionListResponse } from "common/entities/unit";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { unitConversionListAtom } from "recoil/atoms/unit/UnitConversionList";

export function useGetUnitConversionList() {
    return useRecoilValue(unitConversionListAtom);
}

export function useSetUnitConversionList() {
    const reset = useResetRecoilState(unitConversionListAtom)
    const set = useSetRecoilState(unitConversionListAtom);
    return (value: UnitConversionListResponse | ((pre: UnitConversionListResponse) => UnitConversionListResponse )) => {
        reset();
        set(value);
    };
}

export function useResetUnitConversionList() {
    return useResetRecoilState(unitConversionListAtom);
}
