import { TFunction } from 'i18next';
import { formatNumber } from 'common/utils/strings';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { ColumnsType } from 'antd/lib/table';
import { LogDTO } from 'common/entities/invStore/invStoreList';
import { dateUtils } from '..';
import { Link } from 'react-router-dom';
import { point } from 'leaflet';

export function getTableSettingStore(
  t: TFunction,
  pageNumber?: number,
  pageSize?: number,
  handleClick?: (id: string, e: any, field: string) => void,
) {
  return [
    {
      title: t('RESOURCES.ESTABLISH.SETTING_STORE.COLUMNS.STT'),
      render: (text, record, index: number) => {
        return formatNumber(index + 1 + pageSize * (pageNumber - 1));
      },
      width: '5%',
      editable: false,
      align: 'center' as 'center',
    },
    {
      title: t('RESOURCES.ESTABLISH.SETTING_STORE.COLUMNS.NPP'),
      dataIndex: 'refValue',
      width: '15%',
      render: (text, record) => <span>{record?.storeInfo?.refValue}</span>,
      editable: false,
    },
    {
      title: t('RESOURCES.ESTABLISH.SETTING_STORE.COLUMNS.STORE_NAME'),
      dataIndex: 'storeInfo?.name',
      width: '15%',
      render: (text, record) => <span>{record?.storeInfo?.name}</span>,
      editable: false,
    },
    {
      title: t('RESOURCES.ESTABLISH.SETTING_STORE.COLUMNS.ISCREATE_PO_MINUS'),
      dataIndex: 'isCreatePOMinus',
      width: '5%',
      editable: true,
      render: (text, record) => {
        return (
          <Checkbox
            key={record?.id}
            defaultChecked={record?.isCreatePOMinus}
            onClick={(e) => handleClick(record?.id, e, 'isCreatePOMinus')}
          />
        );
      },
      align: 'center' as 'center',
    },
    {
      title: t('RESOURCES.ESTABLISH.SETTING_STORE.COLUMNS.NOTIFICATION'),
      width: '5%',
      editable: true,
      render: (text, record) => {
        return (
          <Checkbox
            key={record?.id}
            defaultChecked={record?.isNotifyQtyMin}
            onClick={(e) => handleClick(record?.id, e, 'isNotifyQtyMin')}
          />
        );
      },
      align: 'center' as 'center',
    },
  ];
}

export function getTableLog(
  t: TFunction,
  pageNumber?: number,
  pageSize?: number,
  handleClick?: (e) => void,
): ColumnsType<LogDTO> {
  return [
    {
      title: t('RESOURCES.ESTABLISH.SETTING_STORE.COLUMNS.STT'),
      render: (text, record, index: number) => {
        return formatNumber(index + 1 + pageSize * (pageNumber - 1));
      },
      width: '5%',
      align: 'center' as 'center',
    },
    {
      title: 'Tên API',
      width: '5%',
      render: (text, record) => (
        <span onClick={() => handleClick(record)} style={{ color: 'blue', cursor: 'pointer' }}>
          {record?.name}
        </span>
      ),
    },
    {
      title: 'Ngày thực hiện',
      dataIndex: 'storeInfo?.name',
      width: '5%',
      render: (text, record) => <span>{dateUtils.formatDate(record?.createdDate)}</span>,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      width: '15%',
      ellipsis: true,
      render: (text, record) => <span>{record?.description}</span>,
    },
    {
      title: 'Kết quả',
      dataIndex: 'response',
      width: '15%',
      ellipsis: true,
      render: (text, record) => <span>{record?.response}</span>,
    },
  ];
}
