import type {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosRequestHeaders, AxiosResponse,
    CancelToken,
    CancelTokenSource,
} from 'axios';
import axios from 'axios';
import { BaseInventoryApiResponse } from 'common/entities/baseRootResponse';
import { HttpServiceResponse } from 'common/entities/http/axioxRequestResponse';
import { BodyData, HttpParameter, Interceptors } from 'common/types/http';
import { decrypt } from 'common/utils';
import { AUTH_STORAGE_KEY } from 'common/config';

/**
 * The common HTTP client service class implements the Axios library to handle Restful request
 * @example
 *
 * import { HttpClient } from 'path/to/http-client'
 *
 * const client = new HttpClient();
 *
 * // GET request
 * client.get('/foo', { id: 1 })
 *  .then(data => console.log('data', data));
 *
 * or use the default HttpClient instance
 *
 * import httpClient from '/path/to/http-client'
 *
 * //GET request
 * httpClient.get('/foo', { id: 1 })
 *  .then(data => console.log('data', data))
 */
export class HttpClient {
    protected readonly xhr: AxiosInstance;

    /**
     * Create a new HttpClient instance with default configuration to call Restful Service
     */
    constructor(baseURL: string) {

        const token = HttpClient.getToken();
        this.xhr = axios.create({
            headers: {
                'content-type': 'application/json',
                DeviceCode: 'web-inventory',
                Authorization: token ? `Bearer ${token}` : null,
            },
            maxContentLength: Infinity,
            withCredentials: false,
            timeout: 900000,
            baseURL,
            responseType: 'json',
        });

        this.xhr.interceptors.request.use(async (requestConfig) => requestConfig);

        this.xhr.interceptors.response.use(
            (r) => r,
            async (error: AxiosError) => {
                if (error?.response?.status === 401) {
                    //localStorage.clear();
                    //document.cookie = '';
                }
                await Promise.reject(error);
            },
        );
    }

    public static getToken(): string {
        const rawToken = localStorage.getItem(AUTH_STORAGE_KEY);
        if (rawToken) {
            try {
                return decrypt(rawToken);
            } catch (e) {
                return null;
            }
        }
    }

    /**
     * Get all interceptors of current an Axios instance
     */
    public get interceptors(): Interceptors {
        return this.xhr.interceptors;
    }

    /**
     * Create a cancellation token source
     * @returns The cancellation token source
     */
    public static getCancelTokenSource(): CancelTokenSource {
        return axios.CancelToken.source();
    }

    /**
     * Performs `GET` JSON request. These methods are shorthand for `axios.get(url, { headers: {'content-type': 'application/json', responseType: 'json' }})`
     * @param path is the server resource path that will be used for the request
     * @param queryParams are the URL parameters / query string to be sent with the request. Must be a `plain object` or an `URLSearchParams` object
     * @param cancelToken is Cancellation token to cancelling the request
     * @returns Axios response wrapped by Promise with response type force to `JSON`
     */
    getJSON<
        D extends BodyData,
        R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(path: string, queryParams?: HttpParameter, cancelToken?: CancelToken) {
        return this.request<object, D, R>({
            url: path,
            params: queryParams,
            method: 'GET',
            cancelToken,
        });
    }

    /**
     * Download a file using GET method, response as a blob
     * @param path is the server resource path that will be used for the request
     * @param queryParams are the URL parameters / query string to be sent with the request. Must be a `plain object` or an `URLSearchParams` object
     * @param cancelToken is Cancellation token to cancelling the request
     * @returns
     */
    getDownload(
        path: string,
        queryParams?: HttpParameter,
        cancelToken?: CancelToken,
    ) {
        return new Promise<Blob>((resolve, reject) => {
            this.xhr
                .get(path, {
                    params: queryParams,
                    headers: { 'content-type': 'application/json' },
                    responseType: 'blob',
                    cancelToken,
                })
                .then(({ data }) => {
                    resolve(new Blob([data]));
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    /**
     * Performs `GET` request
     * @param path is the server resource path that will be used for the request
     * @param queryParams are the URL parameters / query string to be sent with the request. Must be a plain object or an `URLSearchParams` object
     * @param headers are custom headers to be sent
     * @param cancelToken is Cancellation token to cancelling the request
     * @returns Axios response wrapped by Promise
     */
    get<D extends BodyData, R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>>(
        path: string,
        queryParams?: HttpParameter,
        headers?: AxiosRequestHeaders,
        cancelToken?: CancelToken,
    ) {
        return this.request<object, D, R>({
            url: path,
            params: queryParams,
            headers,
            method: 'GET',
            cancelToken,
        });
    }

    /**
     * Performs `POST` request
     * @param path is the server resource path that will be used for the request
     * @param body is the request body to be used when making the request
     * @param queryParams are the URL parameters / query string to be sent with the request. Must be a `plain object` or an `URLSearchParams` object
     * @param headers are custom headers to be sent
     * @param cancelToken is Cancellation token to cancelling the request
     * @returns Axios response wrapped by Promise
     */
    post<
        B extends BodyData,
        D extends BodyData,
        R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(
        path: string,
        body: B,
        queryParams?: HttpParameter,
        headers?: AxiosRequestHeaders,
        cancelToken?: CancelToken,
    ) {
        return this.request<B, D, R>({
            url: path,
            params: queryParams,
            headers,
            method: 'POST',
            cancelToken,
            data: body,
        });
    }

    postBlob<
        B extends BodyData,
        D extends BodyData,
        R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(
        path: string,
        body: B,
        queryParams?: HttpParameter,
        headers?: AxiosRequestHeaders,
        cancelToken?: CancelToken,
    ) {
        return this.request<B, D, R>({
            url: path,
            params: queryParams,
            headers,
            method: 'POST',
            cancelToken,
            data: body,
            responseType: "blob"
        });
    }

    getBlob<
        B extends BodyData,
        D extends BodyData,
        R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(
        path: string,
        body: B,
        queryParams?: HttpParameter,
        headers?: AxiosRequestHeaders,
        cancelToken?: CancelToken,
    ) {
        return this.request<B, D, R>({
            url: path,
            params: queryParams,
            headers,
            method: 'GET',
            cancelToken,
            data: body,
            responseType: "blob"
        });
    }


    /**
     * Performs `POST` request with FormData as a body. Useful to upload file
     * @param path is the server resource path that will be used for the request
     * @param body is the request body with FormData type to be used when making the request
     * @param queryParams are the URL parameters / query string to be sent with the request. Must be a `plain object` or an `URLSearchParams` object
     * @param headers are custom headers to be sent
     * @param cancelToken is Cancellation token to cancelling the request
     * @returns Axios response wrapped by Promise
     */
    postFormData<
        D extends BodyData,
        R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(
        path: string,
        body: FormData,
        queryParams?: HttpParameter,
        headers?: AxiosRequestHeaders,
        cancelToken?: CancelToken,
    ) {
        headers = {
            ...headers,
            'Content-Type': 'multipart/form-data',
        };
        return this.request<FormData, D, R>({
            url: path,
            params: queryParams,
            headers,
            method: 'POST',
            cancelToken,
            data: body,
        });
    }

    /**
     * Performs `PUT` request
     * @param path is the server resource path that will be used for the request
     * @param body is the request method to be used when making the request
     * @param queryParams are the URL parameters / query string to be sent with the request. Must be a `plain object` or an `URLSearchParams` object
     * @param headers are custom headers to be sent
     * @param cancelToken is Cancellation token to cancelling the request
     * @returns Axios response wrapped by Promise
     */
    put<
        B extends BodyData,
        D extends BodyData,
        R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>,
    >(
        path: string,
        body: B,
        queryParams?: HttpParameter,
        headers?: AxiosRequestHeaders,
        cancelToken?: CancelToken,
    ) {
        return this.request<B, D, R>({
            url: path,
            params: queryParams,
            headers,
            method: 'PUT',
            cancelToken,
            data: body,
        });
    }

    /**
     * Performs `DELETE` request
     * @param path is the server resource path that will be used for the request
     * @param queryParams are the URL parameters / query string to be sent with the request. Must be a `plain object` or an `URLSearchParams` object
     * @param headers are custom headers to be sent
     * @param cancelToken is cancellation token to cancelling the request
     * @returns Axios response wrapped by Promise
     */
    delete<D extends BodyData, R extends BaseInventoryApiResponse<D> = BaseInventoryApiResponse<D>>(
        path: string,
        queryParams?: HttpParameter,
        headers?: AxiosRequestHeaders,
        cancelToken?: CancelToken,
    ) {
        return this.request<object, D, R>({
            url: path,
            params: queryParams,
            headers,
            method: 'DELETE',
            cancelToken,
        });
    }

    /**
     * Performs a custom Axios request with full configuration
     * @param config An AxiosRequestConfig
     * @returns Axios response wrapped by Promise
     */

    async request<
        TRequestBody extends BodyData,
        TResponseBody extends BodyData,
        TRootResponse extends BaseInventoryApiResponse<TResponseBody> = BaseInventoryApiResponse<TResponseBody>,
    >(
        config: AxiosRequestConfig<TRequestBody>,
    ): Promise<HttpServiceResponse<TResponseBody>> {
        const enhancedConfigs: AxiosRequestConfig<TRequestBody> = {
            ...config,
            headers: {
                ...config.headers,
                'accept-language': 'vi-VN',
                Authorization: (() => {
                    const token = HttpClient.getToken();
                    return token ? `Bearer ${token}` : null
                })(),
            },
        };

        try {
            const response = await this.xhr.request<
                TRootResponse,
                AxiosResponse<TRootResponse, TRequestBody>,
                TRequestBody
            >(enhancedConfigs);

            return {
                data: response?.data?.data,
                success: true,
                fullResponse: response.data,
                errors: null,
                statusCode: response.status,
            };
        } catch (error) {
            return {
                data: null,
                fullResponse: null,
                success: false,
                errors: {
                    data: error?.response?.data,
                    message: error?.response?.data?.message,
                    statusCode: error?.response?.data?.statusCode,
                },
                statusCode: 0,
            };
        }
    }
}
const httpClient = new HttpClient(process.env.REACT_APP_BASE_API_URL);
export default httpClient;
