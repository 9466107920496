import { useSetCrudActionState } from "recoil/hooks/useCrudActionState";
import { CrudState } from "common/types";
import adjDetailService from "services/adjReceiptDetail";
import { useSetAdjReceiptDetail } from "recoil/hooks/useAdjReceiptDetailEdit";
import { useSetUpdateResult } from "recoil/hooks/useUpdateResponseData";
import { UpdateAdjReceiptDetail, ProductAdjReceiptImport, ImportAdjReceiptParamsRequest } from "common/entities";
import { useSetExportBlobData } from "recoil/hooks/useExportBlob";
import { useSetAdjReceiptImport } from "recoil/hooks/useAdjReceiptImport";

export function useAdjReceiptDetailAction() {
  const setDetailData = useSetAdjReceiptDetail();
  const setCrudState = useSetCrudActionState();
  const setUpdateResultData = useSetUpdateResult();
  const setBlobData = useSetExportBlobData();
  const setAdjReceiptImport = useSetAdjReceiptImport();

  const detailAdjReceipt = async function (id: string) {
    const { data, statusCode } = await adjDetailService.detailAdjReceipt(id);
    if (statusCode != 200) {
      setDetailData(null);
      setCrudState(CrudState.ServerError);
    } else if (data) {
      setDetailData(data);
    } else {
      setDetailData(null);
      setCrudState(CrudState.NotFound);
    }
  };

  const updateAdjReceipt = async function (params: UpdateAdjReceiptDetail) {
    const { fullResponse, statusCode, errors } = await adjDetailService.updateAdjReceipt(params);
    if (statusCode != 200) {
      setUpdateResultData(errors);
    } else if (fullResponse) {
      let dataNew = { ...fullResponse,statusCode: `${fullResponse?.statusCode}`, date: new Date() };
      setUpdateResultData(dataNew);
    } else {
      setCrudState(CrudState.NotFound);
    }
  };

  const exportAdjReceiptDetail = async function (id: string) {
    const { fullResponse } = await adjDetailService.exportAdjReceiptDetail(id);
    if (fullResponse) {
      setBlobData(fullResponse);
    } else {
      setBlobData(null);
      setCrudState(CrudState.NotFound);
    }
  };

  const importAdjReceiptDetail = async function (params: ImportAdjReceiptParamsRequest, fileImport: File) {
    const param = {
      ...params
    };
    const { statusCode, data } = await adjDetailService.importAdjReceipt(param, fileImport);
    if (statusCode === 200) {
      setAdjReceiptImport(data);
    } else if (statusCode === 400) {
      setAdjReceiptImport(null);
      setCrudState(CrudState.Failed);
    } else {
      setAdjReceiptImport(null);
      setCrudState(CrudState.ServerError);
    }
  };

  const saveImportAdjReceipt = async function (id: string, detail: ProductAdjReceiptImport[]) {
    const params = {
      adjReceiptId: id,
      details: detail,
    };
    const { statusCode } = await adjDetailService.saveImportAdjReceiptDetail(params);
    if (statusCode === 200) {
      setCrudState(CrudState.Updated);
    } else if (statusCode === 400) {
      setCrudState(CrudState.Failed);
    } else {
      setCrudState(CrudState.ServerError);
    }
  };

  const updateStatusAdjReceipt = async function (id: string, status: number) {
    const params = {
      adjReceiptId: id,
      status,
      AdjReceiptType: 1,
    };
    const { statusCode } = await adjDetailService.updateAdjReceiptStatus(params);
    if (statusCode === 200) {
      setCrudState(CrudState.Updated);
    } else if (statusCode === 400) {
      setCrudState(CrudState.Failed);
    } else {
      setCrudState(CrudState.ServerError);
    }
  };

  return {
    detailAdjReceipt,
    updateAdjReceipt,
    exportAdjReceiptDetail,
    importAdjReceiptDetail,
    saveImportAdjReceipt,
    updateStatusAdjReceipt,
  };
}
