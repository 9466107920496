import { SubmitButton } from 'formik-antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWithIconProps } from './ButtonWithIconProps';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const SaveButton: React.FC<ButtonWithIconProps> = ({ showIcon=true, ...rest }) => {
    const { t } = useTranslation();
    /*style={{ background: "#FF6816", width: "100%" }}*/
    return (
        <SubmitButton {...rest} icon={showIcon ? <SearchOutlined /> : null} className="btn-search-custom" >
      {t('RESOURCES.COMMON.ACTIONS.SEARCH')}
    </SubmitButton>
  );
};

SaveButton.propTypes = {
  showIcon: PropTypes.bool
};

export default SaveButton;
