import { profileAtom } from 'recoil/atoms/auth/profile';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { AuthenticatedUser } from 'common/entities/users/AuthenticatedUser';

export function useGetProfile() {
  return useRecoilValue(profileAtom);
}

export function useSetProfile() {
  const resetter = useResetRecoilState(profileAtom);
  const setter = useSetRecoilState(profileAtom);

  return (value: AuthenticatedUser | ((prev: AuthenticatedUser) => AuthenticatedUser)) => {
    resetter();
    setter(value);
  };
}

export function useResetProfile() {
  return useResetRecoilState(profileAtom);
}