import BaseService from 'services/base-service';
import { AuthenticateProvider } from 'common/types';
import ApiResources from 'common/config/request';
import { AuthenticatedUser } from 'common/entities/users/AuthenticatedUser';
import {
    LoginRequestDto,
    LoginResponseDto,
    ProfileRequestDto,
} from 'common/entities';
import { AUTH_STORAGE_KEY } from 'common/config';
import { encrypt } from 'common/utils';
import { HttpClientTNI, httpClientTNI } from 'common/http';
import { decrypt } from 'common/utils';
import useGetListArea from 'recoil/hooks/ListArea';
import {
    GetGroupAreaRequest,
    getGroupAreaResponse,
} from 'common/entities/groupArea';
import { getRoles } from 'components/auth/AuthorWrapper';
import { includes } from 'lodash';
import { AUTHORIZE_ROLES } from 'common/config/roles';
import { getListRegionalCodesResponse } from 'common/entities/regional';
import { constSelector } from 'recoil';

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

export class TniAuthService {
    private readonly httpService: HttpClientTNI;

    constructor() {
        this.httpService = httpClientTNI;
    }

    async authenticate(username: string, password: string): Promise<boolean> {
        const apiURL = ApiResources.auth.login;
        const request: LoginRequestDto = {
            username,
            password,
            platform: 'web-dashboard',
            rememberClient: true,
        };
        const { data, success, errors } = await this.httpService.post<
            LoginRequestDto,
            LoginResponseDto
        >(apiURL, request);

        if (success) {
            const { accessToken, roles, storeIds } = data;
            localStorage.setItem(AUTH_STORAGE_KEY, encrypt(accessToken));
            localStorage.setItem('role', encrypt(JSON.stringify(roles)));
            localStorage.setItem('Frole', JSON.stringify(roles));
            if (storeIds[0]) {
                localStorage.setItem('storeIds', JSON.stringify(storeIds[0]));
            }
            if (storeIds) {
                localStorage.setItem('storeIdsArray', JSON.stringify(storeIds));
            }

            //const userRoles = getRoles();
            // if (
            //     includes(userRoles, AUTHORIZE_ROLES.RSM) ||
            //     includes(userRoles, AUTHORIZE_ROLES.ASM)
            // ) {
            const dataRSM = await this.getDataRSM();
            const regionalCode = dataRSM[0]?.value;
            const data_ = await this.getDataASM(regionalCode);
            localStorage.setItem(
                'REGIONAL',
                JSON.stringify(data_?.map((x) => x.value)),
            );
            // }
            //return;
            if (
                includes(getRoles(), AUTHORIZE_ROLES.RSM) &&
                includes(getRoles(), AUTHORIZE_ROLES.ASM)
            ) {
                localStorage.setItem('REGIONAL', JSON.stringify([]));
            }
            //if(roles?.any?.values)
            return true;
        } else {
            return false;
            return Promise.reject(errors);
        }
    }

    changePassword(
        currentPassword: string,
        newPassword: string,
    ): Promise<boolean> {
        return Promise.resolve(true);
    }

    async getProfile(): Promise<AuthenticatedUser> {
        var token = decrypt(localStorage.getItem(AUTH_STORAGE_KEY));
        const uid = parseJwt(token)?.sub;

        const apiURL = ApiResources.auth.getProfile;
        const request: ProfileRequestDto = {
            userId: uid as number,
            mode: 'brand',
        };
        const { data, success, errors } =
            await this.httpService.get<AuthenticatedUser>(apiURL, request);
        if (success) {
            return Promise.resolve(data);
        } else {
            return Promise.reject(errors);
        }
    }
    async getDataASM(regionalCode: string): Promise<getGroupAreaResponse[]> {
        var token = decrypt(localStorage.getItem(AUTH_STORAGE_KEY));
        const uid = parseJwt(token)?.sub;

        //khu vực
        //đã filter dưới BE theo userId nên chỉ cần này là đủ
        const apiURL = ApiResources.regional.getDataListAreaCodes;
        const request = {
            userId: uid as number,
            regionalCode: regionalCode,
        };
        const { data, success, errors } = await this.httpService.get<
            getGroupAreaResponse[]
        >(apiURL, request);
        if (success) {
            return data;
        } else {
            return Promise.reject(errors);
        }
    }

    async getDataRSM(): Promise<getListRegionalCodesResponse[]> {
        var token = decrypt(localStorage.getItem(AUTH_STORAGE_KEY));
        const uid = parseJwt(token)?.sub;

        const apiURL = ApiResources.regional.getDataRegional;
        const request: ProfileRequestDto = {
            userId: uid as number,
            //brandCode: 'GT40',
        };
        const { data, success, errors } = await this.httpService.get<
            getListRegionalCodesResponse[]
        >(apiURL, request);
        if (success) {
            return Promise.resolve(data);
        } else {
            return Promise.reject(errors);
        }
    }

    getToken(): Promise<string> | string {
        return Promise.resolve(HttpClientTNI.getToken());
    }

    logout(): Promise<void> {
        return Promise.resolve(undefined);
    }

    saveProfile(authProfile: AuthenticatedUser): Promise<boolean> {
        return Promise.resolve(false);
    }
}

const tniAuthService = new TniAuthService();

export default tniAuthService;
