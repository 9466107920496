import { ColumnsType } from "antd/lib/table";
import { TFunction } from "react-i18next";
import { formatNumber } from "common/utils/strings";
import Title from "antd/lib/typography/Title";
import { ProductAdjReceiptImport } from "common/entities";

export function getTableAdjReceiptImportColumns(
  t: TFunction
): ColumnsType<ProductAdjReceiptImport> {
  return [
    {
      key: "1",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.STT"),
      align: "center",
      width: "5%",
      render: (text, record, index) => formatNumber(index + 1),
    },
    {
      key: "2",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.NUMBER_RECEIPT"),
      align: "center",
      dataIndex: "code",
    },
    {
      key: "3",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.PRODUCT_CODE"),
      align: "center",
      dataIndex: "productId",
    },
    {
      key: "4",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.SKU"),
      align: "center",
      dataIndex: "sku",
    },
    {
      key: "5",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.PRODUCT"),
      dataIndex: "productName",
    },
    {
      key: "6",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.ACTUAL_INVENTORY"),
      align: "center",
      dataIndex: "qtyActual",
    },
    {
      key: "7",
      title: t("RESOURCES.ADJ_RECEIPT.COLUMNS.NOTI_ERROR"),
      width: "20%",
      render: (_, record) => {
        if (record?.isWarning) {
          return <Title level={5} type="warning">{record?.warningNote}</Title>
        }
        if (record?.isError) {
          return <Title level={5} type="danger">{record?.errorNote}</Title>
        }
      }
    },
  ];
}
