import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const ContainerLoader = styled.div`
  padding: 30px 50px;
  text-align: center;
  background: #fff;
  height: 100%;
  width: 100%;
`;

const SuspenseLoader: React.FC = () => {
  return (
    <ContainerLoader>
      <Spin/>
    </ContainerLoader>
  );
};

export default SuspenseLoader;
