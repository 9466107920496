import { LockOutlined, LogoutOutlined, UserOutlined, InfoOutlined } from '@ant-design/icons';
import { Menu, Typography } from 'antd';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthAction } from 'recoil/actions/auth';

const { Item, Divider } = Menu;

const RightMenu: React.FC = () => {

  const { logout } = useAuthAction();
  const { t } = useTranslation();

  const styleMenu = {
    boxShadow:"rgb(136 136 136 / 50%) 1px 1px 5px 2px", 
    borderRadius: '4px'
  };
  
  return (
    <Menu theme='light' style={styleMenu}>
      {/*<Item key={nanoid()} icon={<UserOutlined />}>
        <RouterLink to='/profile-information'>
          {t('RESOURCES.COMMON.PROFILE_MENU')}
        </RouterLink>
      </Item>
      <Divider />
      <Item key={nanoid()} icon={<LockOutlined />}>
        <RouterLink to='/change-password'>
          {t('RESOURCES.COMMON.CHANGE_PASSWORD_MENU')}
        </RouterLink>
      </Item>*/}
      <Divider />
      <Item key={nanoid()} icon={<LogoutOutlined />} onClick={() => logout()}>
        {t('RESOURCES.COMMON.LOGOUT_MENU')}
      </Item>
    </Menu>
  )
};

export default RightMenu;