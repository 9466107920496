import { TFunction } from 'react-i18next';
import dateUtils from './date';
import { Typography, Space } from 'antd';
import { Dayjs } from 'dayjs';
import inRange from 'lodash/inRange';
import * as HolidayJP from '@holiday-jp/holiday_jp';
import { FIRST_YEAR_RELEASE } from 'common/config/constants';

const { Text } = Typography;

type MomentOfDay = 'morning' | 'afternoon' | 'evening';

const momentGreetingMapping: { [key: string]: string } = {
  morning: 'RESOURCES.COMMON.GREETINGS.MORNING',
  afternoon: 'RESOURCES.COMMON.GREETINGS.AFTERNOON',
  evening: 'RESOURCES.COMMON.GREETINGS.EVENING',
};

function isMorning(currentDay: Dayjs) {
  const currentDate = currentDay.format('YYYY-MM-DD');
  return currentDay.isBetween(`${currentDate} 05:00:00`, `${currentDate} 11:59:59`, undefined, '[]');
}

function isAfternoon(currentDay: Dayjs) {
  const currentDate = currentDay.format('YYYY-MM-DD');
  return currentDay.isBetween(`${currentDate} 12:00:00`, `${currentDate} 16:59:59`, undefined, '[]');
}

function isEvening(currentDay: Dayjs) {
  const currentDate = currentDay.format('YYYY-MM-DD');
  const nextDate = currentDay.add(1, 'day').format('YYYY-MM-DD');
  return currentDay.isBetween(`${currentDate} 17:00:00`, `${nextDate} 04:59:59`, 'hour', '[]');
}

function getMomentOfDay(currentDay: Dayjs): MomentOfDay {
  if (isMorning(currentDay)) {
    return 'morning';
  }

  if (isAfternoon(currentDay)) {
    return 'afternoon';
  }

  if (isEvening(currentDay)) {
    return 'evening';
  }

  return 'morning';
}

function isDay(currentDay: Dayjs) {
  return inRange(currentDay.hour(), 5, 17);
}

/**
 * Print greeting welcome sentence
 * @param t Translation function
 * @returns 
 */
export function printGreeting(t: TFunction) {
  const currentDay = dateUtils.dayjs();
  const momentOfDay = t(momentGreetingMapping[getMomentOfDay(currentDay)]);
  const date = currentDay.format('LL');
  const dow = currentDay.format('dddd');
  let holidayName= HolidayJP.between(currentDay.startOf('date').toDate(), currentDay.endOf('date').toDate()).map((h) => h.name).join('、');
  holidayName = holidayName ? `${holidayName}. ` : '';
  const greeting = t('RESOURCES.COMMON.GREETINGS.WELCOME_GREETING', { date, dow, holidayName });

  let currentIcon = process.env.PUBLIC_URL + '/images/sunny.png';
  if (!isDay(currentDay)) {
    currentIcon = process.env.PUBLIC_URL + '/images/moon.png';
  }
  return (
    <Space>
      <img alt='moment-icon' width={32} src={currentIcon}/>
      <Space>
        <Text ellipsis strong type='danger'>{momentOfDay}</Text>
        <span dangerouslySetInnerHTML={{ __html: greeting }} />
      </Space>
    </Space>
  );
}

export function getCopyrightYear() {
  const currentYear = new Date().getFullYear();
  if (currentYear <= FIRST_YEAR_RELEASE) {
    return currentYear.toString();
  } else {
    return `${FIRST_YEAR_RELEASE} - ${currentYear}`;
  }
}