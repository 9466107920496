
import Menu from 'antd/lib/menu';
import { NavSiderMenuProps } from 'common/types';
import { getCurrentRouteSelectedForSidebar } from 'common/utils/navigation';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { getNavItems } from './helpers';

const NavSiderMenu: React.FC<NavSiderMenuProps> = () => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [lastPathname, setLastPathname] = useState<string>();

  useEffect(() => {
    if (location.pathname && location.pathname !== lastPathname) {
      const routeMatch = getCurrentRouteSelectedForSidebar(location.pathname);
      setLastPathname(location.pathname);
      if (routeMatch) {
        if (routeMatch.openKey) {
          setOpenKeys([routeMatch.openKey]);
        } else {
          setOpenKeys(undefined);
        }
        
        if (routeMatch.key) {
          setSelectedKeys([routeMatch.key]);
        } else {
          setSelectedKeys([]);
        }
        
      } else {
        setOpenKeys(undefined);
        setSelectedKeys([]);
      }
    }

  }, [location.pathname]);

    const renderMenuItem = useCallback(getNavItems, [navigate, t]);
  return (<Menu
    className='inner-sider-menu'
    theme={'light'}
    mode="inline"
    selectedKeys={selectedKeys}
    openKeys={openKeys}
    onOpenChange={(keys) => {
      setOpenKeys(keys);
    }}
    style={{ borderRight: 0 }}
  >
    {
      renderMenuItem(navigate.push, t)
    }
  </Menu>)
};

export default memo(NavSiderMenu);