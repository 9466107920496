import { ColumnsType } from "antd/lib/table";
import { categoryMaterialData } from "common/entities";
import { TFunction } from "react-i18next";
import { formatNumber } from "common/utils/strings";
import { Typography } from "antd";

const { Text } = Typography;

export function getTableImportCategoryMaterial(
  t: TFunction,
): ColumnsType<categoryMaterialData> {
  return [
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.STT"),
      align: "center",
      width: "5%",
      dataIndex: "stt",
      render: (text) => formatNumber(text)
    },
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.BRAND"),
      dataIndex: "brandCode",
      align: "center" as "center",
    },
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.CODE_STORE"),
      dataIndex: "storeNo",
      align: "center",
    },
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.NAME_STORE"),
      dataIndex: "storeName",
    },
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.CODE"),
      dataIndex: "code",
      align: "center",
    },
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.NAME"),
      dataIndex: "name",
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_LIST.COLUMNS.ERROR"),
      dataIndex: "errorNote",
      width: "20%",
      render: (text) => <Text type="danger">{text}</Text>,
    },
  ];
}
