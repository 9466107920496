import { UnitData } from "common/entities/unit";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { UnitDetailAtom } from "recoil/atoms/unit/UnitDetailAtom";

export function useGetDetailList() {
  return useRecoilValue(UnitDetailAtom);
}

export function useSetUnitDetail() {
  const reset = useResetRecoilState(UnitDetailAtom);
  const set = useSetRecoilState(UnitDetailAtom);
  return (value: UnitData | ((pre: UnitData) => UnitData)) => {
    reset();
    set(value);
  };
}

export function useResetUnitDetail() {
  return useResetRecoilState(UnitDetailAtom);
}
