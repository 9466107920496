import { CheckCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import {
    AdjReceiptStatus,
    PurchaseOrderStatus,
    PurchaseStatus,
    StatusUnit,
} from 'common/config';
import { useTranslation } from 'react-i18next';

export const tagStatus = (text: number) => {
    const { t } = useTranslation();
    switch (text) {
        case AdjReceiptStatus.New:
            return (
                <Tag color="orange">
                    {t('RESOURCES.ADJ_RECEIPT.STATUS.NEW')}
                </Tag>
            );
        case AdjReceiptStatus.Done:
            return (
                <Tag color="green">
                    {t('RESOURCES.ADJ_RECEIPT.STATUS.DONE')}
                </Tag>
            );
        case AdjReceiptStatus.Cancel:
            return (
                <Tag color="red">
                    {t('RESOURCES.ADJ_RECEIPT.STATUS.CANCEL')}
                </Tag>
            );
        default:
            break;
    }
};
export const tagActive = (text: number) => {
    const { t } = useTranslation();
    switch (text) {
        case StatusUnit.Use:
            return (
                <Tag color="green">
                    {t('RESOURCES.ESTABLISH.UNIT.STATUS.USE')}
                </Tag>
            );
        case StatusUnit.NotUse:
            return (
                <Tag color="red">
                    {t('RESOURCES.ESTABLISH.UNIT.STATUS.NOT_USE')}
                </Tag>
            );
        default:
            break;
    }
};
export const tagActiveProduct = (value: boolean) => {
    const { t } = useTranslation();
    if (value) {
        return <Tag color="green">{t('RESOURCES.PRODUCTS.STATUS.ACTIVE')}</Tag>;
    } else {
        return (
            <Tag color="orange">
                {t('RESOURCES.PRODUCTS.STATUS.NOT_ACTIVE')}
            </Tag>
        );
    }
};

export const iconActive = (value: boolean) => {
    if (value) {
        return (
            <CheckCircleFilled
                style={{ color: 'rgb(37 174 136)', fontSize: 20 }}
            />
        );
    } else {
        return (
            <MinusCircleFilled
                style={{ color: 'rgb(247 118 102)', fontSize: 20 }}
            />
        );
    }
};

export const tagPurchaseStatus = (text: number) => {
    const { t } = useTranslation();
    switch (text) {
        case PurchaseStatus.New:
            return (
                <Tag color="orange">
                    {t('RESOURCES.BOM.PURCHASEREQUEST.STATUS.NEW')}
                </Tag>
            );
        case PurchaseStatus.Done:
            return (
                <Tag color="green">
                    {t('RESOURCES.BOM.PURCHASEREQUEST.STATUS.DONE')}
                </Tag>
            );
        case PurchaseStatus.Cancel:
            return (
                <Tag color="red">
                    {t('RESOURCES.BOM.PURCHASEREQUEST.STATUS.CANCEL')}
                </Tag>
            );
        default:
            break;
    }
};

export const tagPurchaseOrderStatus = (text: number) => {
    const { t } = useTranslation();
    switch (text) {
        case PurchaseOrderStatus.New:
            return (
                <Tag color="orange">
                    {t('RESOURCES.BOM.PURCHASE_ORDER.STATUS.NEW')}
                </Tag>
            );
        case PurchaseOrderStatus.Doing:
            return (
                <Tag color="yellow">
                    {t('RESOURCES.BOM.PURCHASE_ORDER.STATUS.DOING')}
                </Tag>
            );
        case PurchaseOrderStatus.Done:
            return (
                <Tag color="green">
                    {t('RESOURCES.BOM.PURCHASE_ORDER.STATUS.DONE')}
                </Tag>
            );
        default:
            break;
    }
};
