import { ReportPoResponse } from "common/entities/report/reportPo";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { reportPoAtom } from "recoil/atoms/reportPo";

export const useGetReportPoState = () => {
  return useRecoilValue(reportPoAtom);
};

export const useSetReportPoState = () => {
  const reset = useResetRecoilState(reportPoAtom);
  const set = useSetRecoilState(reportPoAtom);
  return (value: ReportPoResponse | ((prev: ReportPoResponse) => ReportPoResponse)) => {
    reset();
    set(value);
  };
};

export const useResetReportPoState = () => {
    return useResetRecoilState(reportPoAtom);
};
