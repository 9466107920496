import { ResponseCreateEntity } from 'common/entities/response/responseCreate';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import { CreateResultDataAtom } from 'recoil/atoms/create/CreateResult';


export function useGetCreateResult() {
    return useRecoilValue(CreateResultDataAtom);
}

export function useSetCreateResult() {
    const reset = useResetRecoilState(CreateResultDataAtom);
    const set = useSetRecoilState(CreateResultDataAtom);

    return (value: ResponseCreateEntity | ((prev: ResponseCreateEntity) => ResponseCreateEntity)) => {
    reset();
    set(value);
  };
}

export function useResetCreateResult() {
    return useResetRecoilState(CreateResultDataAtom);
}
