export const AUTHORIZE_ROLES = {
    InventoryMenu: 'Inventory.InventoryMenu.View',
    BomMenu: 'Inventory.BomMenu.View',
    BasicMenu: 'BasicMenu',
    ASM: 'Pages.Dashboard.Asm.ViewAll',
    RSM: 'Pages.Dashboard.Rsm.ViewAll',
    ImportReport: 'Inventory.Reports.ImportReport',
    ExportReport: 'Inventory.Reports.ExportReport',
    InventoryReport: 'Inventory.Reports.InventoryReport',
    ExpectedInventory: 'Inventory.InventoryMenu.ExpectedInventory',
    MinimumInventory: 'Inventory.InventoryMenu.MinimumInventory',
};
