import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonWithIconProps } from "./ButtonWithIconProps";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const AddButton: React.FC<ButtonWithIconProps> = ({ showIcon, content, ...rest }) => {
  const { t } = useTranslation();
    return (
        <Button {...rest} type="primary" icon={showIcon ? <PlusOutlined /> : null} className="btn-add-custom">
      {content ? content : t("RESOURCES.COMMON.ACTIONS.ADD")}
    </Button>
  );
};

AddButton.propTypes = {
  showIcon: PropTypes.bool,
};

export default AddButton;
