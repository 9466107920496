import * as React from 'react';
import { Alert, Result } from 'antd';

interface ErrorBoundaryProps {
  message?: React.ReactNode;
  description?: React.ReactNode;
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  {
    error?: Error | null;
    info: {
      componentStack?: string;
    };
  }
> {
  state = {
    error: undefined,
    info: {
      componentStack: '',
    },
  };

  componentDidCatch(error: Error | null, info: object) {
    if (process.env.NODE_ENV === 'production') {
      location.reload();
    } else {
      this.setState({ error, info });
    }
  }

  render() {
    const { message, description, children } = this.props;
    const { error, info } = this.state;
    const componentStack = info && info.componentStack ? info.componentStack : null;
    const errorMessage = typeof message === 'undefined' ? (error || '').toString() : message;
    const errorDescription = typeof description === 'undefined' ? componentStack : description;
    if (error) {
      if (process.env.NODE_ENV === 'production') {
        return (
          <Result status='500' />
        )
      } else {
        return (
          <Alert type="error" message={errorMessage} description={<pre>{errorDescription}</pre>} />
        );
      }
    }
    return children;
  }
}
