import { TFunction } from "react-i18next";
import { formatNumber } from "common/utils/strings";

export function getTableRecipeModal(
  t: TFunction,
) {
  return [
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.STT"),
      align: "center" as "center",
      dataIndex: "stt",
      render: (text) => formatNumber(text),
      hidden: false,
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.SKU"),
      dataIndex: "materialSku",
      hidden: false,
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.CODE_MATERIAL"),
      dataIndex: "materialCode",  
      hidden: false,
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.NAME_MATERIAL"),
      dataIndex: "materialName",
      hidden: false,
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.TYPE_BOM"),
      dataIndex: "materialType",
      align: "center" as "center",
      hidden: true,
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.QUANTITATIVE"),
      align: "center" as "center",
      dataIndex: "quantity",
      hidden: false,
    },
    {
      title: t("RESOURCES.BOM.MATERIAL_QUANTIFICATION.RECIPE.COLUMNS.UNIT"),
      align: "center" as "center",
      dataIndex: "unitName",
      hidden: false,
    },
  ];
}
