import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { FileImageOutlined } from '@ant-design/icons';
import { ButtonWithIconProps } from './ButtonWithIconProps';
import PropTypes from 'prop-types';

const ChooseImageButton: React.FC<ButtonWithIconProps> = ({ showIcon, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button type='primary' {...rest} icon={showIcon ? <FileImageOutlined /> : null} >
      {t('RESOURCES.COMMON.ACTIONS.CHOOSE_IMAGE')}
    </Button>
  );
};

ChooseImageButton.propTypes = {
  showIcon: PropTypes.bool
};

export default ChooseImageButton;