import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { districtAtom } from "recoil/atoms/location/DistrictAtom";
import { DistrictDto } from "common/entities/location";

export function useGetDistrict() {
    return useRecoilValue(districtAtom);
}

export function useSetDistrict() {
    const reset = useResetRecoilState(districtAtom);
    const set = useSetRecoilState(districtAtom);
    return (value: DistrictDto[] | ((currVal: DistrictDto[]) => DistrictDto[])) => {
        reset();
        set(value);
    }
}

export function useResetDistrict() {
    return useResetRecoilState(districtAtom);
}
