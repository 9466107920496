import { Menu } from "antd";
import { getAvailableSectionPages } from "common/utils/navigation";
import { TFunction } from "react-i18next";
import styled from "styled-components";
import { getRoles } from 'components/auth/AuthorWrapper';
import { isUndefined, isEmpty, includes } from 'lodash';

const DisabledMenuItem = styled(Menu.Item)`
  cursor: default;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

const { SubMenu } = Menu;

export function getNavItems(navigate: (path: string) => void, t: TFunction<'translation', undefined>) {
    return getAvailableSectionPages().filter(x => x.showOnMenu && !x.redirectOnly).map((item) => {
        const rolesData = getRoles();
        const check = item.rolesName.filter(z => includes(rolesData, z));
        if (item && check.length > 0) {
            const itemIcon = item.icon ?? undefined;
            if (item.group) {
                return (
                    <DisabledMenuItem className='menu-group-section' icon={itemIcon} key={item.key} disabled>
                        {item.autoTranslate ? t(item.title || '') : item.title || ''}
                    </DisabledMenuItem>
                );
            } else {
                let check = item?.items?.filter(z => z.rolesName.filter(y => includes(rolesData, y)));
                if (item.items && check.length > 0) {
                    return (
                        <SubMenu icon={itemIcon} key={item.key} title={item.autoTranslate ? t(item.title || '') : item.title || ''}>
                            {
                                item.items.filter(x => x.showOnMenu).map((subItem) => {
                                    const subItemIcon = subItem.icon ?? <></>;
                                    let check = subItem?.rolesName?.filter(ss => includes(rolesData, ss));
                                    if (check.length > 0) {
                                        if (subItem.link) {
                                            return (
                                                <Menu.Item key={subItem.key} icon={subItemIcon} onClick={() => navigate(subItem.url)}>
                                                    {subItem.autoTranslate ? t(subItem.title || '') : subItem.title || ''}
                                                </Menu.Item>
                                            );
                                        } else {
                                            return (
                                                <Menu.Item key={subItem.key} icon={subItemIcon}>
                                                    {subItem.autoTranslate ? t(subItem.title || '') : subItem.title || ''}
                                                </Menu.Item>
                                            );
                                        }
                                    }
                                })
                            }
                        </SubMenu>
                    );
                } else {
                    if (item.link) {
                        return (
                            <Menu.Item icon={itemIcon} key={item.key} onClick={() => navigate(item.url)}>
                                {item.autoTranslate ? t(item.title || '') : item.title || ''}
                            </Menu.Item>
                        );
                    } else {
                        return (
                            <Menu.Item icon={itemIcon} key={item.key}>
                                {item.autoTranslate ? t(item.title || '') : item.title || ''}
                            </Menu.Item>
                        );
                    }
                }
            }
        } else {
            return undefined;
        }
    });
}