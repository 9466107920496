import { APP_NAME } from 'common/config';
import { createContext } from 'react';

export interface TitleReducer {

  title: string;

  fullTitle: string;

  setTitle: (title?: string | null) => void;
}

export const DEFAULT_TITLE_REDUCER_VALUE = {

  title: document.title,

  fullTitle: document.title,

  setTitle(title?: string) {
    this.title = title;
    document.title = `${title} - ${APP_NAME}`; 
  }
};

const TitleContext = createContext<TitleReducer>(DEFAULT_TITLE_REDUCER_VALUE);

export default TitleContext;