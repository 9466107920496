import { ReportInventoryResponse } from "common/entities/report/reportInventory";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { reportInventoryAtom } from "recoil/atoms/reportPi/ReportInventory";

export const useGetReportInventoryState = () => {
  return useRecoilValue(reportInventoryAtom);
};

export const useSetReportInventoryState = () => {
  const reset = useResetRecoilState(reportInventoryAtom);
  const set = useSetRecoilState(reportInventoryAtom);
  return (value: ReportInventoryResponse | ((prev: ReportInventoryResponse) => ReportInventoryResponse)) => {
    reset();
    set(value);
  };
};

export const useResetReportInventoryState = () => {
    return useResetRecoilState(reportInventoryAtom);
};
