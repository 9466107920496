import { GetProductResponse } from "common/entities";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { productListAtom } from "recoil/atoms/product/productList";

export function useGetProductList() {
  return useRecoilValue(productListAtom);
}

export function useSetProductList() {
  const reset = useResetRecoilState(productListAtom);
  const set = useSetRecoilState(productListAtom);
  return (value: GetProductResponse | ((prev: GetProductResponse) => GetProductResponse)) => {
    reset();
    set(value);
  };
}

export function useResetProductList() {
  return useResetRecoilState(productListAtom);
}
