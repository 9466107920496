import { isUndefined, isEmpty, includes } from 'lodash';
import React, { ReactNode } from 'react';
import { LoginResponseDto, RolesResponseDto } from 'common/entities';
import { decrypt } from 'common/utils';
import { Redirect } from 'react-router-dom';
import NotFoundPage from 'pages/404';
import { uniqBy } from 'lodash';
import { AUTHORIZE_ROLES } from '../../common/config/roles';

export const getAreaRegion = () => {
    var data = null;
    if (localStorage.getItem('REGIONAL')) {
        data = JSON.parse(localStorage.getItem('REGIONAL'));
    }
    return data;
};

export const getStoreIdArray = () => {
    var data = null;
    if (localStorage.getItem('storeIdsArray')) {
        data = JSON.parse(localStorage.getItem('storeIdsArray'));
    }
    return data;
};
export const getRoles = () => {
    var dataRoles: RolesResponseDto[] = [];
    try {
        dataRoles = JSON.parse(decrypt(localStorage.getItem('role')));
    } catch (e) {
        dataRoles = [];
    }
    const listRoles = [];
    dataRoles?.length > 0 &&
        dataRoles?.map((x) =>
            x?.value.map((y) => {
                if (
                    y?.name?.includes(AUTHORIZE_ROLES.InventoryMenu) ||
                    y?.name?.includes(AUTHORIZE_ROLES.BomMenu) ||
                    y?.name?.includes(AUTHORIZE_ROLES.ASM) ||
                    y?.name?.includes(AUTHORIZE_ROLES.RSM) ||
                    y?.name?.includes(AUTHORIZE_ROLES.ImportReport) ||
                    y?.name?.includes(AUTHORIZE_ROLES.ExportReport) ||
                    y?.name?.includes(AUTHORIZE_ROLES.InventoryReport) ||
                    y?.name?.includes(AUTHORIZE_ROLES.ExpectedInventory) ||
                    y?.name?.includes(AUTHORIZE_ROLES.MinimumInventory)
                ) {
                    listRoles.push(y.name);
                }
            }),
        );
    return listRoles;
};
const AuthorWrapper: React.FC<{ children: ReactNode; roles: string[] }> = ({
    roles,
    children,
}) => {
    var rolesData: string[] = [];
    rolesData = getRoles();
    const handleRender = () => {
        if (isUndefined(rolesData)) return <></>;
        else {
            if (!isEmpty(roles)) {
                for (let element of roles) {
                    if (includes(rolesData, element)) {
                        return <>{children}</>;
                    }
                }
            }
            return <></>;
        }
    };
    return handleRender();
};
export default AuthorWrapper;

export const checkRolePages = (roles: string[], children: ReactNode) => {
    const rolesData = getRoles();
    if (isUndefined(rolesData)) return <></>;
    else {
        if (!isEmpty(roles)) {
            for (let element of roles) {
                if (includes(rolesData, element)) {
                    return <>{children}</>;
                }
            }
        }
        return <NotFoundPage status="403" />;
    }
};
