import { SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonWithIconProps } from "./ButtonWithIconProps";
import { SubmitButton } from "formik-antd";

const SaveButton: React.FC<ButtonWithIconProps> = ({ showIcon, danger, content, ...rest }) => {
    const { t } = useTranslation();
    return (
        <SubmitButton {...rest} icon={showIcon ? <SaveOutlined /> : null} className="btn-search-custom">
            {content ? content : t("RESOURCES.COMMON.ACTIONS.SAVE")}
        </SubmitButton>
    );
};

SaveButton.propTypes = {
    showIcon: PropTypes.bool,
};

export default SaveButton;
