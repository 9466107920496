import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartComponentLike,
  ArcElement
} from 'chart.js';

const elementsToRegister: ChartComponentLike[] = [
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
]

export default {
  register() {
    ChartJS.register(
      ...elementsToRegister
    );
    return ChartJS;
  },

  unregister() {
    ChartJS.unregister(
      ...elementsToRegister
    );
    return ChartJS;
  }
}