import { ResponseDeleteEntity } from "common/entities/response/responseDelete";
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { DeleteResultDataAtom } from "recoil/atoms/delete/DeleteResult";

export function useGetDeleteResult() {
  return useRecoilValue(DeleteResultDataAtom);
}

export function useSetDeleteResult() {
  const reset = useResetRecoilState(DeleteResultDataAtom);
  const set = useSetRecoilState(DeleteResultDataAtom);

  return (value: ResponseDeleteEntity | ((prev: ResponseDeleteEntity) => ResponseDeleteEntity)) => {
    reset();
    set(value);
  };
}

export function useResetDeleteResult() {
  return useResetRecoilState(DeleteResultDataAtom);
}
