import { Button } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ConfirmButton = ({ ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button {...rest} type="primary" className="btn-confirm-custom" >
      {t("RESOURCES.COMMON.ACTIONS.CONFIRM")}
    </Button>
  );
};

ConfirmButton.propTypes = {
  showIcon: PropTypes.bool,
};

export default ConfirmButton;
