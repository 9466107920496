import { Layout } from 'antd';
import AppSider from 'components/sidebar';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { currentRouteAtom } from 'recoil/atoms/router';
import DashboardContainer from '../dashboard-container';
import { AppFooter } from '../footer';
import { AppHeader } from '../header';

const { Content } = Layout;

const StyledContent = styled(Content)`
  overflow: hidden auto;
  max-height: calc(100vh - 149px);
`;

export const BaseDashboardLayout: React.FC = ({ children }) => {

  const location = useLocation();
  const setCurrentRoute = useSetRecoilState(currentRouteAtom);

  useEffect(() => {

    if (location) {
      setCurrentRoute(location.pathname);
    }

  }, [location]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AppSider />
      <Layout>
        <AppHeader />
        <StyledContent>
          <DashboardContainer>
            {children}
          </DashboardContainer>
        </StyledContent>
        <AppFooter />
      </Layout>
    </Layout>
  );
};