import { LoadableClassComponent } from "@loadable/component";
import {
    AxiosInterceptorManager,
    AxiosRequestConfig,
    AxiosResponse,
} from "axios";
import React, { CSSProperties, ReactNode } from "react";
import { AuthenticatedUser } from "./entities/users/AuthenticatedUser";

export type PlainObject = {
    [key: string]:
    | string
    | string[]
    | number
    | number[]
    | boolean
    | boolean[]
    | PlainObject
    | PlainObject[]
    | null
    | undefined;
};

export type BodyData =
    | string
    | PlainObject
    | ArrayBuffer
    | ArrayBufferView
    | URLSearchParams
    | FormData
    | File
    | Blob;

export type HttpParameter = PlainObject | URLSearchParams;

export interface ObjectMap {
    [key: string]: any;
}

export interface DataResourceConfig {
    [key: string]: {
        [key: string]: string;
    };
}

/**
 * The Axios interceptors configuration
 */
export type Interceptors = {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse<any>>;
};

export interface Profile {
    displayName: string;
    photoURL?: string;
    photo?: File;
}

export interface NavSideItem {
    key?: string;

    /**
     * Gets or sets title of page
     */
    title?: string;

    /**
     * Make this item as group item in sidebar
     */
    group?: boolean;

    /**
     * Make this item as `<Redirect />`
     */
    redirectOnly?: boolean;

    /**
     * Icons of this item, show on sidebar
     */
    icon?: ReactNode;

    /**
     * Make item is linkable item (containing URL) to show on sidebar menu
     */
    link?: boolean;

    /**
     * URL to navigate to this page item
     */
    url?: string;

    /**
     * Redirect URL. Use it when `redirectOnly`  set to `true`
     */
    redirectTo?: string;

    /**
     * Auto translate for title text
     */
    autoTranslate?: boolean;

    /**
     * Component as lazy-loadable
     */
    lazyElement?: () => Promise<any>;

    /**
     * Sub items of this section
     */
    items?: NavSideSubItem[];

    /**
     * Show this page as menu item on sidebar
     */
    showOnMenu: boolean;

    /**
     * Make private page. Must be performs authorization before navigate to
     */
    private?: boolean;

    /**
     * Show this page on dashboard layout
     */
    showOnDashboard?: boolean;

    /**
     * Working enviroment for this page (from `process.env.NODE_ENV`)
     */
    workingEnvironments: WorkingEnvironment[];

    rolesName?: string[];
}

export interface NavSideSubItem {
    /**
     * Gets or sets key of item
     */
    key: string;

    /**
     *  Gets or sets title of page
     */
    title: string;

    /**
     * Icons of this item, show on sidebar
     */
    icon?: ReactNode;

    /**
     * Make item is linkable item (containing URL) to show on sidebar menu
     */
    link?: boolean;

    /**
     *  URL to navigate to this page item
     */
    url?: string;

    /**
     * Component as lazy-loadable
     */
    lazyElement?: () => Promise<any>;

    /**
     * Show this page as menu item on sidebar
     */
    showOnMenu: boolean;

    /**
     * Make this page is private page. Must be authorize before navigate
     */
    private: boolean;

    /**
     * Show this page on dashboard layout
     */
    showOnDashboard?: boolean;

    /**
     * Auto translate for title text
     */
    autoTranslate?: boolean;

    /**
     * Working enviroment for this page (take from `process.env.NODE_ENV`)
     */
    workingEnvironments: WorkingEnvironment[];

    /**
     * Open key to set sidebar open sub menu item programmatically
     */
    openKey?: string;

    rolesName?: string[];

}

export interface RouteSideBar {
    path: string;
    key: string;
    openKey?: string;
    rolesName?: string[];
}

export enum LayoutWidthType {
    Desktop,
    Mobile,
}

export interface EditorProps {
    name: string;
    values: string;
    setFieldValue: (name: string, value: string) => void;
}

export interface BaseAuthLayoutProps {
    fullWidth?: boolean;
}

export interface CardWithElevationProps {
    title?: ReactNode;
    style?: CSSProperties;
}

export interface PageContentBaseProps {
    title?: string;
    subtitle?: string;
    useBack?: boolean;
    backTo?: string;
    actions?: React.ReactNode[];
    aside?: React.ReactNode;
}

export interface ComponentNavigation {
    key: string;
    path?: string;
    component?: LoadableClassComponent<any>;
    dashboard?: boolean;
    private?: boolean;
    redirect?: boolean;
    to?: string;
    title?: string;
    autoTranslate?: boolean;
    rolesName?: string[];
}

export interface AvatarSelectProps {
    onChange?: (file?: File) => void;
    image?: string;
    error?: string;
}

export interface NavSiderMenuProps { }

export interface ProfileMenuProps { }

export interface ListNewUsersProps {
    users: NewUser[];
    loading: boolean;
}

export interface NewUserItemProps {
    item: NewUser;
}

export interface NewUser {
    id: string;
    name: string;
    email: string;
    avatarURL?: string;
}

export interface ModelUserCommonProps {
    onDone?: (selectedUsers: string[]) => void;
    onClose?: () => void;
    initSelected?: string[];
    show: boolean;
}

export interface CommonUserTableFilterProps {
    onSelectChanged?: (items: string[]) => void;
    initSelect?: string[];
}

export interface PaginationButtonProps {
    onNext: () => void;
    onPrev: () => void;
    showNext: boolean;
    showPrev: boolean;
}

export interface LoadMoreProps {
    initLoading?: boolean;
    loading: boolean;
    onLoadMore?: () => any;
}

export type WorkingEnvironment =
    | "development"
    | "staging"
    | "production"
    | "test";

export enum CrudState {
  NotSet, // not set, default
  Succeed, // Created/Get OK
  Deleted, // Deleted
  Updated, // Updated
  Failed, // Bad request
  ServerError, // internal server error
  NotFound, //Not found
  OK, //Found
  Forbidden,
  Exists,
  NotExists,
  synced,
}

export interface AuthenticateProvider {

    authenticate(username: string, password: string): Promise<boolean>;

    logout(): Promise<void>;

    getToken(): Promise<string> | string;

    getProfile(): Promise<AuthenticatedUser>;

    saveProfile(authProfile: AuthenticatedUser): Promise<boolean>;

    changePassword(currentPassword: string, newPassword: string): Promise<boolean>;
}
