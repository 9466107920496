export enum ActionStatus {
    Success,
    Failed,
    ServerError,
    NotSet,
}

export enum InventoryType {
    Real = 1,
    Virtual = 2,
    Gift = 3,
    NVL = 4,
}

export enum InventoryStatus {
    Use = 1,
    NotUse = 2,
}

export enum ReportStatus {
    New = 1,
    Done = 2,
    Cancel = 3,
}

export enum ReportType {
    Import = 1,
    Export = 2,
}

export enum ReportPoType {
    Export = 1,
}

export enum ReportInventoryColumn {
    start = 1,
    import = 2,
    export = 3,
    end = 4,
    edit = 5,
}

export enum ReportInventorySort {
    Decrement = 1,
    Increment = 0,
}

export enum AdjReceiptStatus {
    New = 1,
    Done = 2,
    Cancel = 3,
}

export enum PiStatus {
    New = 1,
    Done = 2,
    Cancel = 3,
}

export enum PoStatus {
    New = 1,
    Done = 2,
    Cancel = 3,
}

export enum PoTypeOption {
    Deliver = 5,
    Transfer = 6,
    Refund = 4,
}

export enum UnitStatus {
    Use = 1,
    NotUse = 2,
}

export enum StatusUnit {
    Use = 1,
    NotUse = 2,
}

export interface ActionResponse {
    message?: string;
    status: ActionStatus;
}

export enum PurchaseStatus {
    New = 1,
    Done = 2,
    Cancel = 3,
}

export enum FORM_ACTION {
    VIEW = 'view',
    EDIT = 'edit',
    CREATE = 'create',
}

export enum ADD_TYPE {
    INV = 'INV',
    BOM = 'BOM',
}

export enum PurchaseOrderStatus {
    New = 1,
    Doing = 2,
    Done = 3,
}
