import { CrudState } from 'common/types';
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import { crudActionStateAtom } from 'recoil/atoms/common/crudActionStateAtom';

/**
 * Get CRUD action state hook
 * @returns 
 */
export function useGetCrudActionState() {
  return useRecoilValue(crudActionStateAtom);
}

/**
 * Set or update CRUD state hook
 * @returns 
 */
export function useSetCrudActionState() {
  const setter = useSetRecoilState(crudActionStateAtom);
  const resetter = useResetRecoilState(crudActionStateAtom);
    return (crudState: CrudState | ((crudState: CrudState) => CrudState) ) => {
    resetter();
    setter(crudState);
  }
}

/**
 * CRUD action state hook
 * @returns 
 */
export function useCrudActionState(): [CrudState, (state: CrudState) => void] {
  const [crudState, setCrudState] = useRecoilState(crudActionStateAtom);
  const resetState = useResetRecoilState(crudActionStateAtom);

  const setter = (state: CrudState) => {
    resetState();
    setCrudState(state);
  };

  return [crudState, setter];
}

export function useResetCrudActionState() {
  return useResetRecoilState(crudActionStateAtom);
}