import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonWithIconProps } from "./ButtonWithIconProps";
import { SubmitButton } from "formik-antd";

const AgreeButton: React.FC<ButtonWithIconProps> = ({ danger, ...rest }) => {
  const { t } = useTranslation();
  return (
    <SubmitButton {...rest} className="btn-add-custom">
      {t("RESOURCES.COMMON.ACTIONS.AGREE")}
    </SubmitButton>
  );
};

AgreeButton.propTypes = {
  showIcon: PropTypes.bool,
};

export default AgreeButton;
