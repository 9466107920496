import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { authErrorAtom } from 'recoil/atoms/errors/authError';

export type AuthErrorState = () => [string, (error?: string) => void];

export const useAuthError: AuthErrorState = () => {
  const [error, setAuthError] = useRecoilState(authErrorAtom);

  return [error, setAuthError];
};

export const useGetAuthError = () => {
  const error = useRecoilValue(authErrorAtom);
  return error;
};

export const useResetAuthError = () => {
  return useResetRecoilState(authErrorAtom);
}