import { BaseDashboardLayout } from 'components/dashboard-based';
import { nanoid } from 'nanoid';
import NotFound from 'pages/404';
import React, { useCallback, useEffect, useState } from 'react';
import SuspenseLoader from 'components/suspense-loading';
import {
    BrowserRouter,
    Redirect,
    Route,
    Switch,
    RouteProps,
    useHistory,
    useLocation,
} from 'react-router-dom';
import ErrorBoundary from 'components/error-boundaries';
import { flattenNavURLs } from 'common/utils/navigation';
import { authService } from 'services/auth';
import { getRoles } from 'components/auth/AuthorWrapper';
import { isUndefined, isEmpty, includes } from 'lodash';
import { AUTHORIZE_ROLES } from './common/config/roles';

const AsyncRoute: React.FC<RouteProps> = (props) => {
    return <Route {...props}>{props.children}</Route>;
};

const AuthenticatedRoute: React.FC<RouteProps> = (props) => {
    const [isAuth, setIsAuth] = useState<boolean>(true);
    const navigation = useLocation();
    const checkAuth = useCallback(async () => {
        return authService.getToken();
    }, []);

    useEffect(() => {
        let isMounted = true;
        checkAuth()
            .then((token) => {
                if (isMounted) setIsAuth(!!token);
            })
            .catch((e) => {
                if (isMounted) setIsAuth(false);
            });

        return () => {
            isMounted = false;
        };
    }, [navigation.pathname]);

    // useEffect(() => {
    //   checkAuth()
    //     .then(x => setIsAuth(!!x));
    //   console.log('location', window.location.href);
    // }, [window.location.href]);

    if (!isAuth || !localStorage.getItem('role'))
        return (
            <Redirect
                from={props.path as string}
                to={{
                    pathname: '/login',
                    state: { redirectTo: props.path as string },
                }}
            />
        );
    return <AsyncRoute {...props} />;
};

const AppRouter: React.FC = () => {
    const rolesData = getRoles();
    let navURLs = [];
    let URLs = flattenNavURLs().filter((x) => {
        for (let element of x.rolesName) {
            if (includes(rolesData, element)) {
                navURLs.push(x);
            }
        }
    });
    if (URLs.length == 0) {
        flattenNavURLs().filter((x) => {
            if (includes(x.rolesName, AUTHORIZE_ROLES.BasicMenu)) {
                navURLs.push(x);
            }
        });
    }
    //console.log(navURLs);
    //console.log(rolesData);
    return (
        <BrowserRouter>
            <Switch>
                {navURLs.map((item) => {
                    //console.log(item,"item")
                    if (item.dashboard && item.private) {
                        const Component = item.component;
                        return (
                            <AuthenticatedRoute
                                exact
                                key={item.key}
                                path={item.path}
                                children={
                                    <BaseDashboardLayout>
                                        <React.Suspense
                                            fallback={<SuspenseLoader />}
                                        >
                                            <ErrorBoundary>
                                                <Component />
                                            </ErrorBoundary>
                                        </React.Suspense>
                                    </BaseDashboardLayout>
                                }
                            />
                        );
                    } else if (item.redirect && item.to) {
                        return <Redirect key={nanoid()} exact to={item.to} />;
                    } else {
                        const Component = item.component;
                        return (
                            <Route
                                key={item.key}
                                path={item.path}
                                exact
                                component={Component}
                            />
                        );
                    }
                })}
                <Route key={nanoid()} component={NotFound} />
            </Switch>
        </BrowserRouter>
    );
};

export default AppRouter;
