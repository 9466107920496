import { TFunction } from "i18next";
import { formatNumber } from "common/utils/strings";
import { Space } from "antd";
import { useConfirm } from "common/confirm";
import { DeleteButton } from "components/buttons";
import { PoStatus } from "common/config";

export function getTablePoDetail(t: TFunction, handleDeleteItem: (item: any) => void, statusPO = PoStatus.New) {
  const { confirmDelete } = useConfirm();
  return [
    {
      title: t("RESOURCES.INVENTORY.PO.COLUMNS.STT"),
      dataIndex: "index",
      editable: false,
      align: "center",
      render: (text: number) => formatNumber(text + 1),
    },
    {
      title: t("RESOURCES.INVENTORY.PO.COLUMNS.SKU"),
      dataIndex: "sku",
      editable: false,
    },
    {
      title: t("RESOURCES.INVENTORY.PO.COLUMNS.PRODUCT"),
      dataIndex: "productName",
      width: "auto",
      editable: false,
    },
    {
      title: t("RESOURCES.INVENTORY.PI.COLUMNS.UNIT"),
      dataIndex: "unitName",
      editable: false,
    },
    {
      title: t("RESOURCES.INVENTORY.PO.COLUMNS.NUMBER_ORDER_INVEN"),
      dataIndex: "qty",
      width: "15%",
      editable: true,
      render: (text: number) => formatNumber(text),
    },
    {
      title: t("RESOURCES.INVENTORY.PO.COLUMNS.NOTE"),
      dataIndex: "note",
      width: "15%",
      editable: true,
    },
    {
      title: t("RESOURCES.INVENTORY.PO.COLUMNS.MANIPULATION"),
      editable: false,
      render: (text, record) => (
        <Space>
          <DeleteButton
            disabled={statusPO === PoStatus.Done || statusPO === PoStatus.Cancel}
            onClick={() => confirmDelete(() => handleDeleteItem(record))}
          />
        </Space>
      ),
    },
  ];
}

export function getTableImportPO(t: TFunction) {
    return [
        {
            title: t('RESOURCES.PRODUCT_INVENTORY.COLUMNS.STT'),
            align: 'center',
            dataIndex: 'stt',
            width: '5%',
            render: (text: number) => formatNumber(text),
        },
        {
            title: t('RESOURCES.INVENTORY.PI.COLUMNS.PLACE_DELIVERY'),
            dataIndex: 'storeDeliverCode',
            align: 'center',
        },
        {
            title: t('RESOURCES.INVENTORY.PI.LABEL.INPUT_SEARCH_PLACE_RECEIVE'),
            dataIndex: 'storeReceiverCode',
            align: 'center',
        },
        {
            title: t('RESOURCES.INVENTORY.PI.COLUMNS.BRAND_CODE'),
            dataIndex: 'brandCode',
            align: 'center',
        },
        {
            title: t('RESOURCES.PRODUCT_INVENTORY.COLUMNS.SKU'),
            dataIndex: 'sku',
            align: 'center',
        },
        {
            title: t('RESOURCES.PRODUCT_INVENTORY.COLUMNS.PRODUCT'),
            dataIndex: 'productName',
        },
        {
            title: 'Số lượng',
            align: 'center',
            dataIndex: 'qty',
            render: (text: number) => formatNumber(text),
        },
        {
            title: t('RESOURCES.INVENTORY.PI.COLUMNS.BRAVO_CODE'),
            align: 'center',
            dataIndex: 'bravoCode',
        },
         {
            title: 'Loại xuất kho',
            align: 'center',
            dataIndex: 'poTypeName',
        },
        {
            title: t('RESOURCES.PRODUCT_INVENTORY.COLUMNS.NOTE'),
            dataIndex: 'invStoreName',
        },
        {
            title: t('RESOURCES.PRODUCT_INVENTORY.COLUMNS.ERROR'),
            dataIndex: 'errorNote',
        },
    ];
}
