import { FileSyncOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { ButtonWithIconProps } from "./ButtonWithIconProps";

const SyncButton: React.FC<ButtonWithIconProps> = ({ showIcon, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button type="primary" icon={showIcon ? <FileSyncOutlined /> : null} className="btn-sync-custom" {...rest}>
      {t("RESOURCES.COMMON.ACTIONS.SYNC")}
    </Button>
  );
};

export default SyncButton;
