import React, { FC } from "react";
import { Layout, Typography, Space } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getCopyrightYear } from "common/utils/greeting";

const { Footer } = Layout;
const { Text } = Typography;

const StyledFooter = styled(Footer)`
  text-align: center;
  background: #fff;
  padding: 10px 10px;
  border-top: solid 1px #e7e7e7;
`;

export const AppFooter: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <Space align="center" direction="horizontal">
        <Text>{t('RESOURCES.COMMON.FOOTER_COPYRIGHTS', { year: getCopyrightYear() })}</Text>
      </Space>
    </StyledFooter>
  );
};
