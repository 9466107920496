import { ColumnsType } from "antd/lib/table";
import { ProductInventoryDto } from "common/entities/productInventory";
import { TFunction } from "i18next";
import { formatNumber } from "common/utils/strings";
import { Space } from "antd";
import { Link } from "react-router-dom";
import dateTimeUtils from "common/utils/date";

export function getTableInventoryProductModal(
  t: TFunction,
  pageNumber?: number,
  pageSize?: number
): ColumnsType<ProductInventoryDto> {
  return [
    {
      title: t("RESOURCES.PRODUCT_INVENTORY.COLUMNS.STT"),
      render: (text, record, index) => {
        return formatNumber(index + 1 + pageSize * (pageNumber - 1));
      },
      align: "center",
      width: "5%",
    },
    {
      title: t("RESOURCES.PRODUCT_INVENTORY.COLUMNS.SKU"),
      dataIndex: "sku",
      align: "center",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Link to={"#"}>
              <span style={{ color: "blue" }}>{record?.sku}</span>
            </Link>
          </Space>
        );
      },
    },
    {
      title: t("RESOURCES.PRODUCT_INVENTORY.COLUMNS.PRODUCT"),
      dataIndex: "productName",
      align: "left",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Link to={"#"}>
              <span style={{ color: "blue" }}>{record?.productName}</span>
            </Link>
          </Space>
        );
      },
    },
    {
      title: t("RESOURCES.PRODUCT_INVENTORY.COLUMNS.UNIT"),
      dataIndex: "unitName",
      align: "left",
    },
    {
      title: t("RESOURCES.PRODUCT_INVENTORY.COLUMNS.AVAILABLE"),
      dataIndex: "qtyAvailable",
      align: "center",
      render: (text) =>formatNumber(text),
    },
    {
      title: t("RESOURCES.PRODUCT_INVENTORY.COLUMNS.DATE"),
      dataIndex: "date",
      align: "center",
      width: 200,
      render: (_, record) => dateTimeUtils.formatDate(record?.modifiedDate),
    },
    {
      title: t("RESOURCES.PRODUCT_INVENTORY.COLUMNS.STORE"),
      dataIndex: "store",
      align: "center",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Link to={"/inventory-management"}>
              <span style={{ color: "blue" }}>{record?.invStore?.name}</span>
            </Link>
          </Space>
        );
      },
    },
  ];
}
