import { decrypt, encrypt } from 'common/utils/crypto';

const securityStorage: Storage = {
  length: localStorage.length,

  clear: localStorage.clear,

  getItem(key: string) {
    const tmpData = localStorage.getItem(key);
    if (tmpData) {
      try {
        return decrypt(tmpData);
      } catch {
        return tmpData;
      }
    } else {
      return null;
    }
  },

  setItem(key: string, value: string) {
    if (value) {
      const cipher = encrypt(value);
      localStorage.setItem(key, cipher);
    }
  },

  key: localStorage.key,

  removeItem: localStorage.removeItem
};

Object.defineProperty(Window.prototype, 'localStorage', {
  value: securityStorage,
  configurable: true,
  writable: false
})

export default securityStorage;