export const DEFAULT_MAJOR_VERSION = 2;
export const FIRST_YEAR_RELEASE = 2022;
export const APP_NAME = 'TNI Inventory Dashboard';

export const SIDEBAR_TOGGLE_STATE = 'SIDEBAR_TOGGLED';
export const SIDEBAR_COLLAPSED_STATE = 'SIDEBAR_COLLAPSED';
export const CURRENT_ROUTE = 'CURRENT_ROUTE';
export const LOGIN_AUTH = 'LOGIN_AUTH';
export const SAVE_SUCCESS = 'SAVE_SUCCESS';
export const EMAIL_RESET_PASSWORD = 'EMAIL_RESET_PASSWORD';

export const APP_INSTANCE_NAME = "[DEFAULT_APP]";
export const IMG_EMPTY = `${process.env.PUBLIC_URL}/images/empty_avatar.png`;
export const MAX_FILE_SIZE_UPLOAD = 3145728;

export const IMAGE_TYPS_ALLOWED = /^image+\/[-\w.]+$/gim;

export const PASSWORD_STRENGTH_VALIDATE_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9_])/gi;

export const MIN_PASSWORD_LENGTH = 6;

export const SEARCH_DATE_FORMAT = 'DD/MM/YYYY';

export const DATEPICKER_DATE_FORMAT = 'DD-MM-YYYY HH:mm';

export const AUDIT_DATE_FORMAT = 'HH:mm:ss DD-MM-YYYY';

export const API_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const DEFAULT_MAX_FILE_SIZE_UPLOADED = 1048576;

export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_PAGE_SIZE_LIST = [10, 20, 50, 100];

export const MAX_USERS_LIST = 5;

export const DEFAULT_ASPECT_RATIO = 1080 / 810;

export const DEFAULT_PAGE_SIZE_INFINITY = 10000;

export const MAX_LENGTH_TAG_NAME = 12;

export const DEFAULT_DURATION = 3;

export const AUTH_STORAGE_KEY = 'TNI_INVENTORY_AUTH_SID';

export const IVN_STORE_CREATE = 'IVN_STORE_CREATE';

export const INV_STORE_DELETE = 'INV_STORE_DELETE';

export const STORE = 'STORE';

export const INV_STORE_EDIT = 'INV_STORE_EDIT';

export const INV_STORE_EXPORT = 'INV_STORE_EXPORT';

export const ADJ_RECEIPT_EXPORT = 'ADJ_RECEIPT_EXPORT';

export const ADJ_RECEIPT_CREATE = 'ADJ_RECEIPT_CREATE';

export const REPORT_PI = 'REPORT_PI';

export const REPORT_PO = 'REPORT_PO';

export const REPORT_INV = 'REPORT_INV';

export const REPORT_INVENTORY = 'REPORT_INVENTORY';

export const PRODUCT_LIST = 'PRODUCT_LIST';

export const UPDATE_PRODUCT_QTY_MIN = 'UPDATE_PRODUCT_QTY_MIN';

export const CATEGORY_PRODUCT = 'CATEGORY_PRODUCT';

export const BRAND_CODE_PRODUCT = 'BRAND_CODE_PRODUCT';

export const GROUP_AREA = 'GROUP_AREA';
