import React from 'react';
import { Button } from 'antd';
import { ButtonWithIconProps } from './ButtonWithIconProps';
import { ReloadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';

const RefreshButton: React.FC<ButtonWithIconProps> = ({ showIcon, danger, ...rest }) => {
  const { t } = useTranslation();
    return (
        <Button {...rest} icon={showIcon ? <ReloadOutlined /> : null} className="btn-refresh-custom">
      {t('RESOURCES.COMMON.ACTIONS.REFRESH')}
    </Button>
  );
};

export default RefreshButton;
