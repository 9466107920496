import { useSetRecoilState, useRecoilValue } from 'recoil';
import { tokenAtom } from 'recoil/atoms/auth/token';

export const useGetToken = () => {
  return useRecoilValue(tokenAtom);
};

export const useSetToken = () => {
  return useSetRecoilState(tokenAtom);
};

export const useResetAuthToken = () => {
  const setTokenValue = useSetRecoilState(tokenAtom);

  const clearToken = () => {
    setTokenValue(null);
  };

  return clearToken;
}