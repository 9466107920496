import { ColumnsType } from "antd/lib/table";
import { iconActive } from "common/dataGlobal/defaultTag";
import { categoryMaterialData } from "common/entities";
import { TFunction } from "react-i18next";
import { formatNumber } from "common/utils/strings";
import { DeleteButton } from "components/buttons";
import { useConfirm } from "common/confirm";
import { Space } from "antd";

export function getTableCategoryMaterial(
  t: TFunction,
  pageNumber: number,
  pageSize: number,
  handleDeleteItem: (values: number) => void,
  loadingDelete: boolean
): ColumnsType<categoryMaterialData> {
  const { confirmDelete } = useConfirm();
  return [
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.STT"),
      align: "center" as "center",
      width: "5%",
      render: (text, record, index) => formatNumber(index + 1 + pageSize * (pageNumber - 1)),
    },
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.BRAND"),
      dataIndex: "brandCode",
      align: "center" as "center",
    },
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.CODE_STORE"),
      dataIndex: "storeInfo",
      render: (text) => <span>{text?.storeNo}</span>,
    },
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.NAME_STORE"),
      dataIndex: "storeInfo",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.CODE"),
      dataIndex: "code",
    },
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.NAME"),
      dataIndex: "name",
    },
    {
      title: t("RESOURCES.BOM.CATEGORY_MATERIAL.COLUMNS.STATUS"),
      align: "center" as "center",
      dataIndex: "isActive",
      render: (text) => iconActive(text),
    },
    {
      title: "",
      align: "center" as "center",
      dataIndex: "id",
      render: (text, record) => (
        <Space>
          <DeleteButton
            disabled={!record.isActive}
            loading={loadingDelete}
            onClick={() => confirmDelete(() => handleDeleteItem(text))}
          />
        </Space>
      ),
    },
  ];
}
