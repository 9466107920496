import { AdjReceiptDetail } from "common/entities";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { AdjReceiptDetailEdit } from "recoil/atoms/adjReceipt/AdjReceiptDetailEdit";

export function useGetAdjReceiptDetail() {
    return useRecoilValue(AdjReceiptDetailEdit)
}

export function useSetAdjReceiptDetail() {
    const resetter = useResetRecoilState(AdjReceiptDetailEdit);
    const setter = useSetRecoilState(AdjReceiptDetailEdit);
    return function (value: AdjReceiptDetail | ((prev: AdjReceiptDetail) => AdjReceiptDetail)) {
        resetter();
        setter(value)
    }
}

export function useResetAdjReceiptDetail() {
    return useResetRecoilState(AdjReceiptDetailEdit);
}
