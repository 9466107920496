import { CloudUploadOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonWithIconProps } from "./ButtonWithIconProps";
import { SubmitButton } from "formik-antd";

const UploadButton: React.FC<ButtonWithIconProps> = ({ showIcon= false, danger, ...rest }) => {
  const { t } = useTranslation();
  
  return (
    <SubmitButton {...rest} icon={showIcon ? <CloudUploadOutlined /> : null} >
      {t("RESOURCES.COMMON.ACTIONS.UPLOAD") + " (2)"}
    </SubmitButton>
  );
};

UploadButton.propTypes = {
  showIcon: PropTypes.bool,
};

export default UploadButton;
