import { useTranslation } from 'react-i18next'
import { ButtonWithIconProps } from './ButtonWithIconProps'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'

const ExportButton: React.FC<ButtonWithIconProps> = ({ showIcon, ...rest }) => {
    const { t } = useTranslation()
    return (
        <Button
            type="primary"
            {...rest}
            icon={showIcon ? <FileExcelOutlined /> : null}
            className="btn-exp-imp-custom"
        >
            {t('RESOURCES.COMMON.ACTIONS.EXPORT')}
        </Button>
    )
}

ExportButton.propTypes = {
    showIcon: PropTypes.bool,
}

export default ExportButton
