import { useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import { ExportBlobDataAtom } from 'recoil/atoms/export/exportBlobResult';

export function useGetExportBlobData() {
    return useRecoilValue(ExportBlobDataAtom);
}

export function useSetExportBlobData() {
    const reset = useResetRecoilState(ExportBlobDataAtom);
    const set = useSetRecoilState(ExportBlobDataAtom);

    return (value: any | ((prev: any) => any)) => {
        reset();
        set(value);
    };
}

export function useResetExportBlobData() {
    return useResetRecoilState(ExportBlobDataAtom);
}