import { DashboardResponseDto } from 'common/entities/dashboard';
import {
  useRecoilValue, useResetRecoilState, useSetRecoilState
} from 'recoil';
import { dashboardStatsAtom } from 'recoil/atoms/dashboard/stat';

export const useGetDashboardStats = () => {
  return useRecoilValue(dashboardStatsAtom);
}

export function useSetDashboardStats() {
  const resetter = useResetRecoilState(dashboardStatsAtom);
  const setter = useSetRecoilState(dashboardStatsAtom);

  return (state: DashboardResponseDto | ((prevState: DashboardResponseDto) => DashboardResponseDto)) => {
    resetter();
    setter(state);
  }
}

export function useResetDashboardStats() {
  return useResetRecoilState(dashboardStatsAtom);
}
