import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './styles/main.less';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import chartJSIntegration from './chartjsRegister';
import 'common/storage';

console.log('Current working environment:', process.env.REACT_APP_ENV_STAGE);

ReactDOM.render(
  <App />,
  document.getElementById("root")
);

/**
 * Chart.js React integration
 * Call `register()` to register Chart.js elements to app, call `unregister()` to unregister and dispose Chart.js elements
 */
chartJSIntegration.register();

// LocalStorageChangeDetector.detectLocalStorageChange(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(process.env.NODE_ENV !== 'production' ? console.log : undefined);