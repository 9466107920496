import { Modal } from "antd";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;
export function useConfirm() {
  const {t} = useTranslation();

  const confirmDelete = (
    handleOk: () => void,
  ) => {
    confirm({
      title: t("RESOURCES.COMMON.TITLE_CONFIRM.DELETE"),
      okText: t("RESOURCES.COMMON.YES"),
      okType: 'danger',
      cancelText: t("RESOURCES.COMMON.NO"),
      onOk: handleOk,
    });
  };
  const confirmCancel = (
    handleOk: () => void,
  ) => {
    confirm({
      title: t("RESOURCES.COMMON.TITLE_CONFIRM.CANCEL"),
      okText: t("RESOURCES.COMMON.YES"),
      okType: 'danger',
      cancelText: t("RESOURCES.COMMON.NO"),
      onOk: handleOk,
    });
  };
  
  return {
    confirmCancel,
    confirmDelete,
  }
}
