import numeral from 'numeral';
import { TFunction } from 'react-i18next';
import { ListLocale } from 'antd/lib/list';
import { TableLocale } from 'antd/lib/table/interface';

export const ToUpperCase = String.prototype.toUpperCase;
export const ToLowerCase = String.prototype.toLowerCase;

export enum StringTransformationType {
    ToLower,
    ToUpper,
}

/**
 * Get first character of each word in a string
 * @param str String to process
 * @returns
 */
export function getFirstLetters(
    str: string,
    type: StringTransformationType = StringTransformationType.ToUpper,
): string {
    if (!str || str.length === 0) return 'NA';

    str = str.trim();

    const value = getMax2Characters(
        str
            .split(' ')
            .map((i) => i.charAt(0))
            .join(),
    );
    const formatFunc =
        type === StringTransformationType.ToLower ? ToLowerCase : ToUpperCase;

    return formatFunc.call(value);
}

/**
 * Get maximun 2 characters of string. Useful for display name in avatar
 * @param str Text
 * @returns
 */
function getMax2Characters(str: string) {
    if (str.length >= 2) {
        return str.substring(0, 1);
    } else {
        return str[0];
    }
}

/**
 * Format integer value
 * @param num Number to format
 * @returns
 */
export function formatNumber(num: number) {
    return numeral(num).format('0,0');
}
export const numberWithCommas = (number: any) => {
    if (!number) return '0';
    if (parseInt(number) === 0) return '0';
    //return new Intl.NumberFormat('en-US').format(number); if
    return numeral(number).format('0,000');
};

/**
 * Format float value
 * @param num Number to format
 * @returns
 */
export function formatDecimal(num: number) {
    return numeral(num).format('0.0%');
}

/**
 * Format numeral value for chart data
 * @param num Number to format
 * @returns
 */
export function formatForChart(num: number) {
    return numeral(num).format('0a');
}

/**
 * Convert text string to pascal case
 * @param text Text to convert
 * @returns
 */
export function toPascalCase(text: string) {
    return text
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(
            new RegExp(/\s+(.)(\w*)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + ' ' + $3.toLowerCase()}`,
        )
        .replace(new RegExp(/\w/), (s) => s.toUpperCase());
}

/**
 * Truncate text string
 * @param str Text to truncate
 * @param n Max length
 * @param useWordBoundary
 */
export function truncate(
    str: string,
    n: number,
    useWordBoundary: boolean = false,
) {
    if (!str) return '';
    if (str.length <= n) return str;
    const subString = str.substring(0, n - 1); // the original check
    return (
        (useWordBoundary
            ? subString.substring(0, subString.lastIndexOf(' '))
            : subString) + '...'
    );
}
