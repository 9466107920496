import { Button, Result } from 'antd';
import { ResultStatusType } from 'antd/lib/result';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const NotFoundPage: React.FC<{ status?: ResultStatusType }> = ({ status }) => {
    const navigate = useHistory();
    const { t } = useTranslation();
    const statusCode = status || '404';
    return (
        statusCode == '404' ?
            <Result
                status={statusCode}
                title={t('RESOURCES.COMMON.NOT_FOUND_TITLE')}
                subTitle={t('RESOURCES.COMMON.NOT_FOUND_DESCRIPTION')}
                extra={
                    <Button type="primary" onClick={() => navigate.replace('/dashboard')}>
                        {t('RESOURCES.COMMON.GO_HOME')}
                    </Button>
                }
            />
            : statusCode == '403' ? <Result
                status={statusCode}
                title={t('Không có quyền truy cập')}
                subTitle={t('RESOURCES.COMMON.NOT_FOUND_DESCRIPTION')}
                extra={
                    <Button type="primary" onClick={() => navigate.replace('/dashboard')}>
                        {t('RESOURCES.COMMON.GO_HOME')}
                    </Button>
                }
            />
                : <></>
    );
};

export default NotFoundPage;