import printValue from 'yup/lib/util/printValue';
import { LocaleObject } from 'yup/lib/locale';

// Based on https://github.com/jquense/yup/blob/2973d0a/src/locale.js
const mixed: LocaleObject['mixed'] = {
  default: 'Giá trị không hợp lệ',
  required: 'Vui lòng không bỏ trống trường này',
  oneOf: "${path} phải là một trong các giá trị sau: ${values}",
  notOneOf: "${path} không được là một trong các giá trị sau: ${values}",
  notType: ({ path, type, value, originalValue }) => {
    const isCast = originalValue != null && originalValue !== value;
    let msg =
      `${path} phải có kiểu dữ liệu \`${type}\`, ` +
      `nhưng giá trị của nó đang là: \`${printValue(value, true)}\`` +
      (isCast
        ? ` (giá trị ép kiểu sau đó đang là \`${printValue(originalValue, true)}\`).`
        : '.');

    if (value === null) {
      msg += `\n Nếu 'null' là giá trị trống mặc định, phải sử dụng .nullable()`;
    }

    return msg;
  },
};

const string: LocaleObject['string'] = {
  length: '${path} phải có độ dài chính xác là ${length} ký tự',
  min: '${path} phải có ít nhất ${min} ký tự',
  max: '${path} không được vượt quá ${max} ký tự',
  matches: '${path} không phù hợp với điều kiện: "${regex}"',
  email: '${path} không phải là địa chỉ email hợp lệ',
  url: '${path} không phải là URL hợp lệ',
  trim: '${path} không được chứa khoảng trống',
  lowercase: '${path} phải là một chuỗi chữ viết thường',
  uppercase: '${path} phải là một chuỗi chữ viết hoa',
};

const number: LocaleObject['number'] = {
  min: '${path} phải có giá trị lớn hơn hoặc bằng ${min}',
  max: '${path} phải có giá trị nhỏ hơn hoặc bằng ${max}',
  lessThan: '${path} phải có giá trị nhỏ hơn ${less}',
  moreThan: '${path} phải có giá trị lớn hơn ${more}',
  positive: '${path} phải giá trị dương',
  negative: '${path} phải là giá trị âm',
  integer: '${path} phải là giá trị số nguyên',
};

const date: LocaleObject['date'] = {
  min: '$Ngày {path} phải nhỏ hoặc cùng so với ngày ${min}',
  max: 'Ngày ${path} phải sau hoặc cùng so với ${max}',
};

const boolean: LocaleObject['boolean'] = {};

const object: LocaleObject['object'] = {
  noUnknown: "Trường ${path} không được có các khóa không xác định trong đối tượng",
};

const array: LocaleObject['array'] = {
  min: 'Mảng ${path} phải ít nhất ${min} phẩn tử',
  max: 'Mảng ${path} phải có ít hơn hoặc bằng ${max} phần tử',
};

const vnLocale: LocaleObject = {
  mixed,
  string,
  number,
  date,
  boolean,
  object,
  array
};

export default vnLocale;