import { ComponentNavigation, NavSideItem, RouteSideBar, WorkingEnvironment } from "common/types";
import { nanoid } from "nanoid";
import loadable from "@loadable/component";
import NavSideItems from "_nav";

/**
 * Filter the available sections for curren working environment
 * @returns
 */
export function getAvailableSectionPages() {
    return [...NavSideItems].filter((x) =>
        x.workingEnvironments.filter((x) => x).includes(process.env.NODE_ENV) ||
        x.workingEnvironments.filter((x) => x).includes(process.env.REACT_APP_ENV_STAGE as WorkingEnvironment)
    );
}

/**
 * Mapping the navigated route to highlighted item on a sidebar menu
 * @param route Current route path
 * @returns
 */
export function getCurrentRouteSelectedForSidebar(route: string): RouteSideBar {
    const list = getAvailableSectionPages().filter(
        (x) =>
            !x.group &&
            (x.link || x.items || x.showOnDashboard || x.showOnMenu) &&
            !x.redirectOnly
    );

    const item = list.find((x) => x.url === route);

    if (item) {
        return {
            path: route,
            key: item.key,
            openKey: undefined,
           // rolesName: item.rolesName
        };
    } else {
        const allSubItems = list
            .filter((x) => !x.link && x.items && x.items.length > 0)
            .map((x) => x.items)
            .flat();

        const subItem = allSubItems.find((x) => x.url === route);
        if (subItem) {
            return {
                path: route,
                key: subItem.key,
                openKey: subItem.openKey,
               // rolesName: item.rolesName||[]
            };
        } else {
            return null;
        }
    }
}

/**
 * Flatten all navigation items and get URL of them
 * @returns Collection of navigation items (included sub-item navigation) with URL and component, title, access rights, etc...
 */
export function flattenNavURLs(): ComponentNavigation[] {
    const result: ComponentNavigation[] = [];
    const list = getAvailableSectionPages();

    for (const linkItem of list) {
        if (!linkItem.group && linkItem.link && linkItem.url) {
            if (!linkItem.redirectOnly) {
                result.push({
                    key: linkItem.key,
                    path: linkItem.url,
                    component: loadable(linkItem.lazyElement),
                    dashboard: linkItem.showOnDashboard,
                    private: linkItem.private,
                    title: linkItem.title,
                    autoTranslate: linkItem.autoTranslate,
                    rolesName: linkItem.rolesName
                });
            } else if (linkItem.redirectOnly) {
                result.push({
                    key: nanoid(),
                    redirect: true,
                    to: linkItem.redirectTo,
                    rolesName: linkItem.rolesName
                });
            }
        } else if (linkItem.items) {
            const subLinkItems = linkItem.items.filter((x) =>
                x.workingEnvironments.filter((x) => x).includes(process.env.NODE_ENV)
            );
            if (subLinkItems.length > 0) {
                for (const subLinkItem of linkItem.items) {
                    if (subLinkItem.link) {
                        result.push({
                            key: subLinkItem.key,
                            path: subLinkItem.url,
                            component: loadable(subLinkItem.lazyElement),
                            dashboard: subLinkItem.showOnDashboard,
                            private: subLinkItem.private,
                            title: subLinkItem.title,
                            autoTranslate: subLinkItem.autoTranslate,
                            rolesName: subLinkItem.rolesName
                        });
                    }
                }
            }
        }
    }
    return result;
}