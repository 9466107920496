import { UnitListResponse } from "common/entities/unit";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { UnitListAtom } from "recoil/atoms/unit/unitListAtom";

export function  useGetUnitList() {
    return useRecoilValue(UnitListAtom)
}

export function useSetUnitList() {
    const reset = useResetRecoilState(UnitListAtom);
    const set = useSetRecoilState(UnitListAtom);
    return (value: UnitListResponse | ((pre: UnitListResponse) => UnitListResponse)) => {
        reset();
        set(value);
    }
}

export function useResetUnitList() {
    return useResetRecoilState(UnitListAtom);
}
