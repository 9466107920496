import { ColumnsType } from "antd/lib/table";
import { TFunction } from "react-i18next";
import { formatNumber } from "common/utils/strings";
import { tagActive } from "common/dataGlobal/defaultTag";
import { Link, useHistory } from "react-router-dom";
import { ManipulationButton } from "components/buttons";

export function getTableUnitConversion({
  t,
  currentPage,
  pageSize,
  isList,
  handleDeleteUnitConversion,
}: {
  t: TFunction;
  currentPage: number;
  pageSize: number;
  isList: boolean;
  handleDeleteUnitConversion?: (values: any) => void;
}) {
  const router = useHistory();
  return [
    {
      key: "1",
      title: t("RESOURCES.ESTABLISH.UNIT_CONVERSION.COLUMNS.STT"),
      render: (_, record, index) => formatNumber(index + 1 + pageSize * (currentPage - 1)),
      dataIndex: "stt",
      width: "5%",
    },
    {
      key: "2",
      title: t("RESOURCES.ESTABLISH.UNIT_CONVERSION.COLUMNS.PRODUCT_ID"),
      dataIndex: "productId",
    },
    {
      key: "3",
      title: t("RESOURCES.ESTABLISH.UNIT_CONVERSION.COLUMNS.SKU"),
      dataIndex: "sku",
    },
    {
      key: "4",
      title: t("RESOURCES.ESTABLISH.UNIT_CONVERSION.COLUMNS.PRODUCT_NAME"),
      dataIndex: "productName",
      render: (text, record) => (
        <Link to={`/unit-conversion/update-unit-conversion/${record.productId}/${record.sku}`}>
          <span style={{ color: "blue" }}>{record?.products?.name}</span>
        </Link>
      ),
    },
    {
      key: "5",
      title: t("RESOURCES.ESTABLISH.UNIT_CONVERSION.COLUMNS.CODE"),
      dataIndex: "fromUnit",
      // align: "center",
      render: (text) => <span>{text?.code}</span>,
    },
    {
      key: "6",
      title: t("RESOURCES.ESTABLISH.UNIT_CONVERSION.COLUMNS.NAME"),
      // align: "center",
      dataIndex: "fromUnitName",
      render: (text, record) => <span>{record?.fromUnitName}</span>,
    },
    {
      key: "7",
      title: t("RESOURCES.ESTABLISH.UNIT_CONVERSION.COLUMNS.QUANTITY"),
      // align: "center",
      dataIndex: "fromQty",
      render: (number) => formatNumber(number),
    },
    {
      key: "8",
      title: t("RESOURCES.ESTABLISH.UNIT_CONVERSION.COLUMNS.CODE_MIN"),
      dataIndex: "toUnit",
      // align: "center",
      render: (text) => <span>{text?.code}</span>,
    },
    {
      key: "9",
      title: t("RESOURCES.ESTABLISH.UNIT_CONVERSION.COLUMNS.NAME_MIN"),
      // align: "center",
      dataIndex: "toUnitId",
      render: (text, record) => <span>{record?.toUnitName}</span>,
    },
    {
      key: "10",
      title: t("RESOURCES.ESTABLISH.UNIT_CONVERSION.COLUMNS.QUANTITY_MIN"),
      // align: "center",
      dataIndex: "toQty",
      render: (number) => formatNumber(number),
    },
    {
      key: "11",
      title: t("RESOURCES.ESTABLISH.UNIT_CONVERSION.COLUMNS.STATUS"),
      // align: "center",
      dataIndex: "status",
      render: (text) => tagActive(text),
    },
    isList
      ? {
          key: "10",
          title: t("RESOURCES.COMMON.COLUMNS.MANIPULATION"),
          dataIndex: "manipulation",
          // align: "center",
          render: (text, record) => {
            return (
              <ManipulationButton
                showIcon
                handleDelete={() => handleDeleteUnitConversion(record)}
                handleEdit={() => {
                  router.push(`/unit-conversion/update-unit-conversion/${record?.productId}/${record?.sku}`);
                }}
              />
            );
          },
        }
      : {},
  ];
}
