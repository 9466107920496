import AES from 'crypto-js/aes';
import MD5 from 'crypto-js/md5'
import Utf8Enc from 'crypto-js/enc-utf8';
import { SECRET_KEY } from '../config';

/**
 * Encrypt plain text data encrypted cipher text
 * @param data Text data to encrypt
 * @returns Encrypted data
 */
export function encrypt(data: string) {
  return AES.encrypt(data, SECRET_KEY)
    .toString();
};

/**
 * Decrypt cipher text data to plain text
 * @param cipher Cipher encrypted text
 * @returns Decrypted plain text
 */
export function decrypt(cipher: string) {
  return AES.decrypt(cipher, SECRET_KEY)
    .toString(Utf8Enc);
};

/**
 * Calculate MD5 checksum for string
 * @param str String to hash
 * @returns
 */
export function md5(str: string) {
  return MD5(str)
    .toString(Utf8Enc);
}
