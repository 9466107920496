import { MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ManipulationButtonProps } from './ButtonWithIconProps';
import { useConfirm } from 'common/confirm';

interface props extends ManipulationButtonProps {
    hiddenEdit?: boolean;
}
const ManipulationButton: React.FC<props> = ({
    showIcon,
    handleDelete,
    handleEdit,
    textCancel = '',
    hiddenDelete = false,
    hiddenEdit = false,
    ...rest
}) => {
    const { t } = useTranslation();
    const { confirmCancel, confirmDelete } = useConfirm();

    const handleOpenConfirm = () => {
        if (textCancel) {
            confirmCancel(handleDelete);
        } else {
            confirmDelete(handleDelete);
        }
    };

    const menu = (
        <Menu>
            {!hiddenEdit && (
                <Menu.Item key="1" style={{ textAlign: 'center' }}>
                    <Button
                        type="link"
                        onClick={handleEdit}
                        disabled={hiddenEdit}
                    >
                        {t('RESOURCES.COMMON.ACTIONS.EDIT')}
                    </Button>
                </Menu.Item>
            )}
            {!hiddenDelete && (
                <Menu.Item key="2" style={{ textAlign: 'center' }}>
                    <Button
                        type="link"
                        onClick={handleOpenConfirm}
                        disabled={hiddenDelete}
                    >
                        {textCancel || t('RESOURCES.COMMON.ACTIONS.DELETE')}
                    </Button>
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button
                {...rest}
                type="link"
                icon={showIcon ? <MenuOutlined /> : null}
            />
        </Dropdown>
    );
};

ManipulationButton.propTypes = {
    showIcon: PropTypes.bool,
};

export default ManipulationButton;
