import { AuthenticatedUser } from 'common/entities/users/AuthenticatedUser';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { authErrorAtom } from 'recoil/atoms/errors/authError';
import {
    useResetAuthToken,
    useSetAuthState,
    useSetServiceActionState,
    useSetToken,
} from 'recoil/hooks';
import { useSetProfile } from 'recoil/hooks/useProfile';
import { authService } from 'services/auth';

export function useAuthAction() {
    const setProfile = useSetProfile();
    const toggleAuthState = useSetAuthState();
    const setError = useSetRecoilState(authErrorAtom);
    const resetError = useResetRecoilState(authErrorAtom);
    const setToken = useSetToken();
    const clearToken = useResetAuthToken();
    const setSuccess = useSetServiceActionState();

    const showError = (error: string) => {
        resetError();
        setError(error);
    };

    const login = async (email: string, password: string) => {
        const resp = await authService.authenticate(email, password);
        //console.log(resp);
        if (resp) {
            toggleAuthState(true);
        } else {
        }
    };

    const logout = async () => {
        await authService.logout();
        setProfile(null);
        toggleAuthState(false);
        clearToken();
        localStorage.clear();
        location.replace('/login');
    };

    const getProfile = async () => {
        const profile = await authService.getProfile();
        if (profile) {
            setProfile(profile);
        } else {
        }
    };

    const updateProfile = async (profile: AuthenticatedUser) => {
        const saved = await authService.saveProfile(profile);
        if (saved) {
            setSuccess(true);
            return getProfile();
        } else {
            setSuccess(false);
        }
    };

    const getToken = async () => {
        const token = await authService.getToken();
        if (token) {
            setToken(token);
        } else {
        }
    };

    const changePassword = async (
        currentPassword: string,
        newPassword: string,
    ) => {
        const changed = await authService.changePassword(
            currentPassword,
            newPassword,
        );
        setSuccess(changed);
    };

    const sendRequestResetPassword = async (email: string) => {};

    return {
        login,
        logout,
        getProfile,
        getToken,
        changePassword,
        updateProfile,
        sendRequestResetPassword,
    };
}
