import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { siderCollapsedAtom } from 'recoil/atoms/sidebar/sidebarCollapsed';
import styled from 'styled-components';

const Logo = styled.div`
  height: 70px;
  margin: 16px;
  background: transparent;
  text-align: center;
  align-items: middle;
`;

const AppLogo: React.FC = () => {
  const collapsed = useRecoilValue(siderCollapsedAtom);
  const fullLogoURL = `${process.env.PUBLIC_URL}/images/logo_TNI2.png`;
  const smallLogoURL = `${process.env.PUBLIC_URL}/images/logo_TNI2_crop.png`;
  return (
    <Logo>
      <Link to='/'>
        <img style={{ width: '80%' }} alt={'logo'} src={collapsed ? smallLogoURL : fullLogoURL} />
      </Link>
    </Logo>
  );
};

export default AppLogo;