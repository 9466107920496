import { UnitConversionListResponse } from "common/entities/unit";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { unitConversionListByProductAtom } from "recoil/atoms/unit/UnitConversionListByProduct";

export function useGetUnitConversionListByProduct() {
    return useRecoilValue(unitConversionListByProductAtom);
}

export function useSetUnitConversionListByProduct() {
    const reset = useResetRecoilState(unitConversionListByProductAtom)
    const set = useSetRecoilState(unitConversionListByProductAtom);
    return (value:UnitConversionListResponse | ((pre:UnitConversionListResponse) =>UnitConversionListResponse )) => {
        reset();
        set(value);
    };
}

export function useResetUnitConversionListByProduct() {
    return useResetRecoilState(unitConversionListByProductAtom);
}
