import { HomeOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Grid, Layout, Row, Typography } from "antd";
import { LayoutWidthType } from "common/types";
import { GoHomeButton } from "components/buttons";
import GoAdminButton from "components/buttons/GoAdminButton";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useRecoilState } from "recoil";
import { profileAtom } from "recoil/atoms/auth/profile";
import { siderCollapsedAtom } from "recoil/atoms/sidebar/sidebarCollapsed";
import { sidebarToggleAtom } from "recoil/atoms/sidebar/sidebarToggle";
import { useGetAuthState } from "recoil/hooks";
import { useGetLayoutWidth, useSetLayoutWidth } from "recoil/hooks/useLayoutWidth";
import styled from "styled-components";
import Breadcrumbs from "../breadcrumbs";
import ProfileMenu from "./ProfileMenu";
import RightMenu from "./RightMenu";

const { Header } = Layout;
const { Link } = Typography;

const TriggerButton = styled(Button)`
  line-height: 64px;
  cursor: pointer;
`;

const StyledHeader = styled(Header)`
  padding: 0;
  background: white;
`;

const { useBreakpoint } = Grid;

export const AppHeader: React.FC = () => {
  const [toggled, setToggled] = useRecoilState(sidebarToggleAtom);
  const breakpoints = useBreakpoint();
  const setWidthType = useSetLayoutWidth();
  const widthType = useGetLayoutWidth();
  const authState = useGetAuthState();
  const { replace } = useHistory();
  const setCollapsed = useSetRecoilState(siderCollapsedAtom);
  const { pathname } = useLocation();
  const [pathName, setPathName] = useState<string>();

  const handleSetLevel = useCallback(() => {
    if (breakpoints.xs) {
      setWidthType(LayoutWidthType.Mobile);
    }

    if (breakpoints.sm) {
      setWidthType(LayoutWidthType.Mobile);
    }

    if (breakpoints.md) {
      setWidthType(LayoutWidthType.Mobile);
    }

    if (breakpoints.lg) {
      setWidthType(LayoutWidthType.Mobile);
    }

    if (breakpoints.xl) {
      setWidthType(LayoutWidthType.Desktop);
    }

    if (breakpoints.xxl) {
      setWidthType(LayoutWidthType.Desktop);
    }
  }, [breakpoints, setWidthType]);

  useEffect(() => {
    handleSetLevel();
  }, [handleSetLevel]);

  useEffect(() => {
    if (!authState) {
      // replace('/login');
    }
  }, [authState]);

  useEffect(() => {
    setPathName(pathname);
  }, [pathname]);

  const handleSiderCollapsed = () => {
    setToggled(!toggled);

    if (widthType === LayoutWidthType.Desktop) {
      setCollapsed(!toggled);
    }
  };

  return (
    <>
      <StyledHeader className="header">
        <Row justify="start" align="middle" gutter={[16, 16]} style={{ marginLeft: "7px", marginRight: "7px" }}>
          <Col xxl={1} xl={1} lg={3} md={3} sm={3} xs={3} style={{ width: 32 }}>
            <TriggerButton
              type="text"
              shape="circle"
              icon={<MenuOutlined />}
              onClick={(e) => {
                e.preventDefault();
                handleSiderCollapsed();
              }}
            />
          </Col>
          <Col xxl={4} xl={4} lg={9} md={9} sm={9} xs={9}>
            <GoAdminButton />
          </Col>
          <Col xxl={19} xl={19} lg={12} md={12} sm={12} xs={12}>
            <Row justify="end" align="middle">
              <Col>
                <Dropdown
                  overlay={<RightMenu />}
                  trigger={["click"]}
                  // overlayStyle={{ boxShadow: '0px 0px 4px 3px #f1f1f1', top: 120 }}
                  arrow
                >
                  <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    <ProfileMenu />
                  </Link>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledHeader>
      <Breadcrumbs rootLabel={<HomeOutlined />} divider="/" />
    </>
  );
};
