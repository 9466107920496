import { Button, ButtonProps } from "antd";
import { useTranslation } from "react-i18next";

const GoAdminButton: React.FC<ButtonProps> = ({ ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button {...rest}>
      <a href={process.env.REACT_APP_URL_TNI_ADMIN}>{t("RESOURCES.COMMON.GO_ADMIN")}</a>
    </Button>
  );
};

export default GoAdminButton;
