import { ColumnsType } from "antd/lib/table";
import { MaterialOrderData } from "common/entities/materialOrder";
import { TFunction } from "react-i18next";
import formatDate from "common/utils/date";
import { formatNumber } from "../strings";

export function getTableMaterialOrder(
  t: TFunction,
  pageNumber: number,
  pageSize: number
): ColumnsType<MaterialOrderData> {
  return [
    {
      key: "1",
      title: t("RESOURCES.BOM.CATEGORY_ORDER.COLUMNS.STT"),
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1 + (pageNumber - 1) * pageSize,
    },
    {
      key: "2",
      title: t("RESOURCES.BOM.CATEGORY_ORDER.COLUMNS.BRAND"),
      align: "center",
      dataIndex: "brandCode",
    },
    {
      key: "3",
      title: t("RESOURCES.BOM.CATEGORY_ORDER.COLUMNS.CODE_STORE"),
      dataIndex: "storeNo",
    },
    {
      key: "4",
      title: t("RESOURCES.BOM.CATEGORY_ORDER.COLUMNS.NAME_STORE"),
      dataIndex: "storeName",
    },
    {
      key: "5",
      title: t("RESOURCES.BOM.CATEGORY_ORDER.COLUMNS.CODE_ORDER"),
      align: "center",
      dataIndex: "orderNumber",
    },
    {
      key: "6",
      title: t("RESOURCES.BOM.CATEGORY_ORDER.COLUMNS.DATE"),
      align: "center",
      dataIndex: "creationTime",
      render: (text) => formatDate.formatDate(text),
    },
    {
      key: "7",
      title: t("RESOURCES.BOM.CATEGORY_ORDER.COLUMNS.SKU"),
      align: "center",
      dataIndex: "productSku",
    },
    {
      key: "8",
      title: t("RESOURCES.BOM.CATEGORY_ORDER.COLUMNS.CODE_PRODUCT"),
      align: "center",
      dataIndex: "productCode",
    },
    {
      key: "9",
      title: t("RESOURCES.BOM.CATEGORY_ORDER.COLUMNS.NAME_PRODUCT"),
      dataIndex: "productName",
    },
    {
      key: "10",
      title: t("RESOURCES.BOM.CATEGORY_ORDER.COLUMNS.COUNT"),
      align: "center",
      dataIndex: "quantity",
      render: (text) => formatNumber(text),
    },
    {
      key: "12",
      title: t("RESOURCES.BOM.CATEGORY_ORDER.COLUMNS.DATE_UPDATE"),
      align: "center",
      dataIndex: "lastModificationTime",
      render: (text) => formatDate.formatDate(text),
    },
  ];
}
