import { Empty } from 'antd';
import { TFunction } from 'react-i18next';
import styled from 'styled-components';

const EmptyWrapper = styled.div`
  text-align: center;
`;

export function customizeRenderEmpty(t: TFunction) {
  return () => (
    <EmptyWrapper>
      {Empty.PRESENTED_IMAGE_SIMPLE}
      <p>{t('RESOURCES.COMMON.MESSAGES.EMPTY_DATA')}</p>
    </EmptyWrapper>
  );
}