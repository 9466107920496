import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteFilled } from "@ant-design/icons";
import { ButtonWithIconProps } from "./ButtonWithIconProps";
import PropTypes from "prop-types";

const DeleteButton: React.FC<ButtonWithIconProps> = ({ showIcon, minimal, danger, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button danger type="primary" {...rest} icon={showIcon ? <DeleteFilled /> : null} className="btn-delete-custom">
      {minimal ? undefined : t("RESOURCES.COMMON.ACTIONS.DELETE")}
    </Button>
  );
};

DeleteButton.propTypes = {
  showIcon: PropTypes.bool,
};

export default DeleteButton;
