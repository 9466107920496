import { ReportPiResponse } from "common/entities/report/reportPi";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { reportPiAtom } from "recoil/atoms/reportPi";

export const useGetReportPiState = () => {
  return useRecoilValue(reportPiAtom);
};

export const useSetReportPiState = () => {
  const reset = useResetRecoilState(reportPiAtom);
  const set = useSetRecoilState(reportPiAtom);
  return (value: ReportPiResponse | ((prev: ReportPiResponse) => ReportPiResponse)) => {
    reset();
    set(value);
  };
};

export const useResetReportPiState = () => {
    return useResetRecoilState(reportPiAtom);
};
