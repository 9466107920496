import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonWithIconProps } from "./ButtonWithIconProps";
import { SubmitButton } from "formik-antd";

const CreateButton: React.FC<ButtonWithIconProps> = ({ showIcon = true, danger, ...rest }) => {
  const { t } = useTranslation();
  return (
    <SubmitButton {...rest} icon={showIcon ? <PlusOutlined /> : null} className="btn-add-custom">
      {t("RESOURCES.COMMON.ACTIONS.CREATE")}
    </SubmitButton>
  );
};

CreateButton.propTypes = {
  showIcon: PropTypes.bool,
};

export default CreateButton;
