import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { layoutWidthAtom } from 'recoil/atoms/layout';

export function useLayoutWidth() {
  return useRecoilState(layoutWidthAtom);
}

export function useSetLayoutWidth() {
  return useSetRecoilState(layoutWidthAtom);
}

export function useGetLayoutWidth() {
  return useRecoilValue(layoutWidthAtom);
}