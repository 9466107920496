import { ApiResources } from "common/config";
import { ResponseUpdateEntity } from "common/entities";
import {
  CreateUnitConversionRequest,
  DeleteUnitConversionRequest,
  ExportUnitListRequest,
  ExportUnitConversionListRequest,
  UnitConversionListByProductRequest,
  UnitConversionListResponse,
  UpdateUnitConversionRequest,
  ImportUnitConversionRequest,
  ImportUnitConversionResponse,
  ExportUnitConversionTemplateRequest,
  SaveImportUnitConversionRequest,
} from "common/entities/unit";
import {
  DeleteUnitRequest,
  DeleteUnitResponse,
  DetailUnitRequest,
  UnitConversionListRequest,
  UnitCreateRequest,
  UnitData,
  UnitListRequest,
  UnitListResponse,
  UpdateUnitRequest,
} from "common/entities/unit";
import BaseService from "services/base-service";

export class UnitService extends BaseService {
  constructor() {
    super();
  }

  async getUnitConversionList(params: UnitConversionListRequest) {
    const apiURL = ApiResources.unit.unitConversionList;
    const { data, success, errors, statusCode } = await this.post<
      UnitConversionListRequest,
      UnitConversionListResponse
    >(apiURL, params);
    return {
      data,
      success,
      errors,
      statusCode,
    };
  }

  async getUnitConversionListByProduct(params: UnitConversionListByProductRequest) {
    const apiURL = ApiResources.unit.unitConversionListByProduct;
    const { data, success, errors, statusCode } = await this.get<UnitConversionListResponse>(apiURL, params);
    return {
      data,
      success,
      errors,
      statusCode,
    };
  }

  async updateUnitConversion(params: UpdateUnitConversionRequest) {
    const apiURL = ApiResources.unit.updateUnitConversion;
    const { data, success, errors, statusCode, fullResponse } = await this.post<
      UpdateUnitConversionRequest,
      ResponseUpdateEntity
    >(apiURL, params);
    return {
      data,
      success,
      errors,
      statusCode,
      fullResponse,
    };
  }

  async deleteUnitConversion(params: DeleteUnitConversionRequest) {
    const apiURL = ApiResources.unit.deleteUnitConversion;
    const { fullResponse, errors, statusCode } = await this.post<DeleteUnitConversionRequest, string>(apiURL, params);
    return {
      fullResponse,
      errors,
      statusCode,
    };
  }

  async addUnitConversion(params: CreateUnitConversionRequest) {
    const apiURL = ApiResources.unit.addUnitConversion;
    const { fullResponse, errors, statusCode } = await this.post<CreateUnitConversionRequest, string>(apiURL, params);
    return {
      fullResponse,
      errors,
      statusCode,
    };
  }

  async exportUnitConversionList(params: ExportUnitConversionListRequest) {
    const apiURL = ApiResources.unit.exportUnitConversionList;
    const { fullResponse, statusCode } = await this.postBlob<ExportUnitConversionListRequest, Blob>(apiURL, params);
    return { fullResponse, statusCode };
  }

  async exportUnitConversionTemplate(params: ExportUnitConversionTemplateRequest) {
    const apiURL = ApiResources.unit.exportUnitConversionTemplate;
    const { fullResponse, statusCode } = await this.getBlob<ExportUnitConversionTemplateRequest, Blob>(apiURL, params);
    return {
      fullResponse,
      statusCode,
    };
  }

  async importUnitConversion(body: ImportUnitConversionRequest) {
    const formData = new FormData();
    formData.append("importFile", body?.importFile);
    const apiURL = ApiResources.unit.importUnitConversion;
    const { data, statusCode } = await this.postFormData<ImportUnitConversionResponse>(
      apiURL,
      formData,
      null,
      { "content-type": "multipart/form-data" },
      null
    );
    return {
      data,
      statusCode,
    };
  }

  async saveImportUnitConversion(params: SaveImportUnitConversionRequest) {
    const apiURL = ApiResources.unit.saveImportUnitConversion;
    const { data, errors, success, statusCode } = await this.post<SaveImportUnitConversionRequest, string>(
      apiURL,
      params
    );
    return { data, errors, success, statusCode };
  }

  async getUnitList(params: UnitListRequest) {
    const apiURL = ApiResources.unit.unitList;
    const { data, success, errors, statusCode } = await this.post<UnitListRequest, UnitListResponse>(apiURL, params);
    return {
      data,
      success,
      errors,
      statusCode,
    };
  }

  async getUnitDetail(params: DetailUnitRequest) {
    const apiURL = ApiResources.unit.unitDetail;
    const { data, success, errors, statusCode } = await this.get<UnitData>(apiURL, params);
    return {
      data,
      success,
      errors,
      statusCode,
    };
  }

  async createUnit(params: UnitCreateRequest) {
    const apiURL = ApiResources.unit.createUnit;
    const { data, success, errors, statusCode, fullResponse } = await this.post<UnitCreateRequest, string>(
      apiURL,
      params
    );
    return {
      data,
      success,
      errors,
      statusCode,
      fullResponse,
    };
  }

  async deleteUnit(params: DeleteUnitRequest) {
    const apiURL = ApiResources.unit.deleteUnit;
    const paramsRequest = { ...params };
    const { data, success, errors, statusCode, fullResponse } = await this.delete<string, DeleteUnitResponse>(
      apiURL,
      paramsRequest
    );
    return {
      data,
      success,
      errors,
      statusCode,
      fullResponse,
    };
  }

  async updateUnit(body: UnitData, params: UpdateUnitRequest) {
    const apiURL = ApiResources.unit.updateUnit;
    const paramsRequest = { ...params };
    const { data, success, errors, statusCode, fullResponse } = await this.put<UnitData, string>(
      apiURL,
      body,
      paramsRequest
    );
    return { data, success, errors, statusCode, fullResponse };
  }

  async exportUnitList(params: ExportUnitListRequest) {
    const apiURL = ApiResources.unit.exportUnitList;
    const { fullResponse, statusCode } = await this.postBlob<ExportUnitListRequest, Blob>(apiURL, params, null);
    return { fullResponse, statusCode };
  }
}

const unitService = new UnitService();

export default unitService;
