import { ColumnsType } from "antd/lib/table";
import { tagActive } from "common/dataGlobal/defaultTag";
import { ManipulationButton } from "components/buttons";
import { TFunction } from "i18next";
import { formatNumber } from "common/utils/strings";
import { useUnitMutations } from "recoil/actions/unit";
import { UnitData } from "common/entities/unit";

export function getTableUnitList(
  t: TFunction,
  pageNumber?: number,
  pageSize?: number,
  disableManipulation: boolean = false
): ColumnsType<UnitData> {
  const { deleteUnit, getUnitDetail } = useUnitMutations();

  const handleDelete = async (id: string) => {
    const params = {
      unitId: id,
    };
    await deleteUnit(params);
  };

  const handleEdit = async (id: string) => {
    const params = {
      unitId: id,
    };
    await getUnitDetail(params);
  };

  return [
    {
      title: t("RESOURCES.ESTABLISH.UNIT.COLUMNS.STT"),
      render: (text, record, index) => {
        return formatNumber(index + 1 + pageSize * (pageNumber - 1));
      },
      align: "center",
      width: "5%",
    },
    {
      title: t("RESOURCES.ESTABLISH.UNIT.COLUMNS.CODE_ID"),
      dataIndex: "id",
    },
    {
      title: t("RESOURCES.ESTABLISH.UNIT.COLUMNS.CODE"),
      dataIndex: "code",
    },
    {
      title: t("RESOURCES.ESTABLISH.UNIT.COLUMNS.NAME"),
      dataIndex: "name",
    },
    {
      title: t("RESOURCES.ESTABLISH.UNIT.COLUMNS.NOTE"),
      width: "20%",
      dataIndex: "note",
    },
    {
      title: t("RESOURCES.ESTABLISH.UNIT.COLUMNS.DISPLAY"),
      dataIndex: "displayOrder",
      align: "center",
    },
    {
      title: t("RESOURCES.ESTABLISH.UNIT.COLUMNS.STATUS"),
      dataIndex: "status",
      align: "center",
      render: (text) => tagActive(text),
    },
    disableManipulation
      ? {
          title: t("RESOURCES.ESTABLISH.UNIT.COLUMNS.MANIPULATION"),
          align: "center",
          dataIndex: "id",
          render: (text) => {
            return (
              <ManipulationButton
                showIcon
                handleDelete={() => handleDelete(text)}
                handleEdit={() => handleEdit(text)}
              />
            );
          },
        }
      : {},
  ];
}
