import { ColumnsType } from "antd/lib/table";
import { TFunction } from "react-i18next";
import { formatNumber } from "common/utils/strings";
import { ReportPiDto } from "common/entities/report/reportPi";
import formatDate from "common/utils/date";
import { tagStatus } from "common/dataGlobal/defaultTag";
import { SEARCH_DATE_FORMAT } from "common/config";

export function getTableReportImportColumns(
  t: TFunction,
  pageNumber: number,
  pageSize: number
): ColumnsType<ReportPiDto> {
  return [
    {
      key: "1",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.STT"),
      align: "center",
      width: "5%",
      render: (text, record, index) => formatNumber(index + 1 + pageSize * (pageNumber - 1)),
    },
    {
      key: "2",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.CODE_IMPORT"),
      align: "center",
      dataIndex: "pi",
      render: (text) => <span>{text?.code}</span>,
    },
    {
      key: "3",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.TYPE"),
      align: "center",
      dataIndex: "pi",
      render: (text) => {
        if (text?.piType === 1) {
          return <span>
            {t("RESOURCES.REPORT_IMPORT_EXPORT.FILTER_FORM.PI_TYPE.PI_IMPORT")}
          </span>
        } else if (text?.piType === 2) {
          return <span>
            {t("RESOURCES.REPORT_IMPORT_EXPORT.FILTER_FORM.PI_TYPE.PI_EXPORT")}
          </span>
        }
      },
    },
    {
      key: "4",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.CREATE_DATE"),
      align: "center",
      dataIndex: "pi",
      render: (text) => <span>{formatDate.formatDate(text?.createdDate)}</span>,
    },
    {
      key: "5",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.CODE_ORDER"),
      align: "center",
      dataIndex: "order",
      render: (text) => <span>{text?.refCode}</span>,
    },
    {
      key: "6",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.SKU"),
      align: "center",
      dataIndex: "piDetail",
      render: (text) => <span>{text?.sku}</span>,
    },
    {
      key: "7",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.PRODUCT"),
      align: "left",
      dataIndex: "piDetail",
      render: (text) => <span>{text?.productName}</span>,
    },
    {
      key: "8",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.COUNT_ORDER"),
      align: "center",
      dataIndex: "orderDetail",
      render: (text) => <span>{formatNumber(text?.qty)}</span>,
    },
    {
      key: "9",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.ACTUAL_IMPORT"),
      align: "center",
      dataIndex: "piDetail",
      render: (text) => <span>{formatNumber(text?.qtyActual)}</span>,
    },
    {
      key: "10",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.DELIVERY_PLACE"),
      align: "center",
      dataIndex: "storeDeliver",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      key: "11",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.IMPORT_INVENTORY"),
      align: "center",
      dataIndex: "storeReceiver",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      key: "12",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.BRAVO_CODE"),
      align: "center",
      dataIndex: "pi",
      render: (text) => <span>{text?.bravoCode}</span>
    },
    {
      key: "13",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.STATUS"),
      align: "center",
      dataIndex: "pi",
      render: (text) => tagStatus(text?.status),
    },
    {
      key: "14",
      title: t("RESOURCES.REPORT_IMPORT_EXPORT.COLUMNS.NOTE"),
      align: "center",
      dataIndex: "pi",
      render: (text) => <span>{text?.note}</span>,
    },
  ];
}
