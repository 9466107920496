import { ResponseUpdateEntity } from 'common/entities';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import { UpdateResultDataAtom } from 'recoil/atoms/update/UpdateResult';

export function useGetUpdateResult() {
    return useRecoilValue(UpdateResultDataAtom);
}

export function useSetUpdateResult() {
    const reset = useResetRecoilState(UpdateResultDataAtom);
    const set = useSetRecoilState(UpdateResultDataAtom);

    return (value: ResponseUpdateEntity | ((prev: ResponseUpdateEntity) => ResponseUpdateEntity)) => {
    reset();
    set(value);
  };
}

export function useResetUpdateResult() {
    return useResetRecoilState(UpdateResultDataAtom);
}