import React, { ReactNode, useEffect, useState } from 'react';
import { Breadcrumb } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toPascalCase } from 'common/utils';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';
import { APP_NAME } from 'common/config';
import { pathToRegexp } from 'path-to-regexp';
import { flattenNavURLs } from 'common/utils/navigation';
import { useTitle } from 'common/providers/title';

const StyledBreadcrumb = styled(Breadcrumb)`
  background: #fff;
  padding: 10px 25px;
  border-bottom: solid 1px #e7e7e7;
`;

export interface BreadcrumbData {
  breadcrumbName: string;
  path: string;
}

export interface BreadcrumbProps {
  omitRootLabel?: boolean;
  rootLabel: React.ReactNode | React.ReactElement;
  style?: React.CSSProperties;
  className?: string;
  divider?: ReactNode;
}

const navs = flattenNavURLs().filter(x => !x.redirect);

const Breadcrumbs: React.FC<BreadcrumbProps> = ({
  omitRootLabel,
  rootLabel,
  divider,
  ...omitProps
}) => {
  const { pathname } = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbData[] | undefined>([]);
  const { t } = useTranslation();
  const [objPaths, setObjPaths] = useState<{ title: string, pathMatch: RegExp }[]>([]);

  useEffect(() => {
    for (const item of navs) {
      if (item.title) {
        setObjPaths((prev) => {
          prev.push({
            title: item.title,
            pathMatch: pathToRegexp(item.path)
          });
          return prev;
        });
      }

    }
  }, []);

  useEffect(() => {

    const linkPath = pathname.split('/');
    linkPath.shift();

    const pathArray = uniqBy(linkPath.map((path, i) => {
      path ||= 'Dashboard';
      const url = '/' + linkPath.slice(0, i + 1).join('/');
      if (objPaths.some(x => x.pathMatch.test(url))) {
        return {
          breadcrumbName: path,
          path: url,
        };
      } else return undefined;
    }), 'path').filter(x => x);

    setBreadcrumbs(pathArray);
  }, [pathname]);

  if (!breadcrumbs) {
    return null;
  }

  const breadcrumbsLength = breadcrumbs.length;

  return (
    <StyledBreadcrumb separator={divider} {...omitProps}>
      {!omitRootLabel && (
        <Breadcrumb.Item key='root-breadcrumb'>
          <Link to="/">
            {rootLabel}
          </Link>
        </Breadcrumb.Item>
      )}
      {breadcrumbs.length >= 1 &&
        breadcrumbs.map((breadcrumb, index) => {

          const current = objPaths.find(x => x.pathMatch.test(breadcrumb.path))?.title;

          let title = toPascalCase(breadcrumb.breadcrumbName);

          if (current) {
            title = t(current);
          }

          if (index === breadcrumbsLength - 1) {

          }
          return (
            <Breadcrumb.Item key={breadcrumb.path}>
                  <Link to={breadcrumb.path}>
                {title}
              </Link>
            </Breadcrumb.Item>
          );
        })}
    </StyledBreadcrumb>
  );
};

Breadcrumbs.defaultProps = {
  omitRootLabel: false,
  rootLabel: 'Home',
  divider: '/'
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  omitRootLabel: PropTypes.bool,
  style: PropTypes.object,
  rootLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType, PropTypes.node]).isRequired,
  divider: PropTypes.node
};

export default Breadcrumbs;
