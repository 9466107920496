import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { countryAtom } from "recoil/atoms/location/CountryAtom";
import { CountryDto } from "common/entities/location";

export function useGetCountry() {
    return useRecoilValue(countryAtom);
}

export function useSetCountry() {
    const reset = useResetRecoilState(countryAtom);
    const set = useSetRecoilState(countryAtom);
    return (value: CountryDto[] | ((currVal: CountryDto[]) => CountryDto[])) => {
        reset();
        set(value);
    }
}

export function useResetCountry() {
    return useResetRecoilState(countryAtom);
}
