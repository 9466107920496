import { DataResourceConfig } from 'common/types';

const ApiResources = Object.freeze<DataResourceConfig>({
    auth: {
        login: 'v1/Inventory/Login',
        getProfile: 'epos/v1/User/GetProfile',
    },
    dashboard: {
        getAll: '/inventory/v1/Dashboard',
    },
    product: {
        getProductMasterList: '/inventory/v1/GetProductMasterList',
        exportProductMasterList: '/inventory/v1/ExportProductMasterList',
        updateProductQtyMin: '/inventory/v1/UpdateProductQtyMin',
        syncProductsFromCore: '/inventory/v1/SyncProductsFromCore',
    },
    pi: {
        getListAllPI: '/inventory/v1/PIList',
        getDetailPI: '/inventory/v1/DetailPI',
        ExportPIList: '/inventory/v1/ExportPIList',
        updatePI: '/inventory/v1/UpdatePI',
        createPiNoOrder: '/inventory/v1/CreatePINoOrder',
        updatePiNoOrder: '/inventory/v1/UpdatePINoOrder',
        updatePiStatus: '/inventory/v1/UpdatePIStatus',
        updatePiStatusDone: '/inventory/v1/CancelPIStatus',
        exportPITemplate: '/inventory/v1/ExportPIImportTemplate',
        saveImportMultiPI: '/inventory/v1/SaveImportMultiPI',
        importMultiPI: '/inventory/v1/ImportMultiPI',
    },
    po: {
        getListAllPO: '/inventory/v1/POList',
        getDetailPO: '/inventory/v1/DetailPO',
        ExportPOList: '/inventory/v1/ExportPOList',
        updatePO: '/inventory/v1/UpdatePO',
        createPONoOrder: '/inventory/v1/CreatePONoOrder',
        updatePONoOrder: '/inventory/v1/UpdatePONoOrder',
        updatePOStatus: '/inventory/v1/UpdatePOStatus',
        updatePoStatusDone: '/inventory/v1/CancelPOStatus',
        exportPOTemplate: '/inventory/v1/ExportPOImportTemplate',
        saveImportMultiPO: '/inventory/v1/SaveImportMultiPO',
        importMultiPO: '/inventory/v1/ImportMultiPO',
    },

    location: {
        getCountry: '/v1/Location/GetCountries',
        getStateOrProvince: '/v1/Location/GetStatesOrProvinces',
        getDistrict: '/v1/Location/GetDistricts',
        getWard: '/v1/Location/GetWards',
    },
    invStore: {
        invStoreList: '/inventory/v1/InvStoreList',
        createInvStore: '/inventory/v1/CreateInvStore',
        deleteInvStore: '/inventory/v1/DeleteInvStore',
        detailIvnStore: '/inventory/v1/DetailInvStore',
        updateInvStore: '/inventory/v1/UpdateInvStore',
        exportInvStoreList: '/inventory/v1/ExportInvStoreList',
        getInvStoreSettingList: '/inventory/v1/GetInvStoreSettingList',
        updateInvStoreSetting: '/inventory/v1/UpdateInvStoreSetting',
        getLogList: '/inventory/v1/GetLogList',
    },
    invStoreProduct: {
        productList: '/inventory/v1/ProductList',
        exportProductList: '/inventory/v1/ExportProductList',
        exportProductExpectTemplate:
            '/inventory/v1/ExportProductExpectTemplate',
        importProductExpect: '/inventory/v1/ImportProductExpect',
        saveImportProductExpect: '/inventory/v1/SaveImportProductExpect',
        getProductExpectList: '/inventory/v1/GetProductExpectList',
        deleteProductExpect: '/inventory/v1/DeleteProductExpect',
    },
    store: {
        getAll: '/v1/Store/GetAll',
    },
    adjReceipt: {
        getList: '/inventory/v1/AdjReceiptList',
        createAdjReceipt: '/inventory/v1/CreateAdjReceipt',
        detailAdjReceipt: '/inventory/v1/DetailAdjReceipt',
        updateAdjReceipt: '/inventory/v1/UpdateAdjReceipt',
        exportAdjReceiptDetail: '/inventory/v1/ExportAdjReceiptDetail',
        exportAdjReceiptList: '/inventory/v1/ExportAdjReceiptList',
        importAdjReceipt: '/inventory/v1/ImportAdjReceiptDetail',
        saveImportAdjReceiptDetail: '/inventory/v1/SaveImportAdjReceiptDetail',
        updateAdjReceiptStatus: '/inventory/v1/UpdateAdjReceiptStatus',
        importMultiAdjReceiptDetail:
            '/inventory/v1/ImportMultiAdjReceiptDetail',
        saveImportMultiAdjReceiptDetail:
            '/inventory/v1/SaveImportMultiAdjReceiptDetail',
        exportAdjReceiptDetailTemplate:
            '/inventory/v1/ExportAdjReceiptDetailTemplate',
        importCheckInventory: '/inventory/v1/ImportCheckInventory',
        saveImportCheckInventory: '/inventory/v1/SaveImportCheckInventory',
    },
    report: {
        pi: '/inventory/v1/ReportPI',
        po: '/inventory/v1/ReportPO',
        inventory: '/inventory/v1/GetReportInvProductList',
        exportReportPi: '/inventory/v1/ExportReportPI',
        exportReportPo: '/inventory/v1/ExportReportPO',
        exportReportInvProductList: '/inventory/v1/ExportReportInvProductList',
    },
    unit: {
        unitConversionList: '/inventory/v1/UnitConversionList',
        unitConversionListByProduct:
            '/inventory/v1/UnitConversionListByProduct',
        updateUnitConversion: '/inventory/v1/UpdateUnitConversion',
        deleteUnitConversion: '/inventory/v1/DeleteUnitConversion',
        addUnitConversion: '/inventory/v1/AddUnitConversion',
        exportUnitConversionList: '/inventory/v1/ExportUnitConversionList',
        unitList: '/inventory/v1/UnitList',
        createUnit: '/inventory/v1/CreateUnit',
        deleteUnit: '/inventory/v1/DeleteUnit',
        updateUnit: '/inventory/v1/UpdateUnit',
        unitDetail: '/inventory/v1/DetailUnit',
        exportUnitList: '/inventory/v1/ExportUnitList',
        exportUnitConversionTemplate:
            '/inventory/v1/ExportUnitConversionTemplate',
        importUnitConversion: '/inventory/v1/ImportUnitConversion',
        saveImportUnitConversion: '/inventory/v1/SaveImportUnitConversion',
    },
    category: {
        getAll: '/inventory/v1/GetProductCategories',
    },
    brandCode: {
        getAll: '/v1/Store/Brand',
    },
    GroupArea: {
        getAll: '/v1/Store/GroupArea',
    },
    materialList: {
        getAll: '/bom/v1/GetMaterialsList',
        export: '/bom/v1/ExportExcelMaterialsList',
        exportTemplate: '/bom/v1/ExportMaterialsTemplate',
        import: '/bom/v1/ImportMaterials',
        saveImport: '/bom/v1/SaveImportMaterials',
        delete: '/bom/v1/DeleteMaterial',
    },
    categoryMaterial: {
        getAll: '/bom/v1/GetMaterialsCateList',
        export: '/bom/v1/ExportExcelMaterialsCateList',
        import: '/bom/v1/ImportMaterialsCateList',
        exportTemplate: '/bom/v1/ExportMaterialsCateTemplate',
        saveImport: '/bom/v1/SaveImportMaterialsCateList',
        delete: '/bom/v1/DeleteMaterialsCate',
    },
    getProductCategoryList: {
        getAll: '/bom/v1/GetProductCategoryInStore',
        export: '/bom/v1/ExportExcelProductCategories',
    },
    productFnBList: {
        getAll: '/bom/v1/GetProductInStore',
        export: '/bom/v1/ExportExcelProduct',
    },
    bomProductList: {
        getAll: '/bom/v1/GetBOMProductList',
        export: '/bom/v1/ExportExcelBOMProductList',
        exportTemplate: '/bom/v1/ExportBomProductTemplate',
        import: '/bom/v1/ImportBomProduct',
        saveImport: '/bom/v1/SaveImportBomProduct',
        delete: '/bom/v1/DeleteBomProduct',
    },
    bomSemiProductList: {
        getAll: '/bom/v1/GetBOMSemiProductList',
        export: '/bom/v1/ExportExcelBomSemiProductList',
        exportTemplate: '/bom/v1/ExportBomSemiProductsMaterialsTemplate',
        import: '/bom/v1/ImportBomSemiProductsMaterials',
        saveImport: '/bom/v1/SaveImportBomSemiProductsMaterials',
        delete: '/bom/v1/DeleteBomSemiProduct',
    },
    bomProductDetail: {
        getAll: '/bom/v1/GetBOMProductDetail',
    },
    bomSemiProductDetail: {
        getAll: '/bom/v1/GetBOMSemiProductDetail',
    },
    syncProducts: {
        getAll: '/bom/v1/SyncProducts',
    },
    synProductCategories: {
        getAll: '/bom/v1/SyncProductCategories',
    },
    getStoreBomList: {
        getAll: '/bom/v1/GetStoreList',
    },
    orderList: {
        getAll: '/bom/v1/GetOrdertList',
        export: '/bom/v1/ExportExcelOrderList',
        syncOrders: '/bom/v1/SyncOrders',
    },
    applyBom: {
        exportApply: '/bom/v1/ExportExcelProductApply',
        exportTemplate: '/bom/v1/ExportApplyBomProductTemplate',
        import: '/bom/v1/ImportApplyBomProduct',
        saveImport: '/bom/v1/SaveImportApplyBomProduct',
        delete: '/bom/v1/DeleteProductApply',
    },
    purchaseRequest: {
        createPurchaseRequest: '/bom/v1/CreatePurchaseRequest',
        updatePurchaseRequest: '/bom/v1/UpdatePurchaseRequest',
        deletePurchaseRequest: '/bom/v1/DeletePurchaseRequest',
        approvePurchaseRequest: '/bom/v1/ApprovePurchaseRequest',
        getPurchaseRequestList: '/bom/v1/GetPurchaseRequestList',
        getPurchaseRequestDetails: '/bom/v1/GetPurchaseRequestDetails',
        exportPurchaseRequestList: '/bom/v1/ExportPurchaseRequestList',
    },
    department: {
        getDepartmentList: '/bom/v1/GetDepartmentList',
    },
    unitBomList: {
        getAll: '/bom/v1/GetUnitList',
        create: '/bom/v1/CreateUnit',
        delete: '/bom/v1/DeleteUnit',
        update: '/bom/v1/UpdateUnit',
        export: '/bom/v1/ExportExcelUnitList',
        getUnitConversionList: '/bom/v1/GetUnitConversionList',
        exportExcelUnitConversionList: '/bom/v1/ExportExcelUnitConversionList',
        exportUnitConversionTemplate: '/bom/v1/ExportUnitConversionTemplate',
        importUnitConversion: '/bom/v1/ImportUnitConversion',
        saveImportUnitConversion: '/bom/v1/SaveImportUnitConversion',
        createUnitConvert: '/bom/v1/CreateUnitConvert',
        updateUnitConvert: '/bom/v1/UpdateUnitConvert',
        deleteUnitConvert: '/bom/v1/DeleteUnitConvert',
    },
    supplier: {
        getSupplierList: '/bom/v1/GetSupplierList',
    },
    purchaseOrder: {
        createPurchaseOrder: '/bom/v1/CreatePurchaseOrder',
        updatePurchaseOrder: '/bom/v1/UpdatePurchaseOrder',
        deletePurchaseOrder: '/bom/v1/DeletePurchaseOrder',
        approvePurchaseOrder: '/bom/v1/CompletePurchaseOrder',
        getPurchaseOrderList: '/bom/v1/GetPurchaseOrderList',
        getPurchaseOrderDetails: '/bom/v1/GetPurchaseOrderDetails',
        exportPurchaseOrderList: '/bom/v1/ExportPurchaseOrderList',
    },
    reportUsedMaterials: {
        getAll: '/bom/v1/ReportUsedMaterials',
        export: '/bom/v1/ExportUsedMaterials',
    },
    regional: {
        getDataRegional: '/v1/Regional/ListRegionalCodes',
        getDataListAreaCodes: '/v1/Regional/ListAreaCodes',
    },
});

export default ApiResources;
export const AXIOS_SYSTEM_ERROR_CODE = [
    'ECONNREFUSED',
    'EHOSTUNREACH',
    'ECONNRESET',
    'ECONNABORTED',
    'EHOSTDOWN',
    'ENETDOWN',
    'ENETRESET',
    'ENETUNREACH',
    'ETIMEDOUT',
];
