import { useRecoilValue, useSetRecoilState, useRecoilState, useResetRecoilState } from 'recoil';
import { serviceActionStateAtom } from 'recoil/atoms/common/serviceActionStateAtom';

export const useGetServiceActionState = () => {
  return useRecoilValue(serviceActionStateAtom);
};

export const useSetServiceActionState = () => {
  const reset = useResetRecoilState(serviceActionStateAtom);
  const setState = useSetRecoilState(serviceActionStateAtom);

  const setServiceActionState = (value: boolean) => {
    reset();
    setState(value);
  }
  return setServiceActionState;
};

export const useServiceActionState: () => [boolean, (value: boolean) => void] = () => {
  const [state, setState] = useRecoilState(serviceActionStateAtom);
  const reset = useResetRecoilState(serviceActionStateAtom);

  const updateServiceActionState = (value: boolean) => {
    reset();
    setState(value);
  };

  return [state, updateServiceActionState];
};

export const useResetServiceState = () => {
  return useResetRecoilState(serviceActionStateAtom);
}