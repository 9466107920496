import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { stateOrProvinceAtom } from "recoil/atoms/location/StateOrProvinceAtom";
import { StateOrProvince } from "common/entities/location";


export function useGetProvince() {
    return useRecoilValue(stateOrProvinceAtom);
}

export function useSetProvince() {
    const reset = useResetRecoilState(stateOrProvinceAtom);
    const set = useSetRecoilState(stateOrProvinceAtom);
    return (value: StateOrProvince[] | ((currVal: StateOrProvince[]) => StateOrProvince[])) => {
        reset();
        set(value);
    }
}

export function useResetProvince() {
    return useResetRecoilState(stateOrProvinceAtom);
}
